import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtilService } from '../../services/http-util-service';

@Injectable({
  providedIn: 'root'
})
export class ListaEsperaPermissaoService {

  ;

  private url = environment.API_URL + '/lista-espera-permissao';

  constructor(
    private http: HttpClient,
    private util: HttpUtilService
  ) { }

  getMinhasPermissoes(): Promise<any> {
    return this.http.get(`${this.url}`, this.util.httpOptions()).toPromise()
  }

  getPermissoesByCidadaoId(id: number): Observable<any> {
    return this.http.get(`${this.url}`, this.util.httpOptions())
  }

  getCidadaosComPermissaoAtiva(): Observable<any> {
    return this.http.get(`${this.url}/cidadaos`, this.util.httpOptions())
  }

  deletePermissoesByCidadaoId(id: number): Observable<any> {
    return this.http.delete(`${this.url}/cidadao/${id}`, this.util.httpOptions())
  }

  createAll(model: any): Observable<any> {
    return this.http.post(`${this.url}/save-all`, model, this.util.httpOptions())
  }
}
