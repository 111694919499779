import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpUtilService } from '../../services/http-util-service';

@Injectable({
	providedIn: 'root',
})
export class VereadorService {
	private urlCamara = environment.API_URL + '/camara/vereador';
	private url = environment.API_URL;

	constructor(private http: HttpClient, private util: HttpUtilService) {}

	getAll(): Observable<any> {
		return this.http.get(this.urlCamara, this.util.httpOptions());
	}

	getPageable(options: any): Observable<any> {
		return this.http.post(this.urlCamara + '/pageable', options, this.util.httpOptions());
	}

	getAllAtivos(): Observable<any> {
		return this.http.get(this.urlCamara + '/ativos', this.util.httpOptions());
	}

	delete(id: any): Observable<any> {
		return this.http.delete(this.urlCamara + '/' + id, this.util.httpOptions());
	}

	postVereador(model): Observable<any> {
		return this.http.post(this.urlCamara, model, this.util.httpOptions());
	}

	updateVereador(model): Observable<any> {
		return this.http.put(this.urlCamara + '/' + model.id, model, this.util.httpOptions());
	}

	getVereadorByCidadaoId(id): Observable<any> {
		return this.http.get(this.urlCamara + '/ativos/cidadao/' + id, this.util.httpOptions());
	}
}
