import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-error-markdown',
  templateUrl: './modal-error-markdown.component.html',
  styleUrls: ['./modal-error-markdown.component.scss']
})
export class ModalErrorMarkdownComponent {
  public error: HttpErrorResponse;

  constructor(
		private modalErrorDialogRef: MatDialogRef<ModalErrorMarkdownComponent>,
		@Inject(MAT_DIALOG_DATA) data: HttpErrorResponse,
	) {
		this.error = data;
	}

  public fechar(): void {
    this.modalErrorDialogRef.close();
  }

  public get mensagem(): string {
    if (this.error.status == 0) {
      return 'Aplicação não consegue se conectar ao servidor';
    }
    if (this.error.status == 404) {
      return 'Não encontrado(a)';
    }
    return this.error.error?.message ?? this.error.message;
  }

  public get titulo(): string {
    if (this.error.status == 0) {
      return 'Sem conexão com o servidor';
    }
    if (this.error.status == 500) {
      return 'Erro interno do servidor';
    }
    return 'Ocorreu um erro inesperado';
  }
}
