<div mat-dialog-content *ngIf="loading" class="mb-3 d-flex justify-content-center"
  style="min-width: 300px; min-height: 200px">
  <div class="d-block align-self-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div mat-dialog-title style="margin-bottom: 10px;" *ngIf="!loading">
  <span>{{ artigo?.titulo }}</span>
  <button tabindex="-1" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"
    style="padding: 10px;">
    <span aria-hidden="true" style="font-size: x-large;">&times;</span>
  </button>
</div>
<div mat-dialog-content *ngIf="!loading" class="mb-3" style="min-width: 60vw; max-width: 70vw; min-height: 200px">
  <div class="row mb-2 mx-1 d-block">
    <div class="card card-body mb-3">
      <mat-progress-bar *ngIf="filtrando" mode="indeterminate"></mat-progress-bar>
      <div>Modificado em: {{artigo?.atualizadoEm | date}}</div>
      <hr />
      <iframe id="myIframe" [srcdoc]="artigo?.descricao | safe" frameborder="0" width="100%" (load)="adjustIframeHeight()"></iframe>
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-block text-right">

</div>