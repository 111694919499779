import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpUtilService } from '../../services/http-util-service';
import { TipoTelaNotificacao } from '../enum/tipo-tela-notificacao.enum';

@Injectable({
   providedIn: 'root'
})
export class HistoricoNotificacaoPainelService {

   private url = environment.API_URL + "/historico-notificacao-painel"

   @Output() public atualizaContadorQuantidade = new EventEmitter()

   constructor(
      private http: HttpClient,
      private util: HttpUtilService
   ) { }

   getAllPageable(model: any): Observable<any> {
      return this.http.post(`${this.url}/pageable`, model, this.util.httpOptions());
   }

   countAllByVisualizadaIsFalse(tela: TipoTelaNotificacao = null): Observable<any> {
      const params: any = {};
      if (tela != null) {
         params.tela = tela;
      }
      return this.http.get(`${this.url}/nao-visualizadas`, { params, ...this.util.httpOptions() });
   }

   visualizarByNotificacaoId(id: number): Observable<any> {
      return this.http.get(`${this.url}/${id}/visualizar`, this.util.httpOptions())
         .pipe(tap(() => {
            this.atualizaContadorQuantidade.emit(true)
         }));
   }

   visualizarTodas(tela: TipoTelaNotificacao = null): Observable<any> {    
      const params: any = {};
      if (tela != null) {
         params.tela = tela;
      }  
      return this.http.get(`${this.url}/visualizar/todas`, { params, ...this.util.httpOptions() })
         .pipe(tap(() => {
            this.atualizaContadorQuantidade.emit(true)
         }));
   }

   deleteById(id: number): Observable<any> {
      return this.http.delete(`${this.url}/${id}`, this.util.httpOptions())
         .pipe(tap(() => {
            this.atualizaContadorQuantidade.emit(true)
         }));
   }
}
