import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { Usuario } from '../model/usuario.model';
import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { ModalAlertasComponent } from '../util/modal-module/modal-alertas/modal-alertas.component';
import { Origem } from '../util/models/enums/origem.enum';
import { ValidatorCNPJ } from '../util/validator-cnpj';
import { ValidatorCpf } from '../util/validator-cpf';
import { ModalAceitarTermosComponent } from './modal-aceitar-termos/modal-aceitar-termos.component';
import { ModalEsqueceuSenhaComponent } from './modal-esqueceu-senha/modal-esqueceu-senha.component';

@Component({
   selector: 'app-login',
   templateUrl: './login.component.html',
   styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

   public loadingBtn = false;
   public cidades = [];
   public cidade = null;
   public carregandoCidades = true;
   public showPassword: boolean = false

   public formulario: UntypedFormGroup = new UntypedFormGroup({
      documento: new UntypedFormControl('', [
         Validators.required,
         Validators.minLength(11),
         Validators.maxLength(14),
      ]),
      senha: new UntypedFormControl(null, [Validators.required, Validators.minLength(4)]),
      cidade: new UntypedFormControl(-1, [Validators.required]),
   });

   constructor(
      private authService: AuthService,
      private router: Router,
      private dialog: MatDialog,
      private storage: StorageService,
   ) { }

  ngOnInit() {
    if (this.authService.isAuthenticated()) {
      this.navegar();
    } else {
      this.buscarTodasCidades();
    }
  }

   private buscarTodasCidades() {
      this.authService
         .buscarTodasCidades()
         .pipe(finalize(() => (this.carregandoCidades = false)))
         .subscribe({
            next: (data) => {
               this.cidades = data;
            },
            error: (err) => this.alerta('Não foi possível buscar identificar a sua cidade!'),
         });
   }

   private removeChaves() {
      this.storage.remove('token');
      this.storage.remove('permissoes');
      this.storage.remove('rotinas');
      this.storage.remove('usuario');
      this.storage.remove('cabecalhos');
      this.storage.remove('isAdmin');
   }

   private alerta(msg) {
      let data = {
         mensagem: msg,
      };
      this.dialog.open(ModalAlertasComponent, {
         data,
      });
   }

   public setaCidade() {
      if (this.formulario.value.cidade != -1) {
         this.cidade = this.cidades.find((item) => item.id == this.formulario.value.cidade);
         this.storage.save('cidade', this.cidade.nomeReferencia);
         this.storage.save('logo_cidade', this.cidade.logo);
         this.storage.save('favicon', this.cidade.favicon);
         this.storage.save('facebook_id', this.cidade.facebookId);
         this.storage.save('nome_cidade', this.cidade.nome);
         this.storage.save('latitude_cidade', this.cidade.latitude ? this.cidade.latitude.toString() : '-13.406118');
         this.storage.save('longitude_cidade', this.cidade.longitude ? this.cidade.longitude.toString() : '-54.7093978');
         this.storage.save('maps_zoom_cidade', this.cidade.mapsZoom ? this.cidade.mapsZoom.toString() : '4');
      } else {
         this.cidade = null;
         localStorage.clear();
      }
   }

   /**
    *
    */
   public fazerLogin(): void {
      if (this.formulario.status === 'INVALID' || this.formulario.value.cidade === -1) {
         this.formulario.get('documento').markAsTouched();
         this.formulario.get('senha').markAsTouched();
         this.formulario.get('cidade').markAsTouched();
      } else {
         this.loadingBtn = true;
         let usuario: Usuario = new Usuario(
            this.formulario.value.documento,
            this.formulario.value.senha,
         );
         this.authService.fazerLogin(usuario).subscribe(
            (data) => {

               this.removeChaves()

               if (
                  (!data.permissaoCidadao || data.permissaoCidadao.length == 0) &&
                  (!data.cidadaoRotinas || data.cidadaoRotinas.length == 0)
               ) {
                  this.loadingBtn = false;
                  this.alerta('Cidadão não tem permissão para acessar');
                  return;
               }

               this.storage.save('token', data.token);
               this.storage.save('cabecalhos', JSON.stringify(data.cabecalhos));
               this.storage.save('usuario', JSON.stringify(data.cidadao));
               this.storage.save('permissoes', JSON.stringify(data.permissaoCidadao));
               this.storage.save('isAdmin', data.cidadao?.admin ? 'true' : 'false');

               const rotinas = [];
               for (let item of data.cidadaoRotinas) {
                  if (item.rotina && item.rotina.nome) {
                     rotinas.push({
                        id: item.rotina.id,
                        nome: item.rotina.nome,
                        privilegio: item.privilegio,
                        origem: item.origem
                     })
                  }
               }
               this.storage.save('rotinas', JSON.stringify(rotinas));
               this.authService.setTitleAndFavicon(this.cidade.nome, this.cidade.favicon);

          if (!data.cidadao?.termoUsoEPrivacidadeAceito) {
            this.dialog.open(ModalAceitarTermosComponent, {
              data: {
                cidade: this.storage.read("cidade")
              },
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
              panelClass: 'full-screen-modal',
            }).beforeClosed().subscribe((data) => {
              if (data) {
                this.navegar();
              } else {
                this.removeChaves()
                this.loadingBtn = false;
                this.alerta("Você precisa aceitar os termos de uso e privacidade.");
              }
            })
          } else {
            this.navegar();
          }
        },
        (error) => {
          this.loadingBtn = false;
          this.alerta(error.message);
        },
      );
    }
  }

   public esqueceSenha() {
      this.dialog.open(ModalEsqueceuSenhaComponent, {
         data: this.cidades,
      });
   }

   public verificaCPF(doc: string) {
      if (doc.length === 11) {
         if (!ValidatorCpf(doc)) {
            this.formulario.get('documento').setErrors({ INVALID: true });
            this.formulario.get('documento').markAsTouched();
            return;
         }
         this.formulario.get('documento').setErrors(null);
      }
      else if (doc.length === 14) {
         if (!ValidatorCNPJ(doc)) {
            this.formulario.get('documento').setErrors({ INVALID: true });
         }
         this.formulario.get('documento').markAsTouched();
         return;
      }
      this.formulario.get('documento').setErrors(null);
   }

  private navegar(): void {
    const rotinas = JSON.parse(this.storage.read('rotinas')) as {
      id: number,
      nome: string,
      privilegio: string,
      origem: Origem
    }[];
    if (rotinas.some((rotina) => rotina.nome.toLowerCase() == 'dashboard' && (rotina.origem == Origem.PREFEITURA || rotina.origem == Origem.CAMARA))) {
      this.router.navigate(['/m/dashboard']);
      return;
    }
    this.router.navigate(['/m/camara/dashboard/mural'])
  }
}
