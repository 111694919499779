import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {


  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  
  /**
   * Guarda de rotas
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {(boolean | Observable<boolean> | Promise<boolean>)}
   * @memberof AuthGuard
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
      if(this.authService.isAuthenticated()){
        return true;
      }
      else{
        this.router.navigate(['/login'])
        return false;
      }
  }
  
}
