import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-modal-aceitar-termos',
  templateUrl: './modal-aceitar-termos.component.html'
})
export class ModalAceitarTermosComponent implements OnInit {

  loading = true;
  loadingBtn = false;
  cidade: string;
  // url = "https://backend.appcidades.com.br/termodeuso/novo/"
  content: any = '';
  constructor(
    private modalEsqueceuSenhaDialogRef: MatDialogRef<ModalAceitarTermosComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.cidade = data.cidade;
    // this.url += data.cidade;
  }
  ngOnInit() {
    this.getTermos();
  }

  getTermos() {
    this.authService.getTermos(this.cidade)
      .then((data) => {
        this.loading = false
        this.content = data;
      })
      .catch((err) => {
        this.loading = false
      })
  }

  aceitarTermos() {
    this.loadingBtn = true;
    this.authService.aceitarTermos()
      .then((data) => {
        this.loadingBtn = false
        this.close(true);
      })
      .catch((err) => {
        this.close(false);
        this.loadingBtn = false
      })
  }

  close(sucesso = true) {
    this.modalEsqueceuSenhaDialogRef.close(sucesso)
  }

}
