import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface Erro {
  titulo : "";
  motivo : "";
  motivoExtendido : "";
}

@Component({
  selector: 'app-modal-erro-v2',
  templateUrl: './modal-erro-v2.component.html',
  styleUrls: ['./modal-erro-v2.component.scss']
})

export class ModalErroV2Component {

  public erro: Erro;
  public showExtendedReason: boolean = false;


  constructor(
		private modalErrorDialogRef: MatDialogRef<ModalErroV2Component>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.erro = data;
	}

  toggleExtendedReason() {
    this.showExtendedReason = !this.showExtendedReason;
  }

  close() {
    this.modalErrorDialogRef.close();
  }

}
