import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';

import { SosService } from '../../sos.service';

@Component({
   selector: 'app-modal-detalhes-relatorio',
   templateUrl: './modal-detalhes-relatorio.component.html',
   styleUrls: ['./modal-detalhes-relatorio.component.scss']
})
export class ModalDetalhesRelatorioComponent implements OnInit {
   ocorrencias = [];
   numero = '';
   servidor = false;
   displayedColumns: string[] = ['id', 'horaExataChamado'];
   totalElements: number;
	size: number;
   number: number;
   sortTable = [{ active: "horaExataChamado", direction: "desc" }];
   filters = {
      page: 0,
      size: 5,
      sorts: this.sortTable,
   };
   constructor(
      public dialogRef: MatDialogRef<ModalDetalhesRelatorioComponent>,
      private sosService: SosService,
      @Inject(MAT_DIALOG_DATA) public data: any,
   ) {
      if (data.ocorrencia?.solicitante) {
         this.servidor = true;
         this.numero = data.ocorrencia?.solicitante?.matricula;
      } else {
         this.numero = data.ocorrencia?.cidadaoSolicitante.cidadao.documento;
      }
   }
   ngOnInit() {
      this.getOcorrencias();
   }
   filter(ev) {
		let model = {
			page: ev && ev.pageIndex? ev.pageIndex: 0,
			size: ev && ev.pageSize? ev.pageSize : 5,
		};
		this.filters = {
			...this.filters,
			page: model.page,
			size: model.size,
		};
		this.getOcorrencias();
	}
   getOcorrencias() {
      this.sosService.findAllByPageable(this.filters, this.numero, this.servidor).subscribe((res) => {
         this.ocorrencias = res.content;
         this.totalElements = res.totalElements;
         this.size = res.size;
         this.number = res.number;
      });
   }
   getHoraFormatada(data) {
		return moment.utc(data).local();
	}
   close() {
      this.dialogRef.close();
   }
}
