import { LayoutModule } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
// Manter o MatLegacyList até que atualizemos o style da lista para ser compatível com o novo módulo
import { MatDialogModule } from "@angular/material/dialog";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { BaseDeConhecimentoBotaoAjudaComponent } from "../base-de-conhecimento/botao-ajuda/base-de-conhecimento-botao-ajuda.component";
import { BaseDeConhecimentoMiddlewareListaComponent } from "../base-de-conhecimento/modal-middleware-lista/base-de-conhecimento-middleware-lista.component";
import { BaseDeConhecimentoModalPorArtigoComponent } from "../base-de-conhecimento/modal-por-artigo/base-de-conhecimento-modal-por-artigo.component";
import { FooterComponent } from "../footer/footer.component";
import { MenuListItemComponent } from "./menu-list-item/menu-list-item.component";
import { NavigationComponent } from "./navigation.component";
import { SafePipeModule } from "../util/pipes/safe-pipe/safe.module";

@NgModule({
   declarations: [
      NavigationComponent,
      MenuListItemComponent,
      FooterComponent,
      BaseDeConhecimentoBotaoAjudaComponent,
      BaseDeConhecimentoMiddlewareListaComponent,
      BaseDeConhecimentoModalPorArtigoComponent,
   ],
   imports: [
      CommonModule,
      BrowserModule,
      RouterModule,
      LayoutModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatIconModule,
      MatListModule,
      MatSlideToggleModule,
      FormsModule,
      MatBadgeModule,
      MatDialogModule,
      MatMenuModule,
      MatProgressSpinnerModule,
      MatRadioModule,
		SafePipeModule,
   ],
})
export class NavigationModule {
}
