
  <h2 mat-dialog-title>Deseja ativar ou desativar o item?</h2>
  <!-- <div mat-dialog-content  class="mb-3">
      Esta ação exclui o registro e todos os dados vinculados a ele.
  </div> -->
  <div mat-dialog-actions align="end" style="min-width: 300px">
      <button type="button" mat-stroked-button class="mx-2" (click)="close()" data-dismiss="modal">
        <i class="fas fa-times"></i>
        Cancelar
      </button>
      <button mat-raised-button color=primary class="btn btn-sm my-1  mr-3" style="min-width: 95px;"
        type="button" color="primary" (click)="ativa()">
        <span class="mat-button-wrapper">
          <span>  Ativar</span>
        </span>
      </button>
      <button mat-raised-button color=primary class="btn btn-sm my-1 " style="min-width: 95px;"
        type="button" color="danger" (click)="desativa()">
        <span class="mat-button-wrapper">
          <span>  Desativar</span>
        </span>
      </button>
  </div>
  