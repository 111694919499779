import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TelasAplicativoService {
  private url = environment.API_URL + '/telas';
  constructor(private http: HttpClient, private util: HttpUtilService) { }

  getAll(): Promise<any> {
    return this.http.get(this.url + "/adm", this.util.httpOptions()).toPromise();
  }

  getId(id): Promise<any> {
    return this.http.get(`${this.url}/${id}`, this.util.httpOptions()).toPromise();
  }

  update(obj): Promise<any> {
    return this.http.put(this.url, obj, this.util.httpOptions()).toPromise();
  }

  updateAll(obj): Promise<any> {
    return this.http.put(this.url + "/all", obj, this.util.httpOptions()).toPromise();
  }

  getCampanhaAtiva(): Promise<any> {
    return this.http.get(`${this.url}/${4}`, this.util.httpOptions()).toPromise();
  }

  updatePatch(id, obj): Promise<any> {
    return this.http.patch(`${this.url}/${id}`, obj, this.util.httpOptions()).toPromise();
  }
}
