import { Solicitacao } from './../vinculo-escolar/todas-solicitacoes/todas-solicitacoes.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpUtilService } from '../services/http-util-service';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'any',
})
export class ControleProcessosService {

	protected  url = environment.API_URL + '/ploomes';
	protected  urlPrincipal = environment.API_URL;
	protected  urlAssinatura = environment.API_URL + '/assinatura';

	constructor(
		public http: HttpClient,
		public util: HttpUtilService,
		public storage: StorageService,
	) { }

	// --------------------------- Pipelines ---------------------------

	getPipelines(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/pipelines?${filter}`, this.util.httpOptions());
	}

	getPipelinesAllOrigins(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/pipelines/all-origins?${filter}`, this.util.httpOptions());
	}

	getPipelinesWithPermission(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/pipelines/with-permission?${filter}`, this.util.httpOptions());
	}

	getPipelinesWithOrigin(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/pipelines/with-origin?${filter}`, this.util.httpOptions());
	}

	getFieldsByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.url}/pipelines/${id}/fields`, this.util.httpOptions());
	}

	getReasonsByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.url}/pipelines/${id}/reasons`, this.util.httpOptions());
	}

	getFirstStageIdByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.url}/pipelines/${id}/first-stage`, this.util.httpOptions());
	}

	getAllFieldsByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.url}/pipelines/${id}/fields/all`, this.util.httpOptions());
	}

	updateCidadaoByConfiguracaoPipeline(id: number, model: any): Observable<any> {
		return this.http.put(
			`${this.urlPrincipal}/pipeline-configuration/pipeline/${id}/update-cidadao-by-fields-deal`,
			model,
			this.util.httpOptions(),
		);
	}
	// --------------------------- Deals ---------------------------

	getAllDeals(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/deals/all?${filter}`, this.util.httpOptions());
	}

	getMyDealsWithPermission(filter: any): Observable<any> {
		return this.http.post(`${this.url}/deals/with-permission`, filter, this.util.httpOptions());
	}

	getMyDealsHistoric(filter?: string): Observable<any> {
		return this.http.post(`${this.url}/deals/historic`, filter, this.util.httpOptions());
	}

	getDealsPassedByMe(filter?: string): Observable<any> {
		return this.http.post(`${this.url}/deals/passed-by-me`, filter, this.util.httpOptions());
	}

	getDealsPassedByMeById(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/solicitacao-ja-passada/by-me/${id}`, this.util.httpOptions());
	}

	createDealPassedByMe(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/solicitacao-ja-passada`, model, this.util.httpOptions());
	}

	reopenDeal(id: number): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/reopen`, this.util.httpOptions());
	}

	winDeal(id: number): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/win`, this.util.httpOptions());
	}

	loseDeal(id: number, model: any): Observable<any> {
		return this.http.post(`${this.url}/deals/${id}/lose`, model, this.util.httpOptions());
	}

	uploadDealFile(id: number, model: any, params: any = null): Observable<any> {
		const httpParams = new HttpParams().set('toSign', params?.toSign ? params.toSign : false);
		return this.http.post(`${this.url}/deals/${id}/upload`, model, {
			params: httpParams,
			headers: this.util.httpOptionsMultipart().headers,
		});
	}

	uploadDealFileOnCreation(id: number, model: any, params: any = null): Observable<any> {
		const httpParams = new HttpParams().set('toSign', params?.toSign ? params.toSign : false);
		return this.http.post(`${this.url}/deals/${id}/upload/criacao`, model, {
			params: httpParams,
			headers: this.util.httpOptionsMultipart().headers,
		});
	}

	createDeal(model: any, filter?: string): Observable<any> {
		return this.http.post(`${this.url}/deals/adm?${filter}`, model, this.util.httpOptions());
	}

	patchDeal(id: number, model: any, filter?: string): Observable<any> {
		return this.http.patch(`${this.url}/deals/${id}/adm?${filter}`, model, this.util.httpOptions());
	}

   addAnexoChecklist(id: number, model: any): Observable<any> {
		return this.http.patch(
			`${this.url}/deals/${id}/anexos/adm`,
			model,
			this.util.httpOptions(),
		);
	}

   deleteAnexoChecklist(dealId: number, anexoId: any): Observable<any> {
		return this.http.delete(
			`${this.url}/deals/${dealId}/anexos/${anexoId}/adm`,this.util.httpOptions(),
		);
	}

	patchDealNotify(id: number, model: any, filter?: string): Observable<any> {
		return this.http.patch(`${this.url}/deals/${id}/adm/notify?${filter}`, model, this.util.httpOptions());
	}

	patchDealMobile(id: number, model: any, filter?: string): Observable<any> {
		return this.http.patch(`${this.url}/deals/${id}?${filter}`, model, this.util.httpOptions());
	}

	getDealFieldsWithValue(id: number): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/fields/with/value/adm`, this.util.httpOptions());
	}

	getDealFieldsWithValueMobile(id: number): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/fields/with/value`, this.util.httpOptions());
	}

	getDealChecklists(id: number, stageId?: number): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/checklists?stageId=${stageId}`, this.util.httpOptions());
	}

	generateDealPdf(model: any): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http
			.post(`${this.url}/deals/generate/pdf/solicitacoes`, model, {
				headers,
				responseType: 'arraybuffer',
			})
			.pipe(delay(0));
	}

	generateDealFieldsPdf(id: number, param?): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.get(`${this.url}/deals/${id}/fields/generate/pdf?${param}`, {
			headers,
			responseType: 'arraybuffer',
		});
	}

	generateNewDealFieldsPdf(id: number): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});
		return this.http.get(`${this.url}/deals/${id}/fields/generate/pdf/deal`, { headers, responseType: 'arraybuffer' }).pipe(
			catchError((error) => {
				const uint8Array = new Uint8Array(error.error); // O erro é retornado como um ArrayBuffer
				const jsonString = String.fromCharCode.apply(null, uint8Array); // Convertendo para string
				const parsedError = JSON.parse(jsonString);
				const err = new Error(parsedError);
				return throwError(() => err);
			}),
		);
	}

	generateDealFieldsAttachmentPdf(id: number, param?): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/fields/attachment/generate/pdf?${param}`, this.util.httpOptions());
	}

	generateDealChecklistsFieldsPdf(id: number, param?): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.get(`${this.url}/deals/${id}/checklist/fields/generate/pdf?${param}`, {
			headers,
			responseType: 'arraybuffer',
		});
	}

	generateDealChecklistsFieldsAttachmentPdf(id: number, param?): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/checklist/fields/attachment/generate/pdf?${param}`, this.util.httpOptions());
	}

	getMyDeals(filters: any): Observable<any> {
		return this.http.get(`${this.url}/deals/my-deals?${filters}`, this.util.httpOptions());
	}

	getMyDealsV3(model: any): Observable<any> {
		return this.http.post(`${this.url}/deals/my-deals/v3`, model, this.util.httpOptions());
	}

	getByProtocol(protocol: any): Observable<any> {
		return this.http.get(`${this.url}/protocolo/v2/${protocol}`, this.util.httpOptions());
	}

	// --------------------------- Tasks ---------------------------

	getTasks(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/tasks?${filter}`, this.util.httpOptions());
	}

	finishTask(id): Observable<any> {
		return this.http.get(`${this.url}/tasks/${id}`, this.util.httpOptions());
	}

	// --------------------------- Images ---------------------------

	createImage(model: any): Observable<any> {
		return this.http.post(`${this.url}/images`, model, this.util.httpOptionsMultipart());
	}

	// --------------------------- Contacts ---------------------------

	createContact(model: any, filter?: string): Observable<any> {
		return this.http.post(`${this.url}/contacts?${filter}`, model, this.util.httpOptions());
	}

	getContacts(filter?: string): Observable<any> {
		return this.http.get(`${this.url}/contacts?${filter}`, this.util.httpOptions());
	}

	// --------------------------- Signs ---------------------------

	signatureClicksign(model: any): Observable<any> {
		return this.http.post(`${this.url}/signature`, model, this.util.httpOptions());
	}

	signatureUserPassword(model): Observable<any> {
		return this.http.post(`${this.url}/assinatura`, model, this.util.httpOptions());
	}

	signatureUserPasswordSemLocal(model): Observable<any> {
		return this.http.post(`${this.url}/assinatura/mobile`, model, this.util.httpOptions());
	}

	signatureUserPasswordSemLocalAvulso(model, stageId): Observable<any> {
		return this.http.post(`${environment.API_URL}/assinatura/digital-signer/assina-avulso/etapa/${stageId}`, model, this.util.httpOptions());
	}

	checkPasswordForSignature(model): Observable<any> {
		return this.http.post(`${environment.API_URL}/assinatura/digital-signer/check-password`, model, this.util.httpOptions());
	}

	upaArquivoRetornaS3(anexoPloomes, comTipo): Observable<any> {
		return this.http.post(`${environment.API_URL}/anexo/baixa-anexo-ploomes?comTipo=${comTipo}`, anexoPloomes, this.util.httpOptions());
	}

	uploadMultipart(file: any, fileName: string): Observable<any> {
		return this.http.post(
			`${environment.API_URL}/amazon/uploadMultipartFile/map?fileName=${encodeURIComponent(fileName)}`,
			file,
			this.util.httpOptionsMultipart(),
		);
	}

	requestSignatureUserPassword(model, obrigatoria): Observable<any> {
		return this.http.post(`${this.url}/assinatura/solicitar?obrigatoria=${obrigatoria}`, model, this.util.httpOptions());
	}

	getClicksignKeyByNomeCidadeReferencia(): Observable<any> {
		return this.http.get(`${this.urlAssinatura}/clicksign/key`, this.util.httpOptions());
	}

	stampWithPassword(model: any): Observable<any> {
		return this.http.post(`${this.url}/assinatura/carimbo/V2`, model, this.util.httpOptions());
	}

	deleteAssinaturasObrigatorias(id): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/assinatura-obrigatoria/solicitacao/${id}`, this.util.httpOptions());
	}

	// --------------------------- Attachments ---------------------------

	deleteFile(id: number): Observable<any> {
		return this.http.delete(`${this.url}/attachments/${id}`, this.util.httpOptions());
	}

	createAmazonFile(model: any): Observable<any> {
		return this.http.post(`${this.url}/attachments/upload/s3`, model, this.util.httpOptionsMultipart());
	}

	getAttachmentByAnexoId(id: any): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/anexo/token/anexo/${id}`);
	}

	getAnexoById(id: any): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/anexo/especifico/${id}`, this.util.httpOptions());
	}

	getCanUploadFile(id: number, model: any): Observable<any> {
		return this.http.post(`${this.url}/attachments/${id}/can-upload`, model, this.util.httpOptionsMultipart());
	}

	downloadUrlToByte(model: any): Observable<any> {
		return this.http.post(`${this.url}/attachments/download`, model, {
			headers: this.util.httpOptions().headers,
			responseType: 'arraybuffer',
		});
	}

	// --------------------------- Pipeline Permissions ---------------------------

   getSolicitacaoById(id: number): Observable<any>{
		return this.http.get(`${this.urlPrincipal}/solicitacoes/${id}`, this.util.httpOptions())
   }

	createPipelineAll(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/pipeline-permission/save-all`, model, this.util.httpOptions());
	}

	getPipelinePermissionsByCidadaoId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/pipeline-permission/cidadao/${id}`, this.util.httpOptions());
	}

   getPipelinePermissionsByPipelineIdAndCidadaoLogado(id: number): Observable<any> {
		return this.http.get(
			`${this.urlPrincipal}/pipeline-permission/${id}/cidadao-logado`,
			this.util.httpOptions(),
		);
	}

   getPipelineSharedPermissionsByPipelineId(id: any, cidId: any): Observable<any> {
		return this.http.get(
			`${this.urlPrincipal}/pipeline-permission/${id}/shared-permissions/cidadao/${cidId}`,
			this.util.httpOptions(),
		);
	}

	getAllCidadaosWithPipelinePermission(): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/pipeline-permission/cidadaos`, this.util.httpOptions());
	}

	deletePipelinesPermissionsByCidadaoId(id: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/pipeline-permission/cidadao/${id}`, this.util.httpOptions());
	}

	// --------------------------- Stage Permissions ---------------------------

	createStageAll(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/stage-permission/save-all`, model, this.util.httpOptions());
	}

	getStagesPermissionsByPipelineIdAndCidadaoId(pipelineId: number, cidadaoId: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-permission/pipeline/${pipelineId}/cidadao/${cidadaoId}`, this.util.httpOptions());
	}

   hasPermissionStage(solicitacaoId: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-permission/solicitacao/${solicitacaoId}/has-permission`, this.util.httpOptions());
	}

	getStagesPermissionsByPipelineIdAndCidadaoIdAndSolicitacaoId(
		pipelineId: number,
		cidadaoId: number,
		solicitacaoId: number,
	): Observable<any> {
		return this.http.get(
			`${this.urlPrincipal}/stage-permission/pipeline/${pipelineId}/cidadao/${cidadaoId}/solicitacao/${solicitacaoId}`,
			this.util.httpOptions(),
		);
	}

	deleteStagesPermissionsByPipelineIdAndCidadaoId(pipelineId: number, cidadaoId: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/stage-permission/pipeline/${pipelineId}/cidadao/${cidadaoId}`, this.util.httpOptions());
	}

	getCidadaosByPipelineIdAndStageId(pipelineId: number, stageId): Observable<any> {
		return this.http.get(
			`${this.urlPrincipal}/stage-permission/pipeline/${pipelineId}/stage/${stageId}/cidadoes`,
			this.util.httpOptions(),
		);
	}

	getStagesPermissionsByPipelineId(pipelineId: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-permission/pipeline/${pipelineId}`, this.util.httpOptions());
	}

	// --------------------------- Anexos ---------------------------

	getAllAttachmentBySolicitacaoIdAndFiltros(filtro: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/anexo/solicitacao/v2`, filtro, this.util.httpOptions());
	}

	deleteAttachmentById(id: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/anexo/anexo/${id}`, this.util.httpOptions());
	}

   deleteAttachmentByIdPloomes(id: number, deleteSource): Observable<any> {
		return this.http.delete(`${this.url}/attachments/${id}?deleteSource=${deleteSource}`, this.util.httpOptions());
	}

	generateAttachmentsZipByDealId(id: number, param?): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.get(`${this.urlPrincipal}/anexo/solicitacao/${id}/generate/single/zip?${param}`, {
			headers,
			responseType: 'arraybuffer',
		});
	}

   getAttachmentsByDealIdAndParam(id: number, param?): Observable<any> {

		return this.http.get(
			`${this.urlPrincipal}/anexo/solicitacao/${id}/generate/single?${param}`, this.util.httpOptions()
		);
	}

	generateFormWithAttachmentsZipByDealId(id: number): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.get(`${this.url}/deals/${id}/fields/generate/zip-with-attachments`, { headers, responseType: 'arraybuffer' });
	}

	patchAnexo(id: number, model: any): Observable<any> {
		return this.http.patch(`${this.urlPrincipal}/anexo/${id}`, model, this.util.httpOptions());
	}

	duplicaAnexo(idAnexo: number, idSolicitacao: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/anexo/duplicar/${idAnexo}/solicitacao/${idSolicitacao}`, this.util.httpOptions());
	}

	// --------------------------- Histórico de Anexos ---------------------------

	generateTimelineZipByIdAnexo(id: number): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.get(`${this.urlPrincipal}/historico-anexo/anexo/${id}/generate/single/zip`, {
			headers,
			responseType: 'arraybuffer',
		});
	}

	getAllAttachmentTimelineByAnexoId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/historico-anexo/anexo/${id}`, this.util.httpOptions());
	}

	getCriacaoAttachmentTimelineByAnexoId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/historico-anexo/anexo-criacao/${id}`, this.util.httpOptions());
	}

	// --------------------------- Stage Configuration ---------------------------

	getStageConfigurationByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-configuration/pipeline/${id}`, this.util.httpOptions());
	}

	createAllStageConfiguration(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/stage-configuration/create/all`, model, this.util.httpOptions());
	}

	getStageConfigurationByStageId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-configuration/stage/${id}`, this.util.httpOptions());
	}

	// --------------------------- Stage Expiration ---------------------------

	createStageExpiration(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/stage-expiration`, model, this.util.httpOptions());
	}

	getStageExpirationByDealIdAndStageId(idDeal: number, idStage: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/stage-expiration/deal/${idDeal}/stage/${idStage}`, this.util.httpOptions());
	}

	// --------------------------- Digital Certificate ---------------------------

	signatureDigitalCertificate(model: any): Observable<any> {
		return this.http.post(`${this.url}/digital-certificate`, model, this.util.httpOptions());
	}

	// --------------------------- Pipeline Configuration ---------------------------

	createPipelineConfiguration(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/pipeline-configuration`, model, this.util.httpOptions());
	}

	putPipelineConfiguration(model: any): Observable<any> {
		return this.http.put(`${this.urlPrincipal}/pipeline-configuration`, model, this.util.httpOptions());
	}

	getPipelineConfigurationByPipelineId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/pipeline-configuration/pipeline/${id}`, this.util.httpOptions());
	}

	// --------------------------- Aux ---------------------------

	sendEmail(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/contato/anexos`, model, this.util.httpOptions());
	}

	notification(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/mensagem/ploomes`, model, this.util.httpOptions());
	}

	createSolicitacaoCampo(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/solicitacao-campo`, model, this.util.httpOptions());
	}

	getSolicitacaoCampoBySolicitacaoId(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/solicitacao-campo/solicitacao/${id}`, this.util.httpOptions());
	}

	// --------------------------- Histórico de Campos ---------------------------

	getAuditoriaPloomesByFieldKey(id: number, key: string): Observable<any> {
		return this.http.get(`${this.url}/deals/${id}/auditoria-ploomes/${key}`, this.util.httpOptions());
	}

	getAuditoriaPloomesCamposBySolicitacaoId(id: number): Observable<any> {
		return this.http.get(`${this.url}/auditoria-ploomes/solicitacao/${id}/campos`, this.util.httpOptions());
	}

	// --------------------------- Dashboard ---------------------------
	getDashboard(filter: any): Observable<any> {
		return this.http.post(`${this.url}/dashboard`, filter, this.util.httpOptions());
	}

	generateDashboardPdf(model: any): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.post(`${this.url}/dashboard/exportar/pdf`, model, {
			headers,
			responseType: 'arraybuffer',
		});
	}

	getDashboardExcel(model: any): Observable<any> {
		return this.http.post(`${this.url}/dashboard/exportar/excel`, model, this.util.httpOptions());
	}

	getConfiguracaoDashboard(model: any): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/configuracao-dashboard/${model}`, this.util.httpOptions());
	}

	createConfiguracaoDashboard(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/configuracao-dashboard`, model, this.util.httpOptions());
	}

	deleteConfiguracaoDashboard(id: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/configuracao-dashboard/${id}`, this.util.httpOptions());
	}

	gerarRelatorio(model: any): Observable<any> {
		let headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});

		return this.http.post(`${this.url}/dashboard/exportar/html-pdf`, model, {
			headers,
			responseType: 'text',
		});
	}

	// --------------------------- Timeline ---------------------------
	getTimeline(param?): Observable<any> {
		return this.http.get(`${this.url}/timelines?${param}`, this.util.httpOptions());
	}

	getTimelineSolicitacao(model: any): Observable<any> {
		return this.http.post(`${this.url}/timelines/solicitacao`, model, this.util.httpOptions());
	}

	// --------------------------- Synchronization ---------------------------
	synchronize(): Observable<any> {
		let options = {
			headers: new HttpHeaders({
				Authorization: 'Bearer ' + this.storage.read('token'),
				cidade: this.storage.read('cidade'),
				token: '658361BD062EEC6E51FDB65697654BAAC2B0767B467D1420EECB4088FA42AF1B',
			}).set('Content-Type', 'application/json'),
		};
		return this.http.get(`${this.urlPrincipal}/solicitacoes/sincronizacao/all`, options);
	}

	// --------------------------- Solicitações ---------------------------

	atualizaSolicitacao(id: number, model: any): Observable<any> {
		return this.http.put(`${this.urlPrincipal}/solicitacoes/${id}`, model, this.util.httpOptions());
	}

	// --------------------------- Campo - Valor ---------------------------

	getMotivoCancelado(idSolicitacao: number): Observable<any> {
		return this.http.get(
			`${this.urlPrincipal}/solicitacoes-campo-valor/solicitacao/${idSolicitacao}/motivo-cancelado`,
			this.util.httpOptions(),
		);
	}


	// --------------------------- Etiqueta ---------------------------

	saveEtiquetaProcesso(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/etiquetas/processo`, model, this.util.httpOptions());
	}

	getEtiquetasProcesso(processoId: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/etiquetas/processo/${processoId}`, this.util.httpOptions());
	}

	updateEtiquetaProcesso(id, model: any): Observable<any> {
		return this.http.put(`${this.urlPrincipal}/etiquetas/processo/${id}`, model, this.util.httpOptions());
	}

	deleteEtiquetaProcesso(idEtiqueta: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/etiquetas/processo/${idEtiqueta}`, this.util.httpOptions());
	}

	saveEtiquetaSolicitacao(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/etiquetas/solicitacao`, model, this.util.httpOptions());
	}

	updateEtiquetaSolicitacao(id: number, model: any): Observable<any> {
		return this.http.put(`${this.urlPrincipal}/etiquetas/solicitacao/${id}`, model, this.util.httpOptions());
	}

	patchEtiquetaSolicitacao(id: number, model: any): Observable<any> {
		return this.http.patch(`${this.urlPrincipal}/etiquetas/solicitacao/${id}`, model, this.util.httpOptions());
	}

	deleteAllEtiquetasSolicitacao(solicitacaoId: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/etiquetas/solicitacao/all/${solicitacaoId}`, this.util.httpOptions());
	}

	saveEtiquetaSolicitacaoByEtiquetaProcessoId(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/etiquetas/solicitacao/etiqueta-processo`, model, this.util.httpOptions());
	}

	getEtiquetasSolicitacao(id: number): Observable<any> {
		return this.http.get(`${this.urlPrincipal}/etiquetas/solicitacao/${id}`, this.util.httpOptions());
	}

	deleteEtiquetaSolicitacao(id: number): Observable<any> {
		return this.http.delete(`${this.urlPrincipal}/etiquetas/solicitacao/${id}`, this.util.httpOptions());
	}

	deleteBySolicitacaoIdAndEtiquetaProcessoId(solicitacaoId: number, etiquetaProcessoId: number): Observable<any> {
		return this.http.delete(
			`${this.urlPrincipal}/etiquetas/solicitacao/${solicitacaoId}/etiqueta-processo/${etiquetaProcessoId}`,
			this.util.httpOptions(),
		);
	}
	// --------------------------- Canvas - Assinatura e Carimbo ---------------------------

	getStamp(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/canvas/gerar-img-carimbo`, model, this.util.httpOptions());
	}

	getSignature(model: any): Observable<any> {
		return this.http.post(`${this.urlPrincipal}/canvas/gerar-img-assinatura`, model, this.util.httpOptions());
	}

	downloadFile(url: string): Observable<Blob> {
		const params = new HttpParams().set('fileUrl', url);
		return this.http.get(`${this.urlPrincipal}/amazon/downloadFileFromUrlS3bucket`, {
			headers: this.util.httpOptions().headers,
			params: params,
			responseType: 'blob',
		});
	}
}
