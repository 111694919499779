import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { RotinasService } from './acessos/rotinas/rotinas.service';
import { UsuarioService } from './acessos/usuarios/usuarios.service';
import { TipoTelaNotificacao } from './historico-notificacao-painel/enum/tipo-tela-notificacao.enum';
import { AuthService } from './services/auth.service';
import { SocketService } from './services/socket.service';
import { StorageService } from './services/storage.service';
import { ModalDetalhesOcorrenciaComponent } from './sos/modal-detalhes-ocorrencia/modal-detalhes-ocorrencia.component';
import { SosService } from './sos/sos.service';

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
   public mostrarMenu: boolean = false;

   public clicado: boolean = false;

   sos;

   public notificationSound = new Audio();
   @Output() public notificacaoOcorrencia = new EventEmitter();
   @Output() public mensagemWhatsappRecebida = new EventEmitter();

   constructor(
      private authService: AuthService,
      private permissaoService: UsuarioService,
      private rotinasService: RotinasService,
      private storage: StorageService,
      private socketService: SocketService,
      private sosService: SosService,
      private snackBar: MatSnackBar,
      private router: Router,
      private dialog: MatDialog,
   ) {
      this.authService.setTitleAndFavicon(storage.read("nome_cidade"), storage.read("favicon"))
   }

   onSubmit() { }

   // sidnav moviment
   public toggle(event) {
      this.clicado = !this.clicado
   }

   ngOnInit() {
      this.notificationSound.src = "https://s3.sa-east-1.amazonaws.com/fs-teste-app-cidade-divinopolis/1703036124342-receive-message.mp3";
      this.notificationSound.load();
      let latitudeInicial = this.storage.read('latitude_cidade');
      let longitudeInicial = this.storage.read('longitude_cidade');
      let mapsZoomInicial = this.storage.read('maps_zoom_cidade');
      if (!latitudeInicial || !longitudeInicial) {
         this.storage.save('latitude_cidade', '-13.406118');
         this.storage.save('longitude_cidade', '-54.7093978');
      }
      if (!mapsZoomInicial) {
         this.storage.save('maps_zoom_cidade', '4');
      }

      this.authService.mostrarMenuEmitter.subscribe(
         (mostrar: boolean) => {
            this.mostrarMenu = mostrar;
            if (mostrar) {
               this.getPermissoesPorUsuarioLogado();
               this.getRotinasPorUsuarioLogado();
               this.getCabecalhosDinamicos();
               this.notificacoesPainel()
            }
         });

   }

   notificacoesPainel() {
      const nomeReferenciaCidade = this.storage.read('cidade');
      const idCidadao = JSON.parse(this.storage.read('usuario')).id;

      const nomeEvento = "notificacao-painel=cidade:" + nomeReferenciaCidade + "=cidadao:" + idCidadao;
      const nomeEventoChamado = "notificacao-painel-chamado=cidade:" + nomeReferenciaCidade + "=cidadao:" + idCidadao;
      const nomeEventoSos = "notificacao-painel-sos=cidade:" + nomeReferenciaCidade + "=cidadao:" + idCidadao;
      const nomeEventoWhatsapp = "notificacao-painel-whatsapp=cidade:" + nomeReferenciaCidade + "=cidadao:" + idCidadao;

      let dialogAberto = false;
      let eventoBloqueado = false;

      this.socketService.onListen(nomeEventoSos).subscribe((_) => {
         if (!dialogAberto && !eventoBloqueado) {
            eventoBloqueado = true;
            this.showSnackBar();
            this.sosService.getUltimoSosCriado().subscribe((sos) => {
               this.sos = sos;
               const data = {
                  id: sos.idTela,
                  urgencia: true,
               };
               dialogAberto = true;
               this.dialog
                  .open(ModalDetalhesOcorrenciaComponent, { data })
                  .afterClosed()
                  .subscribe(() => {
                     dialogAberto = false;
                     eventoBloqueado = false;
                  });
            });
         }
      });

      this.socketService.onListen(nomeEventoChamado)
         .subscribe(() => {
            this.notificacaoOcorrencia.emit(true);
            this.showSnackBar(TipoTelaNotificacao.CHAMADO);
            this.notificationSound.play();
         });

      this.socketService.onListen(nomeEvento)
         .subscribe(() => {
            this.showSnackBar();
            this.notificationSound.play();
         });

      this.socketService.onListen(nomeEventoWhatsapp)
         .subscribe(() => {
            this.mensagemWhatsappRecebida.emit(true);
            this.notificationSound.play();

            const horizontalPosition: MatSnackBarHorizontalPosition = 'end';
            const verticalPosition: MatSnackBarVerticalPosition = 'top';

            const snackBarRef = this.snackBar.open('Você tem uma nova mensagem.', 'Ir para', {
               horizontalPosition,
               verticalPosition,
               duration: 10 * 1000,
            });

            snackBarRef.onAction().subscribe(() => {
               this.router.navigate(['m/bate-papo-whatsapp']);
            });
         });
   }

   getCabecalhosDinamicos() {

      this.authService.getCabecalhosDinamicos().subscribe(
         (data) => {
            this.storage.remove('cabecalhos');
            this.storage.save('cabecalhos', JSON.stringify(data.cabecalhos));
         }, (error) => { },
      );
   }

   getPermissoesPorUsuarioLogado() {
      this.permissaoService.getPermissoesPorUsuarioLogado()
         .then((res) => {
            this.storage.remove('permissoes');
            this.storage.save('permissoes', JSON.stringify(res?.permissoes ?? []));
         })
   }

   getRotinasPorUsuarioLogado() {
      this.rotinasService.getAllRotinasByUsuarioLogado()
         .subscribe({
            next: (res) => {
               this.storage.remove('rotinas');
               const rotinas = [];
               for (let item of res.rotinas) {
                  if (item.rotina && item.rotina.nome) {
                     rotinas.push({
                        id: item.rotina.id,
                        nome: item.rotina.nome,
                        privilegio: item.privilegio,
                        origem: item.origem
                     })
                  }
               }
               this.storage.save("rotinas", JSON.stringify(rotinas))
            },
            error: (err) => { console.error(err) }
         })
   }

   ngOnDestroy() {
      this.authService.mostrarMenuEmitter.unsubscribe();
      this.socketService.disconnect();
   }

   showSnackBar(tela?: TipoTelaNotificacao) {
      const horizontalPosition: MatSnackBarHorizontalPosition = 'end';
      const verticalPosition: MatSnackBarVerticalPosition = 'top';

      const snackBarRef = this.snackBar.open('Você tem uma nova notificação.', 'Ir para', {
         horizontalPosition,
         verticalPosition,
         duration: 10 * 1000,
      });

      if (tela == TipoTelaNotificacao.CHAMADO) {
         snackBarRef.onAction().subscribe(() => {
            this.router.navigate(['m/ocorrencias/historico-notificacoes']);
         });
      } else {
         snackBarRef.onAction().subscribe(() => {
            this.router.navigate(['m/historico-notificacao-painel']);
         });
      }
   }
}
