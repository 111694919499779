import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { HttpUtilService } from '../services/http-util-service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InformacoesService{

    //emite evento para componente pai
    @Output() public atualizaNavbarInformacoes = new EventEmitter()

    private url = environment.API_URL+"/servico"

    constructor(
        private http: HttpClient,
        private util: HttpUtilService
    ) { }

    getAll(id: number): Promise<any> {
        return this.http.get(this.url+"/categoria/all/"+id, this.util.httpOptions()).toPromise()
    }
    getById(id: number): Promise<any> {
        return this.http.get(this.url+"/"+id, this.util.httpOptions()).toPromise()
    }
    create(model): Promise<any> {
        return this.http.post(this.url, model, this.util.httpOptions()).toPromise()
    }
    update(model): Promise<any> {
        return this.http.put(this.url, model, this.util.httpOptions()).toPromise()
    }
    delete(id: number): Promise<any> {
        return this.http.delete(this.url+"/"+id, this.util.httpOptions()).toPromise()
    }

    getCategorias(): Observable<any> {
        return this.http.get(this.url+"/categoria", this.util.httpOptions())
    }

    getCategoriasPageable(options: any): Observable<any> {
        return this.http.post(this.url+"/categoria/pageable", options, this.util.httpOptions())
    }

    getCategoriasById(id: number): Promise<any> {
        return this.http.get(this.url+"/categoria/"+id, this.util.httpOptions()).toPromise()
    }

    createCategoria(model): Promise<any> {
        return this.http.post(this.url+"/categoria", model, this.util.httpOptions()).toPromise()
        .then((data) => {
            this.atualizaNavbarInformacoes.emit(true)
            return data;
        })
    }

    updateCategoria(model): Promise<any> {
        return this.http.put(this.url+"/categoria", model, this.util.httpOptions()).toPromise()
        .then((data) => {
            this.atualizaNavbarInformacoes.emit(true)
            return data;
        })
    }

    deleteCategoria(id: number): Observable<any> {
        return this.http.delete(this.url+"/categoria/"+id, this.util.httpOptions())
        .pipe(
            tap(() => {this.atualizaNavbarInformacoes.emit(true)})
        );
    }
}