import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirm-delete',
  templateUrl: './modal-confirm-delete.component.html',
  styleUrls: ['./modal-confirm-delete.component.scss']
})
export class ModalConfirmDeleteComponent implements OnInit {

  @Input() mensagem: string = 'Deseja deletar o registro?';
  @Input() content: string = '';
  @Input() textoBtnSim: string = 'Deletar';
  @Input() textoBtnNao: string = 'Cancelar';

  constructor(
    private modalConfirmDeleteDialogRef: MatDialogRef<ModalConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if (data) {
      if (data.mensagem) {
        this.mensagem = data.mensagem;
      }
      if (data.content) { 
        this.content = data.content;
      }
      if (data.textoBtnSim) {
        this.textoBtnSim = data.textoBtnSim;
      }
      if (data.textoBtnNao) {
        this.textoBtnNao = data.textoBtnNao;
      }
    }
  }

  ngOnInit() {
  }

  close() {
    this.modalConfirmDeleteDialogRef.close()
  }

  save() {
    this.modalConfirmDeleteDialogRef.close(1)
  }

}
