import { EventEmitter } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class PermissaoService {

  public temPermissao = new EventEmitter<boolean>();

  private currentUser: BehaviorSubject<any>;
  private rotinas: BehaviorSubject<any>;
  private permissoes: BehaviorSubject<any>;

  constructor(
		private storage: StorageService,
    ) {
   }

  public get currentUserValue() {
    this.currentUser = new BehaviorSubject<any>(this.storage.read('isAdmin'));
    return this.currentUser.getValue() || false;
  }

  public get currentRotinas() {
    this.rotinas = new BehaviorSubject<any>(JSON.parse(this.storage.read('rotinas')));
    return this.rotinas.getValue() || [];
  }

  public get currentPermissios() {
    this.permissoes = new BehaviorSubject<any>(JSON.parse(this.storage.read('permissoes')));
    if (this.permissoes.getValue()) {
      return this.permissoes.getValue().map(item => item.categoriaPermissao);
    } else {
      return [];
    }
  }

  public get currentPermissiosChamado() {
    this.permissoes = new BehaviorSubject<any>(JSON.parse(this.storage.read('permissoes')));
    if (this.permissoes.getValue()) {
      return this.permissoes.getValue();
    } else {
      return [];
    }
  }

  public get currentPermissiosId() {
    this.permissoes = new BehaviorSubject<any>(JSON.parse(this.storage.read('permissoes')));
    if (this.permissoes.getValue()) {
      return this.permissoes.getValue().map(item => item.categoriaPermissao.id);
    } else {
      return [];
    }
  }

  public isAdmin(): Boolean {
    this.currentUser = new BehaviorSubject<any>(this.storage.read('isAdmin'));
    if (this.currentUser.getValue() === undefined || this.currentUser.getValue() === null || this.currentUser.getValue() === 'false' ) {
      this.temPermissao.emit(false);
      return false;
    } else {
      this.temPermissao.emit(true);
      return true;
    }
  }
}
