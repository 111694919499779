<a mat-list-item [ngStyle]="{'padding-left': (depth * 20) + 'px'}"
  [routerLink]="!item.children || !item.children.length ? ('/' + item.route) : null" (click)="onItemSelected(item)"
  [title]="item.displayName" [ngClass]="{'active': item.route ? isActive(item): false, 'expanded': expanded}">
  <i [class]="'routeIcon ' + item.iconName"></i>

  <!-- <mat-icon class="routeIcon">{{}}</mat-icon> -->
  <span class=" w-100 line-clamp">{{item.displayName}}</span>
  <span class="d-flex" *ngIf="item.children && item.children.length">
    <!-- <span class="mr-auto"></span> -->
    <mat-icon [@indicatorRotate]="expanded ? 'expanded': 'collapsed'">
      expand_more
    </mat-icon>
  </span>

</a>

<hr [ngStyle]="{'margin-left': (depth * 20) + 'px'}" *ngIf="!expanded"
  style="margin: 0; border-top: 1px solid rgba(99, 99, 99, 0.1);" />
<div *ngIf="expanded">
  <app-menu-list-item *ngFor="let child of item.children" [item]="child" [depth]="depth+1">

  </app-menu-list-item>
</div>