import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AtualizarNavToolService {

  emitirEvento = new EventEmitter<any>();

constructor() { }

}
