import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { switchMap, timer } from 'rxjs';
import { ModalAlertasComponent } from 'src/app/util/modal-module/modal-alertas/modal-alertas.component';
import { ModalCertezaComponent } from 'src/app/util/modal-module/modal-certeza/modal-certeza.component';

import { SosService } from '../sos.service';
import { ModalDetalhesRelatorioComponent } from './modal-detalhes-relatorio/modal-detalhes-relatorio.component';

@Component({
	selector: 'app-modal-detalhes-ocorrencia',
	templateUrl: './modal-detalhes-ocorrencia.component.html',
	styleUrls: ['./modal-detalhes-ocorrencia.component.scss'],
})
export class ModalDetalhesOcorrenciaComponent implements OnInit, OnDestroy {

   public urgencia: boolean = false;
	public ocorrencia: any;
	public titulo: string = 'Ocorrência';
	public emergencialUm: string;
	public emergencialDois: string;
   public  observacao: string = '';
   public noClick = true;

	private notificacao: any;
	private numerosDeTelefone: any[] = [];

	private destroy$;

	constructor(
		public dialogRef: MatDialogRef<ModalDetalhesOcorrenciaComponent>,
		public datePipe: DatePipe,
		public sosService: SosService,
      private snackBar: MatSnackBar,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.urgencia = data.urgencia;
		this.ocorrencia = data.oco;
		this.notificacao = data.id;
		this.numerosDeTelefone = data.oco?.solicitante?.telefoneEmergencial;
	}

	ngOnInit(): void {
		if (this.urgencia) {
			this.titulo = 'SOS Recebido';
			this.iniciarNotificacaoEmergencia();
		} else {
         this.titulo = 'Ocorrência Nº ' + this.ocorrencia?.id;
      }

		if (this.notificacao != null) {
			let idNumero: number = this.notificacao;
			this.sosService.getOcorrencia(idNumero).subscribe((oco) => {
				this.ocorrencia = oco;
			});
		}

		this.numerosDeTelefone = this.ocorrencia?.solicitante?.telefoneEmergencial?.split(',');
		if (this.numerosDeTelefone) this.emergencialUm = this.numerosDeTelefone[0];

		if (this.numerosDeTelefone?.length > 1) {
			this.emergencialDois = [...this.numerosDeTelefone?.slice(1)].join(', ');
		}

		if (this.ocorrencia?.observacao) {
			this.observacao = this.ocorrencia?.observacao?.observacao || '';
		}
	}

	ngOnDestroy(): void {
		this.muteSoundEmergency();
	}

	iniciarNotificacaoEmergencia(): void {
      this.noClick = true;
		const notificationSoundEmergency = new Audio();
		notificationSoundEmergency.src = 'https://s3.sa-east-1.amazonaws.com/fs-teste-app-cidade-divinopolis/1711124788800-notificacao-emergencia-sos.mp3';
		notificationSoundEmergency.playbackRate = 1.5;
		this.destroy$ = timer(0, 1000).pipe(
		  switchMap(() => notificationSoundEmergency.play()),
		).subscribe();
	}

	muteSoundEmergency() {
		if (this.destroy$) {
			this.destroy$.unsubscribe();
			this.noClick = false;
		}
	}

	mostrarRelatorio() {
		this.dialog.open(ModalDetalhesRelatorioComponent, {
			data: {
				ocorrencia: this.ocorrencia,
			}
		});
	}

	openLink() {
		window.open(
			`https://www.google.pt/maps?q=${this.ocorrencia?.latitude},${this.ocorrencia?.longitude}`,
		);
	}

	getHoraFormatada(data) {
		return moment.utc(data).local();
	}

	salvarObservacao() {
		const cidadaoSolicitanteId = this.ocorrencia?.cidadaoSolicitante.id;
		if (!cidadaoSolicitanteId) return;

      this.sosService.addObservacaoSos(this.ocorrencia?.id, cidadaoSolicitanteId, this.observacao)
      .subscribe({
         next: () => {
            this.dialog.open(ModalAlertasComponent, {
               data: {
                  mensagem: 'Observação salva com sucesso!'
               }
            });
         },
         error: () => {
            this.dialog.open(ModalAlertasComponent, {
               data: {
                  textoModal: 'Erro ao salvar observação!',
                  opcaoUm: 'Fechar',
               }
            });
         }
      });
	}

	fechar() {
		if (!this.urgencia) {
			this.dialogRef.close();
			return;
		}

		this.dialog.open(ModalCertezaComponent, {
			data : {
				textoModal: 'Deseja fechar a ocorrência?',
				opcaoUm: 'Cancelar',
				opcaoDois: 'Fechar',
			}
		}).afterClosed().subscribe((res) => {
			if (res) this.dialogRef.close();
		});
	}
}
