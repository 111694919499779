import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpUtilService } from '../../services/http-util-service';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AgendamentoServicoService {



    // @ts-ignore
    url = environment.API_URL + '/agendamento/servico';

    constructor(
        public http: HttpClient,
        public util: HttpUtilService
    ) { }

    getWebviews(): Observable<any> {
        return this.http.get(this.url, this.util.httpOptions());
    }

    getWebviewsPageable(options: any, categoriaId: number): Observable<any> {
        return this.http.post(`${this.url}/${categoriaId}/pageable`, options, this.util.httpOptions());
    }

    getWebviewsById(id: number): Observable<any> {
        return this.http.get(this.url + '/' + id, this.util.httpOptions());
    }
    getPerguntasAgendamento(id: number): Observable<any> {
      return this.http.get(this.url + `/${id}/perguntas-form`, this.util.httpOptions());
  }

    createWebview(model): Observable<any> {
        return this.http.post(this.url, model, this.util.httpOptions());
    }

    updateWebview(model): Observable<any> {
        return this.http.put(this.url, model, this.util.httpOptions());
    }

    deleteWebview(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id, this.util.httpOptions());
    }

    getCategorias(): Promise<any> {
        return this.http.get(this.url + '/all', this.util.httpOptions()).toPromise();
    }

    getCategoriasPageable(options: any): Observable<any> {
        return this.http.post(this.url + '/pageable', options, this.util.httpOptions());
    }

    getCategoriasById(id: number): Promise<any> {
        return this.http.get(this.url + '/' + id, this.util.httpOptions()).toPromise();
    }

    //TODO: FAZER CONTROLLER PARA FUNÇÃO
    getCategoriasByBothStatus() {}

    createCategoria(model): Promise<any> {
        return this.http.post(this.url, model, this.util.httpOptions()).toPromise()
    }

    updateCategoria(model): Promise<any> {
        return this.http.put(this.url, model, this.util.httpOptions()).toPromise()
    }

    deleteCategoria(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id, this.util.httpOptions())
    }
}
