import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "../guards/auth.guard";
import { PermissionGuard } from "../guards/permissoes.guard";
import { AutenticidadeDocumentosComponent } from "./autenticidade-documentos.component";

const autenticidadeRoutes: Routes = [
    {
        path: '',
        component: AutenticidadeDocumentosComponent
    },
    
]

@NgModule({

    imports: [RouterModule.forChild(autenticidadeRoutes)],
    exports: [RouterModule]

})
export class AutenticidadeDocumentosRoutingModule {}