import { HttpUtilService } from './../services/http-util-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AutenticidadeService {

  private url = environment.API_URL

  constructor(
    private http: HttpClient,
    private util: HttpUtilService
  ) { }

  getDocumento(hash: number, cidade: string): Promise<any> {
    let options = {
      headers: new HttpHeaders({
        cidade
      })
    }
    return this.http.get(this.url + "/anexo-assinatura/" + hash, options)
      .toPromise()
      .then((data) => data)
      .catch((err) => Promise.reject(err))
  }

  getAssinaturas(id: number, cidade: string): Promise<any> {
    let options = {
      headers: new HttpHeaders({
        cidade
      })
    }
    return this.http.get(this.url + "/anexo-assinatura-cidadao/anexo/" + id, options)
      .toPromise()
      .then((data) => data)
      .catch((err) => Promise.reject(err))
  }

  getHistoricoAnexosByUrlDeUmAnexo(url: string, cidade: string): Promise<any> {
    let options = {
      headers: new HttpHeaders({
        cidade
      })
    }
    return this.http.post(this.url + "/historico-anexo/autenticidade-documentos/url", url, options)
      .toPromise()
      .then((data) => data)
      .catch((err) => Promise.reject(err));
  }

  getCidadeByNomeReferencia(): Observable <any> {
    return this.http.get(this.url + '/master/adm/cidade', this.util.httpOptions());
  }

  getPrefeituraCidadeByNomeReferencia(): Observable <any> {
    return this.http.get(this.url + '/master/adm/prefeitura', this.util.httpOptions());
  }

  getCamaraByNomeReferencia(): Observable <any> {
   return this.http.get(this.url + '/master/configuracao-aplicativo-camara', this.util.httpOptions());
  }

  getArquivoByHashContido(file): Observable <any> {
    return this.http.post(this.url + '/anexo-assinatura/by-file', file, this.util.httpOptionsMultipart());
  }
}
