import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PermissoesCategoriasService {

    public url = environment.API_URL + '/agendamento-servico/permissoes';

    constructor(
        public http: HttpClient,
        public util: HttpUtilService
    ) { }

    createPermissao(dto): Observable<any> {
        return this.http.post(this.url, dto, this.util.httpOptions());
    }

    findPermissaoById(permissaoId): Observable<any> {
        return this.http.get(`${this.url}/${permissaoId}`, this.util.httpOptions());
    }

    findAllPermissaoCidadao(cidadaoId): Observable<any> {
        let url = this.url;
        url += `?&cidadaoId=${encodeURIComponent(cidadaoId)}`;
        return this.http.get(`${url}`, this.util.httpOptions());
    }

    deletePermissaoById(permissaoId): Observable<any> {
        return this.http.delete(`${this.url}/${permissaoId}`, this.util.httpOptions());
    }

    findAllCidComPermissao(page: number = 0, size: number = 10, nome: string): Observable<any> {
        let url = this.url;
        url += `/cids?page=${page}&size=${size}`;
        if (nome != null) {
            url += `&nome=${nome}`;
        }
        return this.http.get(`${url}`, this.util.httpOptions());
    }

    findAllServicos(): Observable<any> {
        return this.http.get(`${this.url}/servicos`, this.util.httpOptions());
    }

    findAllCategoriasAndSubcategorias(servicoId: number): Observable<any> {
        return this.http.get(`${this.url}/categorias/${servicoId}`, this.util.httpOptions());
    }

    findEstruturaCompleta(): Observable<any> {
        return this.http.get(`${this.url}/estrutura-completa`, this.util.httpOptions())
    }

    deleteAllByCidadaoId(cidadaoId: number): Observable<any> {
        return this.http.delete(`${this.url}/by-cidadao/${cidadaoId}`, this.util.httpOptions());
    }

    criaMultiplo(dto): Observable<any> {
        return this.http.post(`${this.url}/multiplo`, dto, this.util.httpOptions());
    }

    findPermissaoEspecifica(servicoId: number, tipoServico: string): Observable<any> {
        let url = `${this.url}/logado/${servicoId}`;
        url += `?tipoServico=${encodeURIComponent(tipoServico)}`;
        return this.http.get(`${url}`, this.util.httpOptions());
    }
}
