import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HistoricoImpressaoQrCode } from '../model/historico-impressao-qr-code';
import { UtilidadePublicaCategoria } from '../model/utilidadePublicaCategoria.model';
import { HttpUtilService } from '../services/http-util-service';
import { StorageService } from '../services/storage.service';

@Injectable({
	providedIn: 'any',
})
export class UtilidadePublicaService {
	//emite evento para componente pai
	@Output() public atualizaNavbarUtilidadePublica = new EventEmitter();

	url = `${environment.API_URL}/utilidadepublica`;
	baseUrl = environment.API_URL;

	constructor(
		public http: HttpClient,
		public util: HttpUtilService,
		public storage: StorageService,
	) {}

	/**
	 * Busca todas as servico de Utilidade Publica
	 */
	getCategorias(): Promise<any> {
		return this.http
			.get(this.url + '/categoria', this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todas as servico de Utilidade Publica
	 * @param id
	 */
	getCategoriasById(id: number): Promise<any> {
		return this.http
			.get(this.url + '/categoria/' + id, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Cria uma categoria de Utilidade Publica
	 * @param categoria
	 */
	createCategoria(categoria): Promise<any> {
		return this.http
			.post(this.url + '/categoria/angular', categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarUtilidadePublica.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Atualiza uma categoria de Utilidade Publica
	 * @param categoria
	 */
	updateCategoria(categoria: UtilidadePublicaCategoria): Promise<any> {
		return this.http
			.put(this.url + '/categoria', categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarUtilidadePublica.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Deleta uma categoria de Utilidade Publica
	 * @param id
	 */
	deleteCategoria(id: number): Promise<any> {
		return this.http
			.delete(this.url + '/categoria/' + id, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarUtilidadePublica.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todos os elementos de determinada categoria de Utilidade Publica
	 * @param categoria categoria do Utilidade Publica
	 */
	getAll(categoria: string): Promise<any> {
		return this.http
			.get(this.url + '/categoria/all/' + categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todos os elementos de determinada categoria de Utilidade Publica
	 * @param categoria categoria do Utilidade Publica
	 */
	getAllUp(): Observable<any> {
		return this.http.get(this.url, this.util.httpOptions());
	}

	/**
	 * Busca todos os elementos de determinada categoria de Utilidade Publica
	 * @param id
	 * @param model
	 */
	getAllByIdPageable(id: number, model): Observable<any> {
		return this.http.post(`${this.url}/${id}/pageable`, model, this.util.httpOptions());
	}

	getAllSolicitacaoCadastroByCategoriaIdPageable(categoriaId: number, model): Observable<any> {
      let params = new HttpParams();
      if (categoriaId != null) params = params.append("idCategoria", categoriaId);

		return this.http.post(`${this.url}/solicitacao-cadastro-pageable`, model, { ...this.util.httpOptions(), params });
	}

	/**
	 * Busca todos os dados de uma objeto da categoria
	 * @param id id da categoria
	 */
	getById(id: number): Promise<any> {
		return this.http
			.get(this.url + '/' + id, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todos os dados pela categoria
	 * @param id id da categoria
	 */
	getByCategoriaId(id: number): Promise<any> {
		return this.http.get(this.url + '/categoria/all/' + id, this.util.httpOptions()).toPromise();
	}

	/**
	 * Cria um objeto da categoria selecionada
	 * @param model objeto que deseja criar
	 * @param categoria tipo do objeto que deseja criar
	 */
	create(model: any): Promise<any> {
		return this.http
			.post(this.url + '/angular', model, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => console.error(err));
	}

	/**
	 * Atualiza objeto selecionado
	 * @param model objeto que deseja atualizar
	 * @param categoria tipio do objeto que deseja atualizar
	 */
	update(model: any): Promise<any> {
		return this.http
			.put(this.url + '/angular', model, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

   atualizarSolicitacaoCadastro(model: any): Observable<any> {
      return this.http.put(this.url + "/atualizar-solicitacao-cadastro", model, this.util.httpOptions())
   }

	uploadImagem(utilidadeId: number, formData: FormData): Observable<any> {
		return this.http.post(
			this.url + `/upload-imagem/${utilidadeId}`,
			formData,
			this.util.httpOptionsMultipart(),
		);
	}

	deletaImagem(imagemId: number): Observable<any> {
		return this.http.delete(this.url + `/deleta-imagem/${imagemId}`, this.util.httpOptions());
	}

	/**
	 * Deleta uma utilidade publica
	 * @param {number} id
	 * @param {string} categoria
	 * @returns {Promise<any>}
	 * @memberof UtilidadePublicaService
	 */
	delete(id: number, categoria: string): Promise<any> {
		return this.http
			.delete(this.url + '/' + id, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca uma imagem pela url
	 * @param path
	 */
	getImagem(path): Promise<any> {
		let headers = new HttpHeaders({ Authorization: 'Bearer ' + this.storage.read('token') });
		return this.http
			.get(path, { headers: headers, responseType: 'blob' })
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 *  Busca um endereço atraves da latitude e da longitude
	 * @param url
	 */
	buscaCoordenada(url): Promise<any> {
		return this.http
			.get(url)
			.toPromise()
			.then((data) => data);
	}

	getAllFuncionamentos(): Promise<any> {
		return this.http.get(this.baseUrl + '/funcionamento-utp', this.util.httpOptions()).toPromise();
	}

	getAllFuncionamentoByUtp(IdUtp: number): Promise<any> {
		return this.http
			.get(this.baseUrl + '/funcionamento-utp/utp/' + IdUtp, this.util.httpOptions())
			.toPromise();
	}

	salvarFuncionamento(horariosFuncionamento): Promise<any> {
		return this.http
			.post(this.baseUrl + '/funcionamento-utp', horariosFuncionamento, this.util.httpOptions())
			.toPromise();
	}

	gerarQrCode(utilidadeId: number, largura: number, altura: number): Observable<any> {
		const headers = new HttpHeaders({
			Authorization: 'Bearer ' + this.storage.read('token'),
			cidade: this.storage.read('cidade'),
			'Content-Type': 'application/json',
		});
		return this.http.get(
			`${this.url}/qr-code/${utilidadeId}/?largura=${largura}&altura=${altura}`,
			{ headers, responseType: 'arraybuffer' },
		);
	}

	salvarHistoricoQrCode(historico: HistoricoImpressaoQrCode): Observable<any> {
		return this.http.post(
			this.baseUrl + '/historico-impressao',
			historico,
			this.util.httpOptions(),
		);
	}

	getHistoricoQrCode(id: number, filter): Observable<any> {
		return this.http.post(
			this.baseUrl + `/historico-impressao/utilidade/${id}/pageable`,
			filter,
			this.util.httpOptions(),
		);
	}
}
