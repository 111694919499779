import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpUtilService } from '../../services/http-util-service';
import { WhatsappMessageDto } from 'src/app/model/whatsapp-message-dto';

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  url = environment.API_URL + '/whatsapp';

  constructor(
    public http: HttpClient,
    public util: HttpUtilService
  ) { }

  getQrcode(): Observable<any> {
    return this.http.get(`${this.url}/getQrCodeImage`, this.util.httpOptions())
  }

  sendMessage(dto: WhatsappMessageDto, telefone: string): Observable<any> {
    let url = `${this.url}/envia-mensagem-adm`;
    if (telefone) {
      url += `?holder=${telefone}`
    }
    return this.http.post(`${url}`, dto, this.util.httpOptions())
  }

  cidadePossuiWhatsapp(): Observable<any> {
    return this.http.get(`${environment.API_URL}/master/configuracao-whatsapp/cidade`, this.util.httpOptions())
  }

  // CONFIG DO APP

  buscaConfiguracaoAplicativo() {
    return this.http.get(`${environment.API_URL}/master/configuracao-aplicativo`, this.util.httpOptions())
  }

  buscaTodasConfiguracoes() {
    return this.http.get(`${environment.API_URL}/master/configuracao-whatsapp/all`, this.util.httpOptions())
  }

  testConnection(cidade: any): Observable<any> {
    return this.http.get(`${this.url}/getQrCodeImage/teste/${cidade}`, this.util.httpOptions());
  }

  buscaBackupsCidade(nome_cidade): Observable<any> {
    return this.http.get(`${environment.API_URL}/master/configuracao-whatsapp/backups/${nome_cidade}`, this.util.httpOptions())
  }

  attConfig(config): Observable<any> {
    return this.http.put(`${environment.API_URL}/master/configuracao-whatsapp/att-config`, config, this.util.httpOptions())
  }

  // DASHBOARD

  depreciarFrequencia(dto: any): Observable<any> {
    return this.http.put(`${environment.API_URL}/frequencia-funcoes/depreciar`, dto, this.util.httpOptions());
  }

  reabilitarFrequencia(dto: any): Observable<any> {
    return this.http.put(`${environment.API_URL}/frequencia-funcoes/reabilitar`, dto, this.util.httpOptions());
  }

  exibirRegistros(dto: any): Observable<any> {
    return this.http.put(`${environment.API_URL}/frequencia-funcoes/exibir-registros`, dto, this.util.httpOptions());
  }

  findAllFrequenciaPageable(dto: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/frequencia-funcoes/pageable`, dto, this.util.httpOptions());
  }

  buscaMetricaAtiva(): Observable<any> {
    return this.http.get(`${this.url}-metrica/ativa`, this.util.httpOptions());
  }

  findAllMetricaPageable(dto: any): Observable<any> {
    return this.http.post(`${this.url}-metrica/pageable`, dto, this.util.httpOptions());
  }

  findTop5(): Observable<any> {
    return this.http.get(`${environment.API_URL}/frequencia-funcoes/top-5`, this.util.httpOptions());
  }

  findAllFuncoes(): Observable<any> {
    return this.http.get(`${environment.API_URL}/frequencia-funcoes/todas`, this.util.httpOptions());
  }

}
