<h2 mat-dialog-title>Envio de arquivo</h2>
<button mat-icon-button (click)="closeModal()" class="close-button">
   <mat-icon>close</mat-icon>
</button>

<div mat-dialog-content>
   <form [formGroup]="uploadForm">
      <!-- Campo para o nome do arquivo -->
      <div class="d-flex flex-column">
         <mat-form-field appearance="fill">
            <mat-label>Nome do arquivo</mat-label>
            <input matInput placeholder="Digite o nome do arquivo" [formControl]="uploadForm.get('fileName')" />
            <mat-error *ngIf="uploadForm.get('fileName')?.hasError('required')">
               O nome do arquivo é obrigatório
            </mat-error>
         </mat-form-field>

         <!-- Botão para selecionar arquivo -->
         <button mat-raised-button color="accent" (click)="fileInput.click()">
            Selecionar Arquivo
         </button>
      </div>

      <input id="fileInput" type="file" (change)="onFileSelected($event)" hidden #fileInput />

      <!-- Exibir linha com o nome do arquivo, ícone e opção de remover -->
      <div id="fileDetail" class="mt-3">
         <div *ngIf="uploadForm.get('file')?.value" class="file-info">
            <div class="d-flex flex-row justify-content-around">
               <mat-icon>insert_drive_file</mat-icon>
               <span>{{ uploadForm.get('file')?.value.name }}</span>
               <a href="#" (click)="removeFile()">Remover</a>
            </div>
         </div>
      </div>

      <mat-error *ngIf="uploadForm.get('file')?.hasError('required')">
         O arquivo é obrigatório
      </mat-error>
   </form>
</div>

<div mat-dialog-actions align="end">
   <button mat-raised-button color="primary" (click)="uploadFile()" [disabled]="loading || uploadForm.invalid">
      <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
      <span *ngIf="!loading">Enviar</span>
   </button>
   <button mat-button (click)="closeModal()">Cancelar</button>
</div>