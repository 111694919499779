import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BaseDeConhecimento } from '../_models/base-de-conhecimento';
import { BaseDeConhecimentoService } from '../_services/base-de-conhecimento.service';
import { BaseDeConhecimentoModalPorArtigoComponent } from '../modal-por-artigo/base-de-conhecimento-modal-por-artigo.component';

@Component({
  selector: 'app-base-de-conhecimento-middleware-lista',
  templateUrl: './base-de-conhecimento-middleware-lista.component.html',
  styleUrls: ['./base-de-conhecimento-middleware-lista.component.scss'],
})
export class BaseDeConhecimentoMiddlewareListaComponent implements OnInit, AfterViewInit, OnDestroy {
  filtrando = false;

  // controla subscribe da rota
  private sub: any;

  loading = false;
  listaArtigos = [];

  constructor(
    private ref: MatDialogRef<BaseDeConhecimentoMiddlewareListaComponent>,
    private dialogService: MatDialog,
    private baseDeConhecimentoService: BaseDeConhecimentoService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.listByRotinas(data);
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  listByRotinas(rotinas: number[]) {
    this.filtrando = true;
    this.baseDeConhecimentoService.listByCategoriaIdList(rotinas).subscribe({
      next: (data) => {
        const div = document.createElement('div');

        this.listaArtigos = data.map(item => {
          div.innerHTML = item.descricao;
          item.descricaoContent = div.textContent;
          return item;
        });
      }
    })
  }

  verArtigo(artigo: BaseDeConhecimento) {
    this.dialogService.open(BaseDeConhecimentoModalPorArtigoComponent, {
      data: artigo,
    });
  }

  close() {
    this.ref.close();
  }
}
