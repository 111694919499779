<div style="overflow-x: auto; height: 100vh;">
  <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

  <div class="d-flex align-items-center" *ngIf="!loading" style="overflow-y: scroll;">

    <div class="mt-2 container-fluid">

      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/login">
            <i class="fas fa-home"></i>
          </a>
        </li>
        <li class="breadcrumb-item">Autenticidade Documentos</li>
      </ol>


      <div class="d-flex justify-content-center align-items-center p-5"
        style="border: 1px solid rgb(148, 148, 148); border-radius: 10px;" *ngIf="!documento && !procurou">
        <div class="d-flex justify-content-center align-items-center flex-column w-100">
          <div class="search-icon">
            <i class="fas fa-search fa-lg" style="width: 60px; height: 60px;"></i>
          </div>
          <div class="textBlock">
            <h5>Validar documento por código</h5>
            <div class="d-flex flex-row" matTooltipPosition="right" matTooltipHideDelay="1500"
              matTooltip="O Hashcode, representa a assinatura digital de seu documento. Podendo ser encontrado prioritariamente no canto inferior esquerdo de suas páginas.">
              <p>Como encontrar o código em seu documento?</p>
              <div *ngIf="windowWidth > 768"><i style="color: rgb(174, 174, 88);"
                  class="fa-solid fa-lightbulb mt-1 ml-3"></i></div>
            </div>
          </div>
          <div class="searchBlock w-100">
            <mat-form-field class=" w-50 searchInput" appearance="outline" [style.fontSize.px]="14">
              <mat-label><b>Pesquisar</b> por hash code</mat-label>
              <input matInput [formControl]="formHashCode">
            </mat-form-field>
            <button class="searchButton" mat-raised-button color="primary" (click)="search()"> Buscar</button>
          </div>

          <hr>

          <div class="search-icon">
            <i class="fas fa-folder fa-lg" style="width: 60px; height: 60px;"></i>
          </div>
          <div class="textBlock">
            <h5>Validar documento por arquivo</h5>
            <div class="d-flex flex-row" matTooltipPosition="right" matTooltipHideDelay="1500"
              matTooltip="Envie o arquivo que deseja verificar/validar no campo abaixo.">
              <p>Selecione um arquivo nas pastas de seu computador/telefone</p>
              <div *ngIf="windowWidth > 768"><i style="color: rgb(174, 174, 88);"
                  class="fa-solid fa-lightbulb mt-1 ml-3"></i></div>
            </div>
          </div>

          <div class="searchBlock w-100">
            <label for="fileInput" class="file-input-button d-flex flex-row align-items-center">
              <div *ngIf="!file">
                <span>Selecionar arquivo</span>
                <input type="file" id="fileInput" class="file-input" accept=".pdf" (change)="handleFileInput($event)">
              </div>
              <div class="d-flex flex-row align-items-center" *ngIf="file">
                <div>
                  <span><strong>Arquivo selecionado: </strong> {{ file.name }}</span>
                </div>
              </div>
            </label>
            <button *ngIf="file" class="ml-2" mat-mini-fab style="background-color: whitesmoke; color: black;" (click)="file = null">
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <button *ngIf="file" class="searchButton" mat-raised-button color="primary" (click)="searchFile()">Pesquisar este arquivo</button>

          <div class="row" *ngIf="!documento && pesquisou">
            <div class="d-flex flex-column justify-content-center align-items-center w-100">
              <img src="../../assets/images/not_found.jpg" width="170vw" height="170vh">
              <h5>Documento não encontrado</h5>
            </div>
          </div>

          <mat-card class="mt-3" *ngIf="windowWidth < 768">
            <mat-card-header>
              <mat-card-subtitle>Como localizar o código de um documento?</mat-card-subtitle>
            </mat-card-header>

            <mat-card-content>
              <p>O Hashcode, representa a assinatura digital de seu documento. Podendo ser encontrado prioritariamente
                no canto inferior esquerdo de suas páginas.</p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>


      <div class="row p-4" *ngIf="documento && formHashCode?.value != null">

        <div id="headerCidade" class="mb-4">
          <img id="logoPrefeitura" src="{{ cidadeObj.logo }}" width="120vw" height="120vh">
          <h3 id="nomePrefeitura">{{ getNome(prefeitura?.nome) }}</h3>
        </div>

        <div class="col-sm-12 col-md-12 col-lg-4 pb-2" id="assinaturaBlock">
          <h5>Assinaturas do Documento</h5>

          <mat-accordion>
            <ng-container *ngFor="let assinatura of assinaturas; let i = index">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    Assinante {{ i + 1 }}
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="row pt-1">
                  <div class="col-12" style="line-height: 10px;">
                    <div class="col-12">
                      <label style="font-weight: bold;">Assinante:&nbsp;&nbsp;</label><span class="h-100">{{
                        assinatura.cidadao.nome }}</span>
                    </div>
                  </div>
                </div>

                <div class="row pb-1">
                  <div *ngIf="assinatura.dataHora;then ifDataHora else ifData"></div>

                  <ng-template #ifDataHora>
                    <div class="col-12" style="line-height: 10px;" *ngIf="assinatura.dataHora">
                      <div class="col-12">
                        <label style="font-weight: bold;">Data Assinatura:&nbsp;&nbsp;</label><span class="h-100">{{
                          getDate(assinatura.dataHora) }}</span>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template #ifData>
                    <div class="col-12" style="line-height: 10px;" *ngIf="assinatura.data">
                      <div class="col-12">
                        <label style="font-weight: bold;">Data Assinatura:&nbsp;&nbsp;</label><span class="h-100">{{
                          assinatura.data | date: 'dd/MM/yyyy HH:mm' }}</span>
                      </div>
                    </div>
                    <div class="col-12" style="line-height: 10px;" *ngIf="!assinatura.data">
                      <div class="col-12">
                        <label style="font-weight: bold;">Assinatura:&nbsp;&nbsp;</label><span class="h-100">O cidadão
                          {{ assinatura.cidadao.nome }} ainda não assinou o documento</span>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
          <div *ngIf="carimbos.length > 0">
            <h5 class="pt-3">Carimbos do Documento</h5>

            <mat-accordion>
              <ng-container *ngFor="let carimbo of carimbos; let i = index">
                <mat-expansion-panel>
                 <mat-expansion-panel-header>
                    <mat-panel-title>
                      <span>Carimbo {{ i + 1 }} &nbsp;&nbsp;</span>
                      <span
                        [ngStyle]="{ color: carimbo?.info?.valido ? 'green' : 'red' }">
                        {{ carimbo?.info?.valido ? " (Válido)" : " (Inválido)" }}
                      </span>
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <div class="row pt-1">
                    <div class="col-12" style="line-height: 10px;">
                      <div class="col-12">
                        <label style="font-weight: bold;">Cidadão:&nbsp;&nbsp;</label><span class="h-100">{{
                          carimbo.cidadao.nome }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-1">
                    <div class="col-12" style="line-height: 10px;">
                      <div class="col-12">
                        <label style="font-weight: bold;">Tipo:&nbsp;&nbsp;</label>
                        <span class="h-100" [ngStyle]="{ color: (carimbo?.info?.tipoCarimbo == 'APROVADO') ? 'green' : 'red' }">{{carimbo?.info?.tipoCarimbo }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-1" *ngIf="carimbo?.info?.tipoNumero['crea']">
                    <div class="col-12" style="line-height: 10px;">
                      <div class="col-12">
                        <label style="font-weight: bold;">CREA:&nbsp;&nbsp;</label><span class="h-100">{{
                          carimbo?.info?.numeroValor['crea'] || 'Não informado'}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-1" *ngIf="carimbo?.info?.tipoNumero['matricula']">
                    <div class="col-12" style="line-height: 10px;">
                      <div class="col-12">
                        <label style="font-weight: bold;">Matrícula:&nbsp;&nbsp;</label><span class="h-100">{{
                          carimbo?.info?.numeroValor['matricula'] || 'Não informado'}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pt-1" *ngIf="carimbo?.info?.tipoNumero['protocolo']">
                    <div class="col-12" style="line-height: 10px;">
                      <div class="col-12">
                        <label style="font-weight: bold;">Protocolo:&nbsp;&nbsp;</label><span class="h-100">{{
                          carimbo?.info?.numeroValor['protocolo'] || 'Não informado'}}</span>
                      </div>
                    </div>
                  </div>

                  <div class="row pb-1">
                     <div class="col-12" style="line-height: 10px;">
                        <div class="col-12">
                          <label style="font-weight: bold;">Data do Carimbo:&nbsp;&nbsp;</label><span class="h-100">{{
                            getDate(carimbo.dataHora) }}</span>
                        </div>
                      </div>
                  </div>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>
        </div>

        <div *ngIf="!isOS()" class="col-sm-12 col-md-12 col-lg-8" id="docBlock">
         <div id="progress" class="offset-sm-6 offset-md-6 offset-lg-4">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
          </div>
          <ngx-doc-viewer id="docViewer" googleCheckContentLoaded="true" googleCheckInterval="3000" googleMaxChecks="5"
            [url]="documento.url" viewer="google" (loaded)="onFileLoad()">
          </ngx-doc-viewer>
        </div>

        <div *ngIf="isOS()" class="col-sm-12 col-md-12 col-lg-8" id="docBlock">
         <span>O seu dispositivo não é compatível com a função de visualização prévia dos documentos. <a target="_blank" [href]="documento.url">Clique aqui para baixar o arquivo</a>.</span>
       </div>


        <div class="retrySearchBlock">
          <button id="buttonRetrySearchBlock" mat-raised-button color="primary" (click)="resetSearch()">Pesquisar outro
            documento <i class="fas fa-search fa-sm"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>
