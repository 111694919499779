<h2 mat-dialog-title>Recuperar senha</h2>
<form [formGroup]="formulario" novalidate (ngSubmit)="esqueceuASenha()">
  <div mat-dialog-content  class="mb-3" style=" max-width: 500px;">
    <label class="mb-2">
      <small>Se você esqueceu sua senha, digite seu CPF abaixo. Enviaremos uma link e as instruções de recuperação da
        senha para
        o seu e-mail cadastrado.</small>
    </label>

    <div class="form-group">
      <input class="form-control" type="text" aria-describedby="cpfHelp" placeholder="CPF" autocomplete="off"
        ng-pastern="/^[0-9]*$/" mask="000.000.000-00" formControlName="documento">
      <small *ngIf="!formulario.get('documento').valid && formulario.get('documento').touched" class="form-text text-danger">CPF ou RNE
        inválido</small>
    </div>
    <div class="form-group">
      <select class="form-control" id="cidade" (ngModelChange)="setaCidade()" formControlName="cidade">
        <option value="-1">Selecione sua cidade</option>
        <option *ngFor="let item of cidades" value="{{ item.nomeReferencia }}">{{item.nome}}</option>
      </select>
      <small *ngIf="formulario.get('cidade').value === -1 && formulario.get('cidade').touched"
        class="form-text text-danger">Selecione sua cidade</small>
    </div>
    <div *ngIf="sucesso">
      <small class="text-success">E-mail enviado com sucesso!</small>
      <i class="fas fa-check text-success"></i>
    </div>
    <div *ngIf="erro">
      <small class="text-danger">Erro ao enviar e-mail!</small>
      <i class="fas fa-exclamation text-danger"></i>
    </div>
  </div>
  <div mat-dialog-actions class="d-block text-right">
    <div *ngIf="!sucesso">
      <button type="submit" class="btn btn-sm btn-success mx-2" style="min-width: 80px;"
        [disabled]="loadingBtn || formulario.status === 'INVALID'">
        <mat-spinner class="d-inline-flex m-0 p-0 text-center align-middle" *ngIf="loadingBtn" diameter="20">
        </mat-spinner>
        <span *ngIf="!loadingBtn">
          <i class="fas fa-share-square"></i>
        </span>
        Enviar
      </button>
      <button type="button" class="btn btn-sm btn-secondary mx-2" style="min-width: 80px;" (click)="close()">
        <i class="fas fa-times"></i>
        Cancelar
      </button>
    </div>
    <div *ngIf="sucesso">
      <button type="button" class="btn btn-sm btn-secondary mx-2" style="min-width: 80px;" (click)="close()">
        Fechar
      </button>
    </div>
  </div>
</form>
