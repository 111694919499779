import { Injectable, EventEmitter, Output, Directive } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { HttpUtilService } from "../services/http-util-service";
import { Observable } from "rxjs";
import { StorageService } from "../services/storage.service";
import { HistoricoImpressaoQrCode } from "../model/historico-impressao-qr-code";

@Injectable({
   providedIn: 'root'
})
export class TurismoService {

   //emite evento para componente pai
   @Output() public atualizaNavbarTurismo = new EventEmitter()

   private url = environment.API_URL

   constructor(
      private http: HttpClient,
      private util: HttpUtilService,
      private storage: StorageService,
   ) { }

   /**
    * Busca todas as servico de turismo
    */
   getCategorias(): Promise<any> {
      return this.http.get(this.url + "/turismo/categoria", this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
   * Busca todas as servico de turismo por id
   */
   getCategoriasById(id: number): Promise<any> {
      return this.http.get(this.url + "/turismo/categoria/" + id, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Cria uma categoria de turismo
    * @param categoria
    */
   createCategoria(categoria): Promise<any> {
      return this.http.post(this.url + "/turismo/categoria/angular", categoria, this.util.httpOptions())
         .toPromise()
         .then((data) => {
            this.atualizaNavbarTurismo.emit(true)
            return data
         })
         .catch((err) => Promise.reject(err))
   }

   /**
    * Atualiza uma categoria de turismo
    * @param categoria
    */
   updateCategoria(categoria): Promise<any> {
      return this.http.put(this.url + "/turismo/categoria/angular", categoria, this.util.httpOptions())
         .toPromise()
         .then((data) => {
            this.atualizaNavbarTurismo.emit(true)
            return data
         })
         .catch((err) => Promise.reject(err))
   }

   /**
    * Deleta uma categoria de turismo
    * @param id
    */
   deleteCategoria(id: number): Promise<any> {
      return this.http.delete(this.url + "/turismo/categoria/" + id, this.util.httpOptions())
         .toPromise()
         .then((data) => {
            this.atualizaNavbarTurismo.emit(true)
            return data
         })
         .catch((err) => Promise.reject(err))
   }

   /**
    * Busca todos os elementos de determinada categoria de turismo
    * @param categoria categoria do turismo
    */
   getAll(id: string): Promise<any> {
      return this.http.get(this.url + "/turismo/categoria/all/" + id, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Busca todos os elementos de determinada categoria de turismo de forma paginada
    * @param id
	 * @param model
    */
	getAllByIdPageable(id: number, model): Observable<any> {
		return this.http.post(`${this.url}/turismo/${id}/pageable`, model, this.util.httpOptions());
	}

   /**
   * Busca todos os elementos de determinada categoria de turismo
   */
   getTurismosByNome(nome: string): Observable<any> {
      return this.http.get(this.url + "/turismo/categoria/nome?nome=" + nome, this.util.httpOptions())
   }

   /**
    * Busca todos os elementos de determinada categoria de Utilidade Publica
    * @param categoria categoria do Utilidade Publica
    */
   getAllTurismo(): Observable<any> {
      return this.http.get(this.url + "/turismo", this.util.httpOptions())
   }


   /**
    * Busca todos os dados de uma objeto da categoria
    * @param id id da categoria
    */
   getById(id: number): Promise<any> {
      return this.http.get(this.url + "/turismo/" + id, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Cria um objeto da categoria selecionada
    * @param model objeto que deseja criar
    * @param categoria tipo do objeto que deseja criar
    */
   create(model: any): Promise<any> {
      return this.http.post(this.url + "/turismo/angular", model, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Atualiza objeto selecionado
    * @param model objeto que deseja atualizar
    * @param categoria tipio do objeto que deseja atualizar
    */
   update(model: any): Promise<any> {
      return this.http.put(this.url + "/turismo/angular", model, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Deleta o objeto selecionado
    * @param id id do objeto
    * @param categoria tipo do objeto que deseja deletar
    */
   delete(id: number): Promise<any> {
      return this.http.delete(this.url + "/turismo/" + id, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   /**
    * Busca um endereço atraves da latitude e da longitude
    * @param url url
    */
   buscaCoordenada(url): Promise<any> {
      return this.http.get(url)
         .toPromise()
         .then((data) => data)
   }

   /**
    * Busca uma imagem atraves da url
    * @param url
    */
   getImagem(url: string): Promise<any> {
      let headers = new HttpHeaders({ Authorization: "Bearer " + this.storage.read('token') })
      return this.http.get(url, { headers: headers, responseType: 'blob' })
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }


   /**
    * Busca uma imagem atraves da url
    * @param url
    */
   getAllFuncionamentos(): Promise<any> {
      return this.http.get(this.url + "/funcionamento-tur", this.util.httpOptions()).toPromise()
   }

   /**
    * Busca uma imagem atraves da url
    * @param url
    */
   getAllFuncionamentoByTur(IdUtp: number): Promise<any> {
      return this.http.get(this.url + "/funcionamento-tur/tur/" + IdUtp, this.util.httpOptions()).toPromise()
   }

   /**
    * Busca uma imagem atraves da url
    * @param url
    */
   salvarFuncionamento(horariosFuncionamento): Promise<any> {
      return this.http.post(this.url + "/funcionamento-tur", horariosFuncionamento, this.util.httpOptions()).toPromise()
   }

   uploadImagem(turismoId: number, formData: FormData): Observable<any> {
      return this.http.post(this.url + `/turismo/upload-imagem/${turismoId}`, formData, this.util.httpOptionsMultipart())
   }

   deletaImagem(imagemId: number): Observable<any> {
      return this.http.delete(this.url + `/turismo/deleta-imagem/${imagemId}`, this.util.httpOptions());
   }


   gerarQrCode(turismoId: number, largura: number, altura: number): Observable<any> {
      const headers = new HttpHeaders({
         'Authorization': 'Bearer ' + this.storage.read('token'),
         'cidade': this.storage.read('cidade'),
         'Content-Type': 'application/json'
      });
      return this.http.get(`${this.url}/turismo/qr-code/${turismoId}/?largura=${largura}&altura=${altura}`, { headers, responseType: 'arraybuffer' })
   }

   salvarHistoricoQrCode(historico: HistoricoImpressaoQrCode): Observable<any> {
      return this.http.post(this.url + "/historico-impressao", historico, this.util.httpOptions());
   }

   getHistoricoQrCode(id: number, filter): Observable<any> {
      return this.http.post(this.url + `/historico-impressao/turismo/${id}/pageable`, filter, this.util.httpOptions());
   }
}
