import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { DatePipe } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDeConhecimento } from '../_models/base-de-conhecimento';

@Component({
  selector: 'app-base-de-conhecimento-modal-por-artigo',
  templateUrl: './base-de-conhecimento-modal-por-artigo.component.html',
  styleUrls: ['./base-de-conhecimento-modal-por-artigo.component.scss'],
})
export class BaseDeConhecimentoModalPorArtigoComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  artigo: BaseDeConhecimento;
  // controla subscribe da rota
  private sub: any;

  constructor(
    private ref: MatDialogRef<BaseDeConhecimentoModalPorArtigoComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.artigo = data;
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.adjustIframeHeight();
  }

  adjustIframeHeight() {
    const iframe = document.getElementById('myIframe') as HTMLIFrameElement;

    if (iframe && iframe.contentWindow) {
      const contentHeight = iframe.contentWindow.document.body.scrollHeight;
      iframe.style.height = (contentHeight + 50) + 'px';
    }
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  imprimir() {
    let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

    mywindow.document.write(`
    <html>
      <head>
        <title>${this.artigo.titulo}</title>
        <style>@page { size: a4; }.heading,a{font-family:"IBM Plex Sans",sans-serif}.heading{font-weight:600;font-size:22px;line-height:26px;color:#24252e}.c-article-row .article-title a{font-size:16px;font-weight:600;line-height:16px}a{color:#0069d0;padding-bottom:10px}.icon-print{height:22px;width:22px}.solution-print--icon .text-print{font-size:13px;margin-right:10px;color:#3b5998;vertical-align:top}.c-row{border-top:1px solid #f3f3f3}.c-article-row{padding:16px 0}.icon-article-table-row{background:0 0;margin:0 5px 0 0;padding:0;width:24px;height:84px;font-size:28px;color:#9aa1a6;line-height:84px;-moz-box-shadow:-8px 0 10px -10px rgba(0,0,0,.5) inset;-webkit-box-shadow:-8px 0 10px -10px rgba(0,0,0,.5) inset;box-shadow:-8px 0 10px -10px rgba(0,0,0,.5) inset;overflow:hidden}.icon-article-table-row:before{content:"\\1F4D6"}.c-article-row .icon-article-table-row{margin-left:0;margin-right:12px;float:left}.description-text,.help-text,.info-text,.list-noinfo,.p-info{font-size:12px;line-height:1.9;color:#6f7c87}.c-row .description-text{font-size:14px;-webkit-box-orient:vertical;display:-webkit-box;-webkit-line-clamp:2;overflow:hidden;text-overflow:ellipsis;white-space:normal}.c-row .help-text{font-size:12px}</style>
      </head>
      <body>
        <h2 class="heading d-flex justify-content-between">${this.artigo?.titulo}
        </h2>
        <div>${this.datePipe.transform(this.artigo.atualizadoEm)}</div>
        <hr />
        <article class="article-body">${this.artigo.descricao}</article>
      </body>
    </html>
    `);

    mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/

    mywindow.onload = function () {
      // Aguarde até que todas as imagens estejam carregadas
      var images = mywindow.document.images;
      var imagesLoaded = 0;

      function checkImagesLoaded() {
        imagesLoaded++;
        if (imagesLoaded === images.length) {
          // Todas as imagens foram carregadas, agora podemos imprimir e fechar a janela
          mywindow.print();
          mywindow.close();
        }
      }
      if (images && images.length > 0) {
        for (var i = 0; i < images.length; i++) {
          if (images[i].complete) {
            checkImagesLoaded();
          } else {
            images[i].onload = checkImagesLoaded;
          }
        }
      } else {
        mywindow.print();
        mywindow.close();
      }
    }
  }

  close() {
    this.ref.close();
  }
}
