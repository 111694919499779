import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable, Output, EventEmitter, Directive } from "@angular/core";
import { HttpUtilService } from '../../services/http-util-service';
import { tap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class ProconProdutoCategoriaService {

  ;

  private url = environment.API_URL + "/procon/categoria";

  constructor(
    private http: HttpClient,
    private util: HttpUtilService
  ) { }

  getCategorias(): Promise<any> {
    return this.http.get(`${this.url}/?painel=true`, this.util.httpOptions()).toPromise();
  }

  getCategoriasPageable(options: any): Observable<any> {
    return this.http.post(this.url + "/pageable", options, this.util.httpOptions());
  }

  getCategoriasById(id: number): Promise<any> {
    return this.http.get(`${this.url}/${id}`, this.util.httpOptions()).toPromise();
  }

  createCategoria(model): Promise<any> {
    return this.http.post(this.url, model, this.util.httpOptions()).toPromise()
  }

  updateCategoria(model): Promise<any> {
    return this.http.put(this.url, model, this.util.httpOptions()).toPromise()
  }

  deleteCategoria(id: number): Observable<any> {
    return this.http.delete(`${this.url}/${id}`, this.util.httpOptions())
  }
}
