<mat-dialog-content style="width: 35vw;">
   <!-- Título do modal -->
   <p mat-dialog-title style="font-size: larger; font-weight: bold; margin-left: 0; margin-top: 0; padding: 0;">{{
      erro?.titulo }}</p>

   <!-- Ícone de erro -->
   <div class="error-icon d-flex flex-row justify-content-center align-itens-center mt-3 mb-3">
      <i class="fa-solid fa-circle-exclamation" style="color: #ff8080; width: 100px; height: 100px"></i>
   </div>

   <!-- Mensagem de erro -->
   <div class="error-message">
      <p><strong><i class="fa-solid fa-wrench"></i> O seguinte erro ocorreu:</strong> {{ erro?.motivo }}</p>
   </div>

   <!-- Expandir motivo estendido -->
   <a style="text-decoration: underline; color: blue;" (click)="toggleExtendedReason()">{{ showExtendedReason == true? 'Esconder' : 'Exibir' }} detalhes do problema </a>

   <!-- Motivo estendido -->
   <div *ngIf="erro?.motivoExtendido" class="mt-3">
      <mat-card class="card p-3" *ngIf="showExtendedReason">
         <mat-card-content>
            <p>{{ erro?.motivoExtendido }}</p>
         </mat-card-content>
      </mat-card>
   </div>

   <div class="button-row d-flex flex-row justify-content-end p-2">
      <button color="primary" mat-raised-button
         (click)="close()">Fechar</button>
   </div>
</mat-dialog-content>