import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InfraestruturaCategoriaService {

    private url = environment.API_URL + "/infraestrutura-categoria"

    constructor(
        private http: HttpClient,
        private util: HttpUtilService
    ) { }

    getAll(): Observable<any> {
        return this.http.get(this.url, this.util.httpOptions())
    }

    getPageable(model: any): Observable<any> {
        return this.http.post(`${this.url}/pageable`, model, this.util.httpOptions())
    }
    getById(id: number): Observable<any> {
        return this.http.get(this.url + "/" + id, this.util.httpOptions())
    }
    create(model): Observable<any> {
        return this.http.post(this.url, model, this.util.httpOptions())
    }
    update(model): Observable<any> {
        return this.http.put(this.url, model, this.util.httpOptions())
    }
    delete(id: number): Observable<any> {
        return this.http.delete(this.url + "/" + id, this.util.httpOptions())
    }
}


