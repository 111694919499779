<mat-sidenav-container class="sidenav-container">
   <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
      <div class="text-center">
         <div class="card-person-info">
            <!--<div class="m-auto">-->
            <!--<div class="nome">{{usuario.nome || 'Usuário'}}</div>-->
            <div class="parent">
               <div class="text-container">
                  <span class="text">
                     <!--This Text is a bit longer
              and should be wrapped correctly-->
                     {{usuario.nome || 'Usuário'}}
                  </span>
               </div>
            </div>
            <p>{{usuario.email || 'email@email.com'}}</p>
            <!--</div>-->
         </div>
         <div style="height: 60px;">
            <!-- <div class="bg-ligth m-2 rounded-circle"> -->
            <img style="cursor: pointer;" routerLink="/m/minhaconta" class="img rounded-image" [src]="usuario.imagem"
               alt="Imagem do Perfil">
            <!-- </div> -->
         </div>
      </div>
      <mat-nav-list class="mb-5">
         <span *ngFor="let item of navItems">
            <app-menu-list-item *ngIf="item.show" [item]="item" [depth]="0"></app-menu-list-item>
         </span>
      </mat-nav-list>
   </mat-sidenav>

   <mat-sidenav-content id="temScroll">
      <mat-toolbar>
         <!--Se for modificar esse id do botão, modificar também lá no dashboard do controle de processo-->
         <button id="botao-menu" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
            <mat-icon style="color: white;" aria-label="Side nav toggle icon">menu</mat-icon>
         </button>
         <span style="color: white; font-weight: 400 !important;" role="button"
            routerLink="/m/dashboard">{{nomeCidade}}</span>
         <span style="color: white;" class="spacer"></span>

         <button style="color: white;" *ngIf="qtdeNotificacoes == 0" mat-icon-button aria-label="Notificações"
            title="Notificações" (click)="listagemNotificacoes()">
            <mat-icon style="color: white;">notifications</mat-icon>
         </button>
         <button *ngIf="qtdeNotificacoes > 0" mat-icon-button aria-label="Notificações" title="Notificações"
            (click)="listagemNotificacoes()">
            <mat-icon style="color: white;" aria-hidden="false" [matBadge]="qtdeNotificacoes" matBadgeOverlap="false"
               matBadgeColor="customMatBadgeColor">notifications_active</mat-icon>
         </button>
         <button class="pl-2 ml-2" mat-icon-button aria-label="Acessar perfil vereador" title="Acessar perfil vereador"
            (click)="acessarPerfilVereador()" [matMenuTriggerFor]="menu" #clickMenuTrigger="matMenuTrigger"
            *ngIf="podeAcessarPerfilVereador$ | async">
            <mat-icon style="color: white;" *ngIf="!loadingVereadores">manage_accounts</mat-icon>
            <mat-spinner class="d-inline-flex m-0 p-0 text-center" *ngIf="loadingVereadores"
               diameter="20"></mat-spinner>
         </button>
         <mat-menu #menu="matMenu" #vereadorMenu>
            <mat-radio-group class="radio-group" [(ngModel)]="vereadorPerfilSelecionado"
               (change)="trocarContextoParaVereador($event)" (click)="$event.stopPropagation()">
               <mat-radio-button class="d-inline-flex m-0 p-0" [disabled]="loadingTrocaContextoParaVereador"
                  [value]="undefined">
                  <mat-spinner class="d-inline-flex m-0 p-0 text-center" *ngIf="loadingTrocaContextoParaVereador"
                     diameter="15"></mat-spinner>
                  <span>Perfil do usuário logado</span>
               </mat-radio-button>
               <mat-radio-button class="d-inline-flex m-0 p-0" [disabled]="loadingTrocaContextoParaVereador"
                  *ngFor="let vereador of vereadores" [value]="vereador.id">
                  <mat-spinner class="d-inline-flex m-0 p-0 text-center" *ngIf="loadingTrocaContextoParaVereador"
                     diameter="15"></mat-spinner>
                  <span>{{vereador.nome}}</span>
               </mat-radio-button>
            </mat-radio-group>
         </mat-menu>
         <button class="pl-2 ml-2" mat-icon-button aria-label="Sair" title="Sair" (click)="logout()">
            <mat-icon style="color: white;">logout</mat-icon>
         </button>

      </mat-toolbar>
      <div class="mt-2">
         <router-outlet></router-outlet>
      </div>
      <app-base-de-conhecimento-botao-ajuda></app-base-de-conhecimento-botao-ajuda>
   </mat-sidenav-content>