<h2 mat-dialog-title *ngIf="error.status === 500">{{ titulo }}</h2>
<div mat-dialog-content  class="mb-1">
    <p>Mensagem:<br /></p>
    <p style="white-space: pre-line;">
      <markdown>{{ mensagem }}</markdown>
    </p>
</div>
<div mat-dialog-actions class="d-block text-right">
  <button type="button" class="btn btn-primary mx-2" (click)="fechar()">Ok</button>
</div>
