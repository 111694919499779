import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { BaseDeConhecimentoBotaoAjudaService } from '../_services/base-de-conhecimento-botao-ajuda.service';
import { BaseDeConhecimentoService } from '../_services/base-de-conhecimento.service';
import { BaseDeConhecimentoMiddlewareListaComponent } from '../modal-middleware-lista/base-de-conhecimento-middleware-lista.component';

@Component({
  selector: 'app-base-de-conhecimento-botao-ajuda',
  templateUrl: './base-de-conhecimento-botao-ajuda.component.html',
  styleUrls: ['./base-de-conhecimento-botao-ajuda.component.scss'],
})
export class BaseDeConhecimentoBotaoAjudaComponent implements OnInit, OnDestroy {
  filtrando = false;

  // controla subscribe da rota
  private sub: any;

  categoriaList: number[] = [];
  existeArtigos = false;

  constructor(
    private dialogService: MatDialog,
    private baseDeConhecimentoService: BaseDeConhecimentoService,
    private baseDeConhecimentoBotaoAjudaService: BaseDeConhecimentoBotaoAjudaService,
  ) {
    this.sub = this.baseDeConhecimentoBotaoAjudaService.rotinasEvent.subscribe((data) => {
      this.categoriaList = data;
      this.existsByRotinas(data);
    })
  }

  ngOnInit(): void { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  existsByRotinas(rotinas: number[]) {
    this.filtrando = true;
    this.baseDeConhecimentoService.verifyByCategoriaIdList(rotinas).subscribe({
      next: (data) => {
        this.existeArtigos = data;
      }
    })
  }

  verAjuda() {
    this.dialogService.open(BaseDeConhecimentoMiddlewareListaComponent, {
      data: this.categoriaList,
      width: '75vw'
    })
  }
}
