import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UtilidadePublica } from '../model/utilidadePublica.model';
import { HttpUtilService } from '../services/http-util-service';

@Injectable({
  providedIn: 'root'
})
export class CamaraService {
  private readonly urlCamara = environment.API_URL + '/camara/camara';


  constructor(private http: HttpClient, private util: HttpUtilService) { }

  public findCamaraAtiva(): Observable<UtilidadePublica> {
    return this.http.get<UtilidadePublica>(`${this.urlCamara}/ativo`, this.util.httpOptions());
  }
}
