import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpUtilService } from '../services/http-util-service';

@Injectable({
	providedIn: 'any',
})
export class SosService {
	private url = environment.API_URL + '/sos';

	constructor(private http: HttpClient, private util: HttpUtilService) {}

	//   getOcorrencias(page: number = 0, size : number = 10, titulo?: string): Observable<any> {
	//     let url = `${this.url}?page=${page}&size=${size}`;
	//     if (titulo) {
	//       url += `&titulo=${encodeURIComponent(titulo)}`;
	//     }
	//     return this.http.get(url, this.util.httpOptions());
	//   }
	findAllPageable(model: any, sosEscola: boolean): Observable<any> {
		return this.http.post(
			`${this.url}/pageable?sosEscola=${sosEscola}`,
			model,
			this.util.httpOptions(),
		);
	}

	getOcorrencia(id): Observable<any> {
		return this.http.get(`${this.url}/${id}`, this.util.httpOptions());
	}

	getUltimoSosCriado(): Observable<any> {
		return this.http.get(
			environment.API_URL + '/historico-notificacao-painel/ultimo-sos',
			this.util.httpOptions(),
		);
	}

	criarCategoria(model): Observable<any> {
		return this.http.post(`${this.url}/categoria`, model, this.util.httpOptions());
	}
	updateCategoria(id, model): Observable<any> {
		return this.http.put(`${this.url}/categoria/${id}`, model, this.util.httpOptions());
	}
	deleteCategoria(id): Observable<any> {
		return this.http.delete(`${this.url}/categoria/${id}`, this.util.httpOptions());
	}
	getCategorias(): Observable<any> {
		return this.http.get(`${this.url}/categoria`, this.util.httpOptions());
	}
	getCategoriasPageable(model): Observable<any> {
		return this.http.post(`${this.url}/categoria/pageable`, model, this.util.httpOptions());
	}

	criarCidadao(model): Observable<any> {
		return this.http.post(`${this.url}/cidadao`, model, this.util.httpOptions());
	}
	updateCidadao(id, model): Observable<any> {
		return this.http.put(`${this.url}/cidadao/${id}`, model, this.util.httpOptions());
	}
	getCidadaos(): Observable<any> {
		return this.http.get(`${this.url}/cidadao`, this.util.httpOptions());
	}
	getCidadaosPageable(model): Observable<any> {
		return this.http.post(`${this.url}/cidadao/pageable`, model, this.util.httpOptions());
	}
	deleteCidadao(id): Observable<any> {
		return this.http.delete(`${this.url}/cidadao/${id}`, this.util.httpOptions());
	}
}
