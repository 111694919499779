import { environment } from './../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from "@angular/core";
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { tap } from 'rxjs/operators';
@Injectable({
   providedIn: 'root'
})
export class CategoriaService {


   private url = environment.API_URL

   constructor(
      private http: HttpClient,
      private util: HttpUtilService,
   ) { }

   getAllCategorias(): Promise<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria/all`, this.util.httpOptions()).toPromise()
   }

   getAllCategoriasObs(): Observable<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria/all`, this.util.httpOptions())
   }

   getCategoria(id: number): Promise<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria/${id}`, this.util.httpOptions()).toPromise()
   }

   createCategoria(categoria: any): Promise<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/categoria`, categoria, this.util.httpOptions()).toPromise()
   }
   getCategoriasPageable(model: any): Observable<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/categoria/pageable`, model, this.util.httpOptions())
   }

   updateCategoria(categoria: any): Promise<any> {
      return this.http.put(`${this.url}/agendamento-dinamico/categoria`, categoria, this.util.httpOptions()).toPromise()
   }

   deleteCategoria(id: number): Observable<any> {
      return this.http.delete(`${this.url}/agendamento-dinamico/categoria/${id}`, this.util.httpOptions())
   }

   //relacionadas a categoria do serviço
   getAllCategoriasSevico(catId: number): Promise<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria-servico/categoria/${catId}`, this.util.httpOptions()).toPromise()
   }
   getAllCategoriasSevicoObservable(catId: number): Observable<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria-servico/categoria/${catId}`, this.util.httpOptions())
   }
   getCategoriaSevico(catId: number, id: number): Promise<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/categoria-servico/${id}/categoria/${catId}`, this.util.httpOptions()).toPromise()
   }

   createCategoriaSevico(categoria: any, catId: number): Promise<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/categoria-servico/categoria/${catId}`, categoria, this.util.httpOptions()).toPromise()
   }
   getCategoriasSevicoPageable(model: any, catId: number): Observable<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/categoria-servico/categoria/${catId}/pageable`, model, this.util.httpOptions())
   }

   updateCategoriaSevico(categoria: any, catId: number): Promise<any> {
      return this.http.put(`${this.url}/agendamento-dinamico/categoria-servico/categoria/${catId}`, categoria, this.util.httpOptions()).toPromise()
   }

   deleteCategoriaSevico(id: number, catId: number): Observable<any> {
      return this.http.delete(`${this.url}/agendamento-dinamico/categoria-servico/${id}/categoria/${catId}`, this.util.httpOptions())
   }

   //relacionadas a sub-categoria do serviço
   getAllSubCategoriasSevico(): Promise<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/sub-categoria/categoria-servico`, this.util.httpOptions()).toPromise()
   }

   getAllSubCategoriasBySevicoId(servicoId: any): Observable<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/sub-categoria/por-servico/${servicoId}`, this.util.httpOptions())
   }

   getAllSubCategoriasSevicoPorCategoriaServico(catId: number): Observable<any> {
      return this.http.get(`${this.url}/agendamento-dinamico/sub-categoria/categoria-servico/${catId}`, this.util.httpOptions())
   }

   createSubCategoriaSevico(categoria: any, catId: number): Observable<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/sub-categoria/categoria-servico/${catId}`, categoria, this.util.httpOptions())
   }
   getSubCategoriasSevicoPageable(model: any, catId: number): Observable<any> {
      return this.http.post(`${this.url}/agendamento-dinamico/sub-categoria/categoria/${catId}/pageable`, model, this.util.httpOptions())
   }

   updateSubCategoriaSevico(categoria: any, catId: number): Observable<any> {
      return this.http.put(`${this.url}/agendamento-dinamico/sub-categoria/categoria-servico/${catId}`, categoria, this.util.httpOptions())
   }

   deleteSubCategoriaSevico(id: number, catId: number): Observable<any> {
      return this.http.delete(`${this.url}/agendamento-dinamico/sub-categoria/${id}/categoria-servico/${catId}`, this.util.httpOptions())
   }
}
