import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-alertas',
  templateUrl: './modal-alertas.component.html',
  styleUrls: ['./modal-alertas.component.scss']
})
export class ModalAlertasComponent implements OnInit {

  @HostListener('window:keydown.enter', ['$event'])
  handleKeyDown() {
    this.close();
  }

  public alerta : any;
  public disableClose: boolean = false;
  public maxWidth: string = '720px';

  constructor(
    private modalAlertasDialogRef: MatDialogRef<ModalAlertasComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
   this.alerta = data
   this.disableClose = data?.disableClose;
   this.maxWidth = data?.maxWidth ? data.maxWidth : this.maxWidth;
  }

  ngOnInit() {
    this.modalAlertasDialogRef.disableClose = this.disableClose ? this.disableClose : false;
  }

  close(){
    this.modalAlertasDialogRef.close()
  }

}
