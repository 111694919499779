import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpUtilService } from '../services/http-util-service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class TransporteService {
	private url = environment.API_URL;
	@Output() public atualizaNavbarTransporte = new EventEmitter();

	constructor(private http: HttpClient, private util: HttpUtilService) {}

	getCategorias(): Promise<any> {
		return this.http
			.get(this.url + '/transportepublico/categoria', this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}
	getCategoriasPermitidas(): Promise<any> {
		return this.http
			.get(this.url + '/transportepublico/categoria/permitidas', this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}
	getCategoriasById(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/categoria/${id}`, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	//busca todos os transportes publico
	public getAll(): Promise<any> {
		return this.http.get(this.url + '/transportepublico', this.util.httpOptions()).toPromise();
	}

	//busca todos os transportes publico
	public getById(id: number): Promise<any> {
		return this.http
			.get(this.url + '/transportepublico/' + id, this.util.httpOptions())
			.toPromise();
	}

	//cria um transporte publico
	public create(model): Promise<any> {
		return this.http
			.post(this.url + '/transportepublico', model, this.util.httpOptions())
			.toPromise();
	}

	//cria um transporte publico
	public update(model): Promise<any> {
		return this.http
			.put(this.url + '/transportepublico', model, this.util.httpOptions())
			.toPromise();
	}

	//deleta um transporte publico
	deleteCategoria(id: number): Promise<any> {
		return this.http
			.delete(this.url + '/transportepublico/categoria/' + id, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarTransporte.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**                   PERIDO                    **/

	//busca todos os periodos dado o id de uma categoria
	public getTPPEriodo(id: number, model): Observable<any> {
		return this.http.post(
			this.url + `/transportepublico/categoria/${id}/periodo/pageable`,
			model,
			this.util.httpOptions(),
		);
	}

	public getTPPEriodoByCategoriaList(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/categoria/${id}/periodo/all`, this.util.httpOptions())
			.toPromise();
	}

	//cria um periodo
	public createPeriodo(model: any): Promise<any> {
		return this.http
			.post(this.url + '/transportepublico/periodo', model, this.util.httpOptions())
			.toPromise();
	}

	//atualiza um periodo
	public updatePeriodo(model: any): Promise<any> {
		return this.http
			.put(this.url + '/transportepublico/periodo', model, this.util.httpOptions())
			.toPromise();
	}

	//deleta um periodo
	public deletePeriodo(id: number): Promise<any> {
		return this.http
			.delete(this.url + '/transportepublico/periodo/' + id, this.util.httpOptions())
			.toPromise();
	}

	/**                   VIA                    **/

	//busca todas as vias
	public getTPVia(): Promise<any> {
		return this.http
			.get(this.url + '/transportepublico/via', this.util.httpOptions())
			.toPromise();
	}

	//busca vias por bairro
	public getTPViaByBairros(bairros: any): Promise<any> {
		return this.http
			.post(this.url + '/transportepublico/via/bairros', bairros, this.util.httpOptions())
			.toPromise();
	}

	//cria uma via
	public createVia(model): Promise<any> {
		return this.http
			.post(this.url + '/transportepublico/via', model, this.util.httpOptions())
			.toPromise();
	}

	//atualiza uma via
	public updateVia(model): Promise<any> {
		return this.http
			.put(this.url + '/transportepublico/via', model, this.util.httpOptions())
			.toPromise();
	}

	//deleta uma via
	public deleteVia(id: number): Promise<any> {
		return this.http
			.delete(this.url + '/transportepublico/via/' + id, this.util.httpOptions())
			.toPromise();
	}

	//busca pontos parada pelo id do transporte publico via
	public getTpPontosParadaByTransportePublicoViaId(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/via/${id}/pontosparada`, this.util.httpOptions())
			.toPromise();
	}

	//busca pontos de referencia pelo id do transporte publico via
	public getTpPontosReferenciaByTransportePublicoViaId(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/via/${id}/pontosreferencia`, this.util.httpOptions())
			.toPromise();
	}

	//busca utilidade publica pelo id do transporte
	public getUtilidadePublicaByTransporteId(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/${id}/utilidadepublica`, this.util.httpOptions())
			.toPromise();
	}

	//busca linha pelo id
	public getTPLinhaByTransporteId(id): Promise<any> {
		return this.http
			.get(this.url + '/transportepublico/' + id + '/linha', this.util.httpOptions())
			.toPromise();
	}

	//busca horarios pelo id do transporte publico linha
	public getTpPeriodoByTransportePublicoLinhaId(id: number): Promise<any> {
		return this.http
			.get(this.url + `/transportepublico/${id}/horario`, this.util.httpOptions())
			.toPromise();
	}
}
