import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';

import { LoginComponent } from './login.component';

import { AuthService } from '../services/auth.service';
import { PermissaoService } from '../services/permissao.service';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { ModalEsqueceuSenhaComponent } from './modal-esqueceu-senha/modal-esqueceu-senha.component';

import { ModalModule } from '../util/modal-module/modal.module';
import { LoginRoutingModule } from './login.routing.module';
import { ModalAceitarTermosComponent } from './modal-aceitar-termos/modal-aceitar-termos.component';
import { SafePipeModule } from '../util/pipes/safe-pipe/safe.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LoginRoutingModule,
        MatDialogModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatIconModule,
        ModalModule,
        SafePipeModule,
        NgxMaskDirective, NgxMaskPipe,
    ],
    declarations: [LoginComponent, ModalEsqueceuSenhaComponent, ModalAceitarTermosComponent],
    providers: [
        AuthService,
        PermissaoService,
        provideNgxMask()
    ]
})
export class LoginModule {}
