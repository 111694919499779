<div class="modal-title" [ngClass]="{ urgent: urgencia }">
	<div mat-dialog-title class="modal-title-inner">
		<span>{{ titulo }}</span>
		<div class="modal-title-actions">
			<button
				*ngIf="urgencia && noClick"
				tabindex="-1"
				class="btn-act"
				type="button"
				data-dismiss="modal"
				aria-label="mute"
				(click)="muteSoundEmergency()"
			>
				<i class="fas fa-volume-up"></i>
			</button>
			<button
				*ngIf="urgencia && !noClick"
				tabindex="-1"
				class="btn-act"
				type="button"
				data-dismiss="modal"
				aria-label="mute"
				(click)="iniciarNotificacaoEmergencia()"
			>
				<i class="fa fa-volume-off" aria-hidden="true"></i>
			</button>

			&nbsp;
			<button tabindex="-1" type="button" class="btn-act" data-dismiss="modal" aria-label="Fechar" (click)="fechar()">
				<i class="mt-1 fa-solid fa-x"></i>
			</button>
		</div>
	</div>
</div>
<mat-dialog-content style="min-width: 800px; min-height: 600px">
	<div *ngIf="ocorrencia?.qtdChamados">
		<div class="form-row border rounded mb-2">
			<div class="form-group col-12 col-md-12 text-center" style="margin: 0.5rem">
				<label class="text-dark"> Total de ocorrências registradas para o cidadão ao longo do tempo: </label>
				<span class="text-danger"> {{ ocorrencia.qtdChamados }} </span>
				<button tabindex="-1" (click)="mostrarRelatorio()" type="button" class="btn btn-secondary btn-sm ml-2">
					Visualizar relatório
				</button>
			</div>
		</div>
	</div>
	<div *ngIf="ocorrencia?.solicitante">
		<div class="form-row">
			<div class="form-group col-md-6">
				<label>Nome:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.cidadao?.nome || 'Não informado' }}</span>
			</div>
			<div class="form-group col-md-6">
				<label>CPF:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.cidadao?.documento || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Escola:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.empresa?.razaoSocial || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Cargo:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.profissao?.nome || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Endereço:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.empresa?.enderecoFormatado || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row" *ngIf="ocorrencia?.latitude != null && ocorrencia?.longitude != null">
			<div class="form-group col-md-12">
				<label>Local do SOS:</label>
				<button mat-raised-button class="col-md-12" color="primary" (click)="openLink()">
					Acessar <i class="fa-solid fa-map-location-dot"></i>
				</button>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-4">
				<label>Telefone do servidor:</label>
				<span class="form-control bg-light">{{ ocorrencia?.solicitante?.cidadao?.telefone || 'Não informado' }}</span>
			</div>
			<div class="form-group col-md-4">
				<label>Telefone emergencial:</label>
				<span class="form-control bg-light">{{ this.emergencialUm || 'Não informado' }}</span>
			</div>
			<div class="form-group col-md-4">
				<label>Telefone emergencial 2:</label>
				<span class="form-control bg-light">{{ this.emergencialDois || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Data e hora do chamado:</label>
				<span class="form-control bg-light">{{ this.getHoraFormatada(ocorrencia?.horaExataChamado) | date : 'dd/MM/yyyy HH:mm' }}</span>
			</div>
		</div>
	</div>

	<div *ngIf="ocorrencia?.cidadaoSolicitante">
		<div class="form-row">
			<div class="form-group col-md-6">
				<label>Nome:</label>
				<span class="form-control bg-light">{{ ocorrencia?.cidadaoSolicitante?.cidadao?.nome || 'Não informado' }}</span>
			</div>
			<div class="form-group col-md-6">
				<label>CPF:</label>
				<span class="form-control bg-light">{{ ocorrencia?.cidadaoSolicitante?.cidadao?.documento || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Categoria:</label>
				<span class="form-control bg-light">{{ ocorrencia?.cidadaoSolicitante?.categoria?.nome || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Local do SOS:</label>
				<button mat-raised-button class="col-md-12" color="primary" (click)="openLink()">
					Acessar <i class="fa-solid fa-map-location-dot"></i>
				</button>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Telefone do cidadão:</label>
				<span class="form-control bg-light">{{ ocorrencia?.cidadaoSolicitante?.cidadao?.telefone || 'Não informado' }}</span>
			</div>
		</div>
		<div class="form-row">
			<div class="form-group col-md-12">
				<label>Data e hora do chamado:</label>
				<span class="form-control bg-light">{{ this.getHoraFormatada(ocorrencia?.horaExataChamado) | date : 'dd/MM/yyyy HH:mm' }}</span>
			</div>
		</div>
	</div>

	<div class="form-row" *ngIf="!urgencia">
		<div class="form-group col-md-12 text-danger">
			<label for="observacao">Deixe uma observação para o usuário da ocorrência: </label>
			<textarea
				[(ngModel)]="observacao"
				class="form-control text-danger"
				id="observacao"
				rows="3"
				placeholder="Dica: Essa observação irá aparecer no histórico do usuário."
			></textarea>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions class="justify-content-end px-3">
	<button
		*ngIf="ocorrencia?.cidadaoSolicitante && !urgencia"
		type="button"
		mat-raised-button
		color="primary"
		style="min-width: 45px; margin-right: 10px"
		(click)="salvarObservacao()"
	>
		<i class="fa-solid fa-check"></i> Salvar observação
	</button>
	<button type="button" mat-button class="mr-1 mat-elevation-z2" style="min-width: 45px" (click)="fechar()">
		<i class="fa-solid fa-xmark"></i> Fechar
	</button>
</mat-dialog-actions>
