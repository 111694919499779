import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PermissoesCartaoDeVacinaService {

   url = environment.API_URL + "/cartao-de-vacina/permissoes"

  constructor(
   private http: HttpClient,
   private util: HttpUtilService,
  ) { }

  create(body: any): Observable<any> {
   return this.http.post(this.url, body, this.util.httpOptions())
  }

  getAllPermissoes(pageable: any): Observable<any> {
   return this.http.post(this.url + `/pageable`, pageable, this.util.httpOptions())
  }

  getPermissoesByCpf(cpf: any): Observable<any> {
   return this.http.get(this.url + `/${cpf}`, this.util.httpOptions())
  }

  getPermissoesById(id: any): Observable<any> {
   return this.http.get(this.url + `/${id}`, this.util.httpOptions())
  }

  findByUserId(userId: number): Observable<any> {
   return this.http.get(this.url + `/find-by-userid?userId=${userId}`, this.util.httpOptions())
  }

  editarPermissoes(id: any, body: any): Observable<any> {
   return this.http.put(this.url + `/${id}`, body, this.util.httpOptions())
  }

  deletePermissaoById(id: any): Observable<any> {
   return this.http.delete(this.url + `/${id}`, this.util.httpOptions())
  }
}
