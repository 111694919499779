import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-certeza',
  templateUrl: './modal-certeza.component.html',
  styleUrls: ['./modal-certeza.component.scss']
})
export class ModalCertezaComponent implements OnInit {

  tituloModal = null;
  textoModal: any;
  opcaoUm: any;   //esquerda
  opcaoDois: any; //direita - possui ação

  constructor(
    public dialogRef: MatDialogRef<ModalCertezaComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    if(data) {
      this.tituloModal = data.tituloModal;
      this.textoModal = data.textoModal;
      this.opcaoUm = data.opcaoUm;
      this.opcaoDois = data.opcaoDois
    }
  }

  ngOnInit(): void {
  }

  acaoUm(){
    this.dialogRef.close()
  }

  acaoDois(){
    this.dialogRef.close(true);
  }

}
