import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';

@Injectable({
   providedIn: 'root'
})
export class SocketService {

   private socket: any;

   @Output() public eventoRecebido = new EventEmitter()

   constructor() {

      let url = environment.API_NODE_URL;
      let path = "/relatorio-consulta/sockets/socket.io";
      if (environment.name == 'prod') {
         url = environment.API_URL
      }

      this.socket = io(url, {
         path: path
      });
   }

   onListen(eventName: string): Observable<any> {
      return new Observable((subscribe) => {
         this.socket.on(eventName, () => {
            this.eventoRecebido.emit(true)
            subscribe.next();
         });
      })
   }

   onListenNoHistory(eventName: string): Observable<any> {
      return new Observable((subscribe) => {
         this.socket.on(eventName, () => {
            subscribe.next();
         });
      })
   }

   disconnect() {
      if (this.socket) {
         this.socket.disconnect();
      }
   }
}
