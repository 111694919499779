import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { environment } from 'src/environments/environment';
import { BaseDeConhecimento } from '../_models/base-de-conhecimento';


@Injectable({
    providedIn: 'any'
})
export class BaseDeConhecimentoService {

    private url = environment.API_URL + "/master/base-de-conhecimento"

    constructor(
        private http: HttpClient,
        private util: HttpUtilService
    ) { }

    getAll(): Observable<BaseDeConhecimento[]> {
        return this.http.get<BaseDeConhecimento[]>(this.url, this.util.httpOptions())
    }

    getAllByCategoriaId(categoriaId: number): Observable<BaseDeConhecimento[]> {
        return this.http.get<BaseDeConhecimento[]>(`${this.url}/categoria/${categoriaId}`, this.util.httpOptions())
    }

    verifyByCategoriaIdList(categoriaId: number[]): Observable<boolean> {
        return this.http.get<boolean>(`${this.url}/verificar-se-existe-por-rotina?categoriaIdList=${categoriaId}`, this.util.httpOptions())
    }

    listByCategoriaIdList(categoriaId: number[]): Observable<BaseDeConhecimento[]> {
        return this.http.get<BaseDeConhecimento[]>(`${this.url}/listar-por-rotina?categoriaIdList=${categoriaId}`, this.util.httpOptions())
    }

    getById(id: number, categoriaId: number): Observable<any> {
        return this.http.get(`${this.url}/${id}/${categoriaId}`, this.util.httpOptions())
    }
    create(model): Observable<any> {
        return this.http.post(this.url, model, this.util.httpOptions())
    }
    update(model): Observable<any> {
        return this.http.put(this.url, model, this.util.httpOptions())
    }
    delete(id: number): Observable<any> {
        return this.http.delete(this.url+"/"+id, this.util.httpOptions())
    }
}