import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { StorageService } from './storage.service';


@Injectable({
   providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

   rotasSemCidade = [
      'maps.googleapis.com',
      '/master/adm/cidades',
      '/anexo-assinatura',
      '/anexo-assinatura-cidadao/anexo',
      '/historico-anexo/autenticidade-documentos/url',
      '/dashboard/chamados',
      '/dashboard/chamados/all'
   ]

   constructor(private authenticationService: AuthService,
      private storage: StorageService,) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      let newRequest;
      if (!this.rotasSemCidade.some(rota => request.url.includes(rota))) {
         newRequest = request.clone({
            setHeaders: {
               'cidade': this.storage.read("cidade"),
            }
         });
      } else {
         newRequest = request;
      }
      return next.handle(newRequest).pipe(catchError(err => {
         if (err.status === 401 || err.status === 403) {
            // err = { status: 401 };
            // auto logout if 401 response returned from api
            if (!request.url.includes("maps.googleapis.com")) {
               this.authenticationService.logout();
            }
            // location.reload(true);
         }
         return throwError({ ...err, ...err.error });

      }))
   }
}
