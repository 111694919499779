import { Component, OnInit, } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ativa-modal',
  templateUrl: './modal-ativa.component.html'
})
export class ModalAtiva implements OnInit {

  constructor(private modalAtiva: MatDialogRef<ModalAtiva>) { }

  ngOnInit() {
  }

  close(){
    this.modalAtiva.close()
  }

  ativa(){
    this.modalAtiva.close(1)
  }

  desativa(){
    this.modalAtiva.close(2)
  }
}
