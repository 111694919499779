import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './navigation/navigation.component';

const appRoutes: Routes = [
	{
		path: 'login',
		loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
	},
   {
      data: { origem: 'PREFEITURA' },
      path: 'dashboard-transparencia',
      loadChildren: () =>
         import('./dashboard-transparencia/dashboard-transparencia.module').then(m => m.DashboardTransparenciaModule),
   },
	{
		path: 'autenticidade-documentos',
		loadChildren: () =>
			import('./autenticidade-documentos/autenticidade-documentos.module').then((m) => m.AutenticidadeDocumentosModule),
	},
	{
		path: '',
		loadChildren: () => import('./splash-screen/splash-screen.module').then((m) => m.SplashScreenModule),
	},
	{
		path: 'm',
		component: NavigationComponent,
		children: [
			{
				data: { title: 'Bairros', roles: ['Bairros'] },
				path: 'bairros',
				loadChildren: () => import('./bairros/bairros.module').then((m) => m.BairrosModule),
			},
			{
				data: { title: 'Painel de Controle', origem: 'PREFEITURA', roles: ['Dashboard'] },
				path: 'dashboard',
				loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				data: { title: 'Painel de Controle', origem: 'CAMARA', roles: ['Dashboard'] },
				path: 'camara/dashboard',
				loadChildren: () =>
				import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
			},
			{
				data: { title: 'Telas do Aplicativo' },
				path: 'telas-aplicativo',
				loadChildren: () =>
					import('./acessos/telas-aplicativo/telas-aplicativo.module').then((m) => m.TelasAplicativoModule),
			},
			{
				data: { title: 'Rotinas', origem: 'PREFEITURA' },
				path: 'rotinas',
				loadChildren: () => import('./acessos/rotinas/rotinas.module').then((m) => m.RotinasModule),
			},
			{
				data: { title: 'Rotinas', origem: 'CAMARA' },
				path: 'camara/rotinas',
				loadChildren: () => import('./acessos/rotinas/rotinas.module').then((m) => m.RotinasModule),
			},
			{
				data: { title: 'Permissões', origem: 'PREFEITURA', roles: ['Usuários'], isChild: false },
				path: 'usuarios',
				loadChildren: () => import('./acessos/usuarios/usuarios.module').then((m) => m.UsuariosModule),
			},
			{
				data: { title: 'Permissões', origem: 'CAMARA', roles: ['Usuários'], isChild: false },
				path: 'camara/usuarios',
				loadChildren: () => import('./acessos/usuarios/usuarios.module').then((m) => m.UsuariosModule),
			},
         {
            data: { title: 'Permissões', origem: 'SOS', roles: ['SOS Permissões'], isChild: true },
            path: 'chamado-sos/usuarios',
            loadChildren: () => import('./acessos/usuarios/usuarios.module').then(m => m.UsuariosModule),
         },
			{
				data: {
					title: 'Agendamento de Serviços',
					origem: 'PREFEITURA',
					roles: ['Agendamento Serviços'],
				},
				path: 'servicos',
				loadChildren: () =>
					import('./agendamentos/agendamento-servico/agendamento-servico.module').then(
						(m) => m.AgendamentoServicoModule,
					),
			},
			{
				data: {
					title: 'Agendamento de Serviços',
					origem: 'CAMARA',
					roles: ['Agendamento Serviços'],
				},
				path: 'camara/servicos',
				loadChildren: () =>
					import('./agendamentos/agendamento-servico/agendamento-servico.module').then(
						(m) => m.AgendamentoServicoModule,
					),
			},
			{
				data: { title: 'Agendamento de Serviço Dinâmico', roles: ['Solicitação de Agendamento'] },
				path: 'servicos-dinamicos',
				loadChildren: () =>
					import('./agendamentos/agendamento-servico-dinamico/agendamento-servico-dinamico.module').then(
						(m) => m.AgendamentoServicoDinamicoModule,
					),
			},
			{
				data: { title: 'Usuários', roles: ['Usuários'] },
				path: 'lista-usuarios',
				loadChildren: () => import('./acessos/lista-usuarios/lista-usuarios.module').then((m) => m.ListUsuarioModule),
			},
			{
				data: { title: 'Configuração Castração', roles: ['Castração'] },
				path: 'castracao/configuracao',
				loadChildren: () =>
					import('./agendamentos/castracao/configuracao-castracao/configuracao-castracao.module').then(
						(m) => m.ConfiguracaoCastracaoModule,
					),
			},
			{
				data: { title: 'Castração', roles: ['Castração'] },
				path: 'castracao',
				loadChildren: () => import('./agendamentos/castracao/castracao.module').then((m) => m.CastracaoModule),
			},
			{
				data: { title: 'Tipo de Restrição', roles: ['Tipo de Restrição'] },
				path: 'tipo-restricao',
				loadChildren: () =>
					import('./agendamentos/tipo-restricao/tipo-restricao.module').then((m) => m.TipoRestricaoModule),
			},
			{
				data: { title: 'ONGs', roles: ['ONGs'] },
				path: 'pessoa-juridica',
				loadChildren: () => import('./pessoa-juridica/pessoa-juridica.module').then((m) => m.PessoaJuridicaModule),
			},
			{
				data: { title: 'Instalação Esportiva', roles: ['Instalação Esportiva'] },
				path: 'instalacao-esportiva',
				loadChildren: () =>
					import('./agendamentos/instalacao-esportiva/instalacao-esportiva.module').then(
						(m) => m.InstalacaoEsportivaModule,
					),
			},
			{
				data: { title: 'Comunicados', roles: ['Comunicados'] },
				path: 'mensagens',
				loadChildren: () => import('./mensagens/mensagens.module').then((m) => m.MensagensModule),
			},
			{
				data: { title: 'Comunicados', roles: ['Comunicados'] },
				path: 'mensagens-externas',
				loadChildren: () =>
					import('./mensagens-externas/mensagens-externas.module').then((m) => m.MensagensExternasModule),
			},
			{
				data: { title: 'Documentos', roles: ['Lista de Documentos'] },
				path: 'documentos',
				loadChildren: () => import('./documento/documento.module').then((m) => m.DocumentoModule),
			},
         {
				data: { title: 'Entrevistas', origem: 'PREFEITURA', roles: ['Dashboard Entrevistas', 'Permissões Entrevistas', 'Relatório Entrevistas', 'Empresas Entrevistas', 'Listagem Entrevistas'] },
				path: 'entrevistas',
				loadChildren: () => import('./entrevistas/entrevistas.module').then((m) => m.EntrevistasModule),
			},
			{
				data: { title: 'Eventos', origem: 'PREFEITURA', roles: ['Eventos'] },
				path: 'eventos',
				loadChildren: () => import('./eventos/eventos.module').then((m) => m.EventosModule),
			},
			{
				//Solicitar Role e inserir
				data: { title: 'Eventos', origem: 'CAMARA', roles: ['Eventos'] },
				path: 'camara/eventos',
				loadChildren: () => import('./eventos/eventos.module').then((m) => m.EventosModule),
			},
			{
				data: { title: 'Empresas', roles: ['Empresa'] },
				path: 'empresa',
				loadChildren: () => import('./empresa/empresa.module').then((m) => m.EmpresaModule),
			},
			{
				data: { title: 'Formulários', origem: 'PREFEITURA', roles: ['Formulários'] },
				path: 'formularios',
				loadChildren: () => import('./formularios/formularios.module').then((m) => m.FormulariosModule),
			},
			{
				data: { title: 'Formulários', origem: 'CAMARA', roles: ['Formulários'] },
				path: 'camara/formularios',
				loadChildren: () => import('./formularios/formularios.module').then((m) => m.FormulariosModule),
			},
			{
				data: { title: 'Informações', roles: ['Informações'] },
				path: 'informacoes/categorias',
				loadChildren: () => import('./informacoes/informacoes.module').then((m) => m.InformacoesModule),
			},
			{
				data: { title: 'Central da Infraestrutura', roles: ['Central de Iluminação Pública'] },
				path: 'infraestrutura',
				loadChildren: () => import('./infraestrutura/infraestrutura.module').then((m) => m.InfraestruturaModule),
			},
			{
				data: { title: 'Meio Ambiente', roles: ['Meio Ambiente'] },
				path: 'meio-ambiente',
				loadChildren: () => import('./meio-ambiente/meio-ambiente.module').then((m) => m.MeioAmbienteModule),
			},
			{
				data: { title: 'Modalidade Esportiva', roles: ['Modalidade Esportiva'] },
				path: 'modalidade/esportiva',
				loadChildren: () =>
					import('./modalidade_esporte/modalidade-esporte.module').then((m) => m.ModalidadeEsporteModule),
			},
			{
				data: { title: 'Notícias', roles: ['Notícias'] },
				path: 'noticias',
				loadChildren: () => import('./noticias/noticias.module').then((m) => m.NoticiasModule),
			},
			{
				data: { title: 'Ocorrências', roles: ['Ocorrências'] },
				path: 'ocorrencias',
				loadChildren: () => import('./ocorrencias/ocorrencias.module').then((m) => m.OcorrenciasModule),
			},
			{
				data: { title: 'Pesquisas', roles: ['Pesquisas'] },
				path: 'pesquisas',
				loadChildren: () => import('./pesquisas/pesquisas.module').then((m) => m.PesquisasModule),
			},
			{
				data: { title: 'Procon', roles: ['Procon'] },
				path: 'procon',
				loadChildren: () => import('./procon/procon.module').then((m) => m.ProconModule),
			},
			{
				data: { title: 'Relatórios', origem: 'PREFEITURA', roles: ['Relatórios'] },
				path: 'relatorios',
				loadChildren: () => import('./relatorios/relatorios.module').then((m) => m.RelatoriosModule),
			},
			{
				data: { title: 'Sorteios', roles: ['Sorteios'] },
				path: 'sorteios',
				loadChildren: () => import('./sorteios/sorteios.module').then((m) => m.SorteiosModule),
			},
			{
				data: { title: 'SorteiosNovo', roles: ['Sorteios'] },
				path: 'sorteiosv2',
				loadChildren: () => import('./sorteio-novo/sorteio-novo.module').then((m) => m.SorteioNovoModule),
			},
			{
            data: { title: 'Chamados SOS', roles: ["SOS"] },
				path: 'chamado-sos',
				loadChildren: () => import('./sos/sos.module').then((m) => m.SosModule),
			},
			{
				data: { title: 'Protetores Individuais', roles: ['Castração'] },
				path: 'castracao/protetores-individuais',
				loadChildren: () =>
					import('./agendamentos/castracao/protetor-individual/protetor-individual.module').then(
						(m) => m.ProtetorIndividualModule,
					),
			},
			{
				data: { title: 'Protetores Individuais', roles: ['Castração'] },
				path: 'castracao/protetores-individuais',
				loadChildren: () =>
					import('./agendamentos/castracao/protetor-individual/protetor-individual.module').then(
						(m) => m.ProtetorIndividualModule,
					),
			},
         {
				data: { title: 'Cartão de Vacinas' },
				path: 'castracao/cartao-de-vacina',
				loadChildren: () =>
					import('./agendamentos/castracao/cartao-de-vacina/cartao-de-vacina.module').then(
						(m) => m.CartaoDeVacinaModule,
					),
			},
			{
				data: { title: 'Profissões', roles: ['Profissoes'] },
				path: 'profissoes',
				loadChildren: () => import('./empresa/profissoes/profissoes.module').then((m) => m.ProfissoesModule),
			},
			{
				data: { title: 'Profissões', roles: ['Profissoes'] },
				path: 'profissoes',
				loadChildren: () => import('./empresa/profissoes/profissoes.module').then((m) => m.ProfissoesModule),
			},
			{
				data: { title: 'Protetores Individuais', roles: ['Castração'] },
				path: 'castracao/protetores-individuais',
				loadChildren: () =>
					import('./agendamentos/castracao/protetor-individual/protetor-individual.module').then(
						(m) => m.ProtetorIndividualModule,
					),
			},
			{
				data: { title: 'Limpeza Urbana', roles: ['Limpeza Urbana'] },
				path: 'limpeza-urbana',
				loadChildren: () => import('./limpeza urbana/limpeza-urbana.module').then((m) => m.LimpezaUrbanaModule),
			},
         {
				data: { title: 'Livraria', origem: 'PREFEITURA', roles: ['Livraria Configuração','Livraria Listagem', 'Livraria Locação'] },
				path: 'livraria',
				loadChildren: () => import('./livraria/livraria.module').then((m) => m.LivrariaModule),
			},
			{
				data: { title: 'Transporte', roles: ['Transporte'] },
				path: 'transporte',
				loadChildren: () => import('./transporte/transporte.module').then((m) => m.TransporteModule),
			},
			{
				data: { title: 'Turismo', roles: ['Turismo'] },
				path: 'turismo',
				loadChildren: () => import('./turismo/turismo.module').then((m) => m.TurismoModule),
			},
			{
				data: {
					title: 'Utilidade Pública',
					origem: 'PREFEITURA',
					roles: ['Utilidade Pública'],
				},
				path: 'utilidadepublica',
				loadChildren: () =>
					import('./utilidade-publica/utilidade-publica.module').then((m) => m.UtilidadePublicaModule),
			},
			{
				data: { title: 'Utilidade Pública', origem: 'CAMARA', roles: ['Utilidade Pública'] },
				path: 'camara/utilidadepublica',
				loadChildren: () =>
					import('./utilidade-publica/utilidade-publica.module').then((m) => m.UtilidadePublicaModule),
			},
			{
				data: { title: 'Webview', roles: ['Webview'] },
				path: 'webview/categorias',
				loadChildren: () => import('./webview/webview.module').then((m) => m.WebviewModule),
			},
			{
				data: { title: 'Relatório', roles: ['Diário de bordo'] },
				path: 'diario-de-bordo',
				loadChildren: () => import('./diario-de-bordo/diario-de-bordo.module').then((m) => m.DiarioDeBordoModule),
			},
			{
				data: { title: 'Carros', roles: ['Diário de bordo'] },
				path: 'carro',
				loadChildren: () =>
					import('./diario-de-bordo/carro-diario-de-bordo/carro-diario-de-bordo.module').then(
						(m) => m.CarroDiarioDeBordoModule,
					),
			},
			{
				data: { title: 'Tipos de Carros', roles: ['Diário de bordo'] },
				path: 'carro/tipos',
				loadChildren: () =>
					import('./diario-de-bordo/carros-tipos/carros-tipos.module').then((m) => m.CarrosTiposModule),
			},
			{
				data: { title: 'Motoristas', roles: ['Diário de bordo'] },
				path: 'motoristas',
				loadChildren: () => import('./diario-de-bordo/motoristas/motoristas.module').then((m) => m.MotoristasModule),
			},
			{
				data: { title: 'Setor', roles: ['Diário de bordo'] },
				path: 'diario-setor',
				loadChildren: () =>
					import('./diario-de-bordo/diario-setor/diario-setor.module').then((m) => m.DiarioSetorModule),
			},
			{
				data: { title: 'Rastreamento RANOR', roles: ['Rastreamento RANOR'] },
				path: 'diario-rastreamento-ranor',
				loadChildren: () =>
					import('./diario-de-bordo/rastreamento-ranor/rastreamento-ranor.module').then(
						(m) => m.RastreamentoRanorModule,
					),
			},
			{
				data: { title: 'Carros rastreaveis', roles: ['Rastreamento RANOR'] },
				path: 'diario-carros-rastreaveis',
				loadChildren: () =>
					import('./diario-de-bordo/carros-rastreaveis/carros-rastreaveis.module').then(
						(m) => m.CarrosRastreaveisModule,
					),
			},
			{
				data: { title: 'Saúde', roles: ['Saúde'] },
				path: 'saude',
				loadChildren: () => import('./saude/saude.module').then((m) => m.SaudeModule),
			},
         {
            data: { title: 'Base de Conhecimento', roles: ["Base de Conhecimento"] },
            path: 'base-de-conhecimento',
            loadChildren: () =>
               import('./base-de-conhecimento/base-de-conhecimento.module').then(
                  m => m.BaseDeConhecimentoModule,
               ),
         },

			{
				data: { title: 'Campanhas', roles: ['Campanhas'] },
				path: 'campanhas',
				loadChildren: () => import('./campanhas/campanhas.module').then((m) => m.CampanhasModule),
			},
			{
				data: { title: 'Minha Conta', roles: ['Minha Conta'] },
				path: 'minhaconta',
				loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
			},
			{
				data: {
					title: 'Processo Seletivo',
					origem: 'PREFEITURA',
					roles: ['Processo Seletivo'],
				},
				path: 'processo-seletivo',
				loadChildren: () =>
					import('./processo-seletivo/processo-seletivo.module').then((m) => m.ProcessoSeletivoModule),
			},
			{
				data: { title: 'Processo Seletivo', origem: 'CAMARA', roles: ['Processo Seletivo'] },
				path: 'camara/processo-seletivo',
				loadChildren: () =>
					import('./processo-seletivo/processo-seletivo.module').then((m) => m.ProcessoSeletivoModule),
			},
			{
				data: { title: 'Controle de Processos', origem: 'CAMARA', roles: ['Controle de Processo'] },
				path: 'camara/controle-processos',
				loadChildren: () =>
					import('./controle-processos/controle-processos.module').then((m) => m.ControleProcessosModule),
			},
			{
				data: { title: 'Controle de Processos',  origem: 'PREFEITURA', roles: ['Controle de Processo'] },
				path: 'controle-processos',
				loadChildren: () =>
					import('./controle-processos/controle-processos.module').then((m) => m.ControleProcessosModule),
			},
			{
				data: { title: 'Controle de Processos App Cidades', roles: ['Controle de Processo'] },
				path: 'controle-processos-app-cidades',
				loadChildren: () =>
					import('./controle-processos-app-cidades/controle-processos-app-cidades.module').then((m) => m.controleProcessosAppCidadesModule),
			},
			{
				data: { title: 'Parâmetros', roles: ['Parâmetros'] },
				path: 'parametros',
				loadChildren: () => import('./parametros/parametros.module').then((m) => m.ParametrosModule),
			},
			{
				data: { title: 'Patos Premia', roles: ['Patos Premia'] },
				path: 'patos-premia',
				loadChildren: () => import('./patos-premia/patos-premia.module').then((m) => m.PatosPremiaModule),
			},
			{
				data: { title: 'Boletim Escolar', roles: ['Boletim Escolar'] },
				path: 'boletim-escolar',
				loadChildren: () => import('./boletim-escolar/boletim-escolar.module').then((m) => m.BoletimEscolarModule),
			},
			{
				data: { title: 'Vínculo Escolar', roles: ['Vínculo Escolar'] },
				path: 'vinculo-escolar',
				loadChildren: () => import('./vinculo-escolar/vinculo-escolar.module').then((m) => m.VinculoEscolarModule),
			},
			{
				data: { title: 'Câmara Legislativa', roles: ['Câmara'] },
				path: 'camara',
				loadChildren: () => import('./camara/camara.module').then((m) => m.CamaraModule),
			},
			{
				data: { title: 'Servidores', roles: ['Servidores'] },
				path: 'servidores',
				loadChildren: () => import('./servidores/servidores.module').then((m) => m.ServidoresModule),
			},
			{
				data: { title: 'Tabelas', roles: ['Tabelas'] },
				path: 'lista-espera',
				loadChildren: () => import('./lista-espera/lista-espera.module').then((m) => m.ListaEsperaModule),
			},
			{
				data: { title: 'Whatsapp-Camara', origem: 'CAMARA', roles: ['Whatsapp Configuração'] },
				path: 'camara/whatsapp',
				loadChildren: () => import('./whatsapp/whatsapp.module').then((m) => m.WhatsappModule),
			},
			{
				data: { title: 'Whatsapp', origem: 'PREFEITURA', roles: ['Whatsapp Configuração'] },
				path: 'whatsapp',
				loadChildren: () => import('./whatsapp/whatsapp.module').then((m) => m.WhatsappModule),
			},
         {
				data: { title: 'Zoneamento Escolar', origem: 'PREFEITURA', roles: ['Zoneamento Escolar Listagem', 'Zoneamento Escolar Escolas'] },
				path: 'zoneamento-escolar',
				loadChildren: () => import('./zoneamento-escolar/zoneamento-escolar.module').then((m) => m.ZoneamentoEscolarModule),
			},
			{
				data: { title: 'Histórico notificações' },
				path: 'historico-notificacao-painel',
				loadChildren: () =>
					import('./historico-notificacao-painel/historico-notificacao-painel.module').then(
						(m) => m.HistoricoNotificacaoPainelModule,
					),
			},
         {
				data: { title: 'Carteira Digital', origem: 'PREFEITURA', roles: ['Carteira Digital Servidores', 'Carteira Digital Comerciantes', 'Carteira Digital Configuração'] },
				path: 'carteira-digital',
				loadChildren: () => import('./carteira-digital/carteira-digital.module').then((m) => m.CarteiraDigitalModule),
			},
			{
				data: { title: 'Carteirinhas', origem: 'PREFEITURA', roles: ['Carteirinhas'] },
				path: 'carteirinhas',
				loadChildren: () => import('./carteirinhas/carteirinhas.module').then((m) => m.CarteirinhasModule),
			},
			{
				data: { title: 'Demográficas e Serviços', roles: ['Demográficas e Serviços'] },
				path: 'demograficas-servicos',
				loadChildren: () =>
					import('./demograficas_servicos/demograficas-servicos.module').then((m) => m.DemograficasServicosModule),
			},
			{
				data: { title: 'Curriculos', roles: ['Curriculo'] },
				path: 'curriculos',
				loadChildren: () => import('./curriculos/curriculos.module').then((m) => m.CurriculosModule),
			},
			{
				data: { title: 'Rotativo Digital', roles: ['Rotativo Digital'] },
				path: 'rotativo-digital',
				loadChildren: () => import('./rotativo-digital/rotativo-digital.module').then((m) => m.RotativoDigitalModule),
			},
			{
				data: { title: 'Restaurante Popular', roles: ['Restaurante Popular'] },
				path: 'restaurante-popular',
				loadChildren: () => import('./restaurante-popular/restaurante-popular.module').then((m) => m.RestaurantePopularModule),
			},
			{
				data: { title: 'Configurações Aplicativo', roles: ['Configurações aplicativo'] },
				path: 'config-app',
				loadChildren: () =>
					import('./configuracoes-aplicativo/configuracoes-aplicativo.module').then(
						(m) => m.ConfiguracoesAplicativoModule,
					),
			},
         {
            data: { title: 'Telemedicina', roles: ["Painel Telemedicina"] },
            path: 'telemedicina',
            loadChildren: () =>
               import('./telemedicina/telemedicina.module').then(
                  m => m.TelemedicinaModule,
               ),
         },
			{
				data: { title: 'Editor de Documentos', roles: [] },
				path: 'editor-documentos',
				loadChildren: () => import('./util/pdf-viewer/pdf-viewer.module').then((m) => m.PdfViewerModule),
			},
			{
				data: { title: 'Bate Papo Whatsapp', roles: [] },
				path: 'bate-papo-whatsapp',
				loadChildren: () => import('./bate-papo-whatsapp/bate-papo-whatsapp.module').then((m) => m.BatePapoWhatsappModule),
			},
			{
				data: { title: 'Configurações Whatsapp', roles: [] },
				path: 'config-whatsapp',
				loadChildren: () => import('./configuracoes-whatsapp/configuracoes-whatsapp.module').then((m) => m.ConfiguracoesWhatsappModule),
			},
         {
				data: { title: 'Certificados', roles: ['Certificados'] },
				path: 'certificados',
				loadChildren: () => import('./certificados/certificados.module').then((m) => m.CertificadosModule),
			},
			{
				data: { title: 'Mural', roles: ['Mural'], origem: 'CAMARA' },
				path: 'camara/mural',
				loadChildren: () => import('./mural/mural.module').then((m) => m.MuralModule),
			},
			{
				data: { title: 'Dashboard Mural', origem: 'CAMARA' },
				path: 'camara/dashboard/mural',
				loadChildren: () => import('./dashboard-mural/dashboard-mural.module').then((m) => m.DashboardMuralModule),
			},
         {
				data: { title: 'Ingressos', roles: ['Ingressos'] },
				path: 'ingressos',
				loadChildren: () => import('./ingressos/ingressos.module').then((m) => m.IngressosModule),
			},
			{
				data: { title: 'Permissões do Dashboard da Câmara' },
				path: 'camara/permissoes/dashboard',
				loadChildren: () => import('./camara/dashboard-permissoes-camara/dashboard-permissoes-camara.module').then((m) => m.DashboardPermissoesCamaraModule),
			},
		],
	},
	{ path: '**', pathMatch: 'full', redirectTo: '' },
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes, { useHash: true, onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
