<div class="modal-title" [ngClass]="{'urgent': urgencia}">
   <div class="modal-title-inner">
      <span class="modal-title-text">{{ titulo }}</span>
      <span aria-hidden="true"></span>
   </div>
</div>

<mat-dialog-content class="mb-3" style="width: 700px; height: 560px">
   <div *ngIf="this.ocorrencia?.solicitante">
      <div class="form-row">
         <div class="form-group col-md-6">
            <label>Nome:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.cidadao?.nome || 'Não informado'
               }}</span>
         </div>
         <div class="form-group col-md-6">
            <label>CPF:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.cidadao?.documento || 'Não informado'
               }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Escola:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.empresa?.razaoSocial || 'Não informado'
               }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Cargo:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.profissao?.nome || 'Não informado'
               }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Endereço:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.empresa?.enderecoFormatado || 'Não
               informado' }}</span>
         </div>
      </div>
      <div class="form-row" *ngIf="this.ocorrencia?.latitude != null  && this.ocorrencia?.longitude != null">
         <div class="form-group col-md-12">
            <label>Local do SOS:</label>
            <button mat-raised-button class="col-md-12" color="primary" (click)="openLink()">
               Acessar <i class="fa-solid fa-map-location-dot"></i>
            </button>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-4">
            <label>Telefone do servidor:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.solicitante?.cidadao?.telefone || 'Não informado'
               }}</span>
         </div>
         <div class="form-group col-md-4">
            <label>Telefone emergencial:</label>
            <span class="form-control bg-light">{{ this.emergencialUm || 'Não informado' }}</span>
         </div>
         <div class="form-group col-md-4">
            <label>Telefone emergencial 2:</label>
            <span class="form-control bg-light">{{ this.emergencialDois || 'Não informado' }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Data e hora do chamado:</label>
            <span class="form-control bg-light">{{ this.getHoraFormatada(this.ocorrencia?.horaExataChamado) | date:
               "dd/MM/yyyy HH:mm"}}</span>
         </div>
      </div>
   </div>

   <div *ngIf="this.ocorrencia?.cidadaoSolicitante">
      <div class="form-row">
         <div class="form-group col-md-6">
            <label>Nome:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.cidadaoSolicitante?.cidadao?.nome || 'Não informado'
               }}</span>
         </div>
         <div class="form-group col-md-6">
            <label>CPF:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.cidadaoSolicitante?.cidadao?.documento || 'Não
               informado' }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Categoria:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.cidadaoSolicitante?.categoria?.nome || 'Não
               informado' }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Local do SOS:</label>
            <button mat-raised-button class="col-md-12" color="primary" (click)="openLink()">
               Acessar <i class="fa-solid fa-map-location-dot"></i>
            </button>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Telefone do cidadão:</label>
            <span class="form-control bg-light">{{ this.ocorrencia?.cidadaoSolicitante?.cidadao?.telefone || 'Não
               informado' }}</span>
         </div>
      </div>
      <div class="form-row">
         <div class="form-group col-md-12">
            <label>Data e hora do chamado:</label>
            <span class="form-control bg-light">{{ this.getHoraFormatada(this.ocorrencia?.horaExataChamado) | date:
               "dd/MM/yyyy HH:mm"}}</span>
         </div>
      </div>
   </div>
   <div class="form-row justify-content-end">
      <button type="button" mat-raised-button class="btn btn-sm ml-1" style="min-width: 45px;" (click)="fechar()">
         <i class="fa-solid fa-xmark"></i> Fechar
      </button>
   </div>
</mat-dialog-content>
