import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SosService } from '../sos.service';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Component({
	selector: 'app-modal-detalhes-ocorrencia',
	templateUrl: './modal-detalhes-ocorrencia.component.html',
	styleUrls: ['./modal-detalhes-ocorrencia.component.scss'],
})
export class ModalDetalhesOcorrenciaComponent implements OnInit {
	urgencia: boolean = false;
	ocorrencia: any;
	titulo: string = 'Ocorrência';
	emergencialUm: string;
	emergencialDois: string;
	notificacao: any;
	ocorrenciaSos: any;
	numerosDeTelefone: any[] = [];
	tipo = 'empresa';
	constructor(
		public dialogRef: MatDialogRef<ModalDetalhesOcorrenciaComponent>,
		public datePipe: DatePipe,
		public sosService: SosService,
		@Inject(MAT_DIALOG_DATA) public data: any,
	) {
		this.tipo = data.tipo;
		this.urgencia = data.urgencia;
		this.ocorrencia = data.oco;
		this.notificacao = data.id;
		this.numerosDeTelefone = data.oco?.solicitante?.telefoneEmergencial;
	}

	ngOnInit(): void {
		if (this.ocorrencia?.solicitante) {
			if (this.notificacao != null) {
				let idNumero: number = this.notificacao;
				this.sosService.getOcorrencia(idNumero).subscribe((oco) => {
					this.ocorrencia = oco;
					this.numerosDeTelefone = oco?.solicitante?.telefoneEmergencial?.split(',');
					this.emergencialUm = this.numerosDeTelefone[0];

					if (this.numerosDeTelefone?.length > 1) {
						this.emergencialDois = [...this.numerosDeTelefone?.slice(1)].join(', ');
					}
				});
			}
			this.numerosDeTelefone = this.ocorrencia?.solicitante?.telefoneEmergencial?.split(',');
			this.emergencialUm = this.numerosDeTelefone[0];

			if (this.numerosDeTelefone?.length > 1) {
				this.emergencialDois = [...this.numerosDeTelefone?.slice(1)].join(', ');
			}
		}

		if (this.urgencia) this.titulo = 'SOS Recebido';
	}

	openLink() {
		window.open(
			`https://www.google.pt/maps?q=${this.ocorrencia?.latitude},${this.ocorrencia?.longitude}`,
		);
	}

	getHoraFormatada(data) {
		return moment.utc(data).local();
	}

	fechar() {
		this.dialogRef.close();
	}
}
