<div mat-dialog-content *ngIf="loading" class="mb-3 d-flex justify-content-center"
  style="min-width: 300px; min-height: 200px">
  <div class="d-block align-self-center">
    <mat-spinner></mat-spinner>
  </div>
</div>
<div mat-dialog-title style="margin-bottom: 10px;" *ngIf="!loading">
  <span>Artigos</span>
  <button tabindex="-1" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()"
    style="padding: 10px;">
    <span aria-hidden="true" style="font-size: x-large;">&times;</span>
  </button>
</div>
<div mat-dialog-content *ngIf="!loading" class="mb-3" style="min-height: 200px">
  <div class="row mb-2 mx-1 d-block">
    <div class="card card-body mb-3">
      <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
      <!-- <h2 class="heading">{{ categoria?.nome }}</h2> -->
      <div class="c-row c-article-row" *ngFor="let artigo of listaArtigos" (click)="verArtigo(artigo)">
        <i class="icon-article-table-row"></i>
        <div class="article-title"><a class="underline">{{artigo.titulo}}</a></div>
        <div class="description-text" style="max-width: 100%;" [innerHtml]="artigo.descricaoContent"></div>
        <div class="help-text">{{ artigo.atualizadoEm | date }}</div>
      </div>
      <!-- <span class="voltar" (click)="voltar()">
        <i class="fas fa-arrow-left"></i> Voltar
      </span> -->
    </div>
  </div>
</div>
<div mat-dialog-actions class="d-block text-right">

</div>