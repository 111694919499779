import { AuthService } from './../services/auth.service';
// // import { ModalAlertasComponent } from './modal-alertas/modal-alertas.component';
// import { Injectable } from '@angular/core';
// import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// import { Observable } from 'rxjs';
// import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { PermissaoService } from '../services/permissao.service';
// import { ModalAlertasComponent } from '../util/modal-module/modal-alertas/modal-alertas.component';

// @Injectable({
//   providedIn: 'root'
// })
// export class PermissionGuard implements CanActivate{


//   public modalAlertasDialogRef: MatDialogRef<ModalAlertasComponent>

//   constructor(
//       private permissaoService: PermissaoService,
//       private router: Router,
//       private dialog: MatDialog
//     ) { }

//   /**
//    * Guarda de rotas por permissões
//    *
//    * @param {ActivatedRouteSnapshot} route
//    * @param {RouterStateSnapshot} state
//    * @returns {(boolean | Observable<boolean> | Promise<boolean>)}
//    * @memberof PermissionGuard
//    */
//   canActivate(
//     route: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
//       if(this.permissaoService.isAdmin()){
//         return true;
//       }
//       else{
//           let data = {
//               mensagem: "Você não tem acesso a este modulo."
//           }
//         this.modalAlertasDialogRef = this.dialog.open(ModalAlertasComponent, {data})
//         this.modalAlertasDialogRef.beforeClosed().subscribe(
//             ()=> {
//                 this.router.navigate(['/m/dashboard'])
//             }
//         )
//         return false;
//       }
//   }

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PermissaoService } from '../services/permissao.service';
import { ModalAlertasComponent } from '../util/modal-module/modal-alertas/modal-alertas.component';
import { BaseDeConhecimentoBotaoAjudaService } from '../base-de-conhecimento/_services/base-de-conhecimento-botao-ajuda.service';

@Injectable({ providedIn: 'root' })
export class PermissionGuard  {
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private permissaoService: PermissaoService,
    private baseDeConhecimentoBotaoAjudaService: BaseDeConhecimentoBotaoAjudaService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentRoles = this.permissaoService.currentRotinas;
    if (currentRoles) {
      // authorised so return true
      // let index = currentRoles.findIndex((item) => {
      //   return route.data.roles.findIndex(role => role === item.nome) != -1
      // })
      let hasRole = false;
      route.data = {
        ...route.data,
        privilegio: []
      };
      const roles = [];
      currentRoles.forEach((element: any) => {
        if (route.data.roles.findIndex((role: any) => role === element.nome && (!route.data.origem || route.data.origem == element.origem)) != -1) {
          roles.push(element?.id || 0);
          hasRole = true;
          route.data.privilegio.push(element.privilegio)
        }
      });
      this.baseDeConhecimentoBotaoAjudaService.setAcvRoute(roles);
      if (route.data.roles && hasRole) {
        return true;
      } else {

        // TODO testar se este estado eh o estado de retorno quando não tem permissão
        if (state?.url == '/login') {
          return true;
        }

        const dialogRef = this.dialog.open(ModalAlertasComponent, {
          data: {
            mensagem: "Você não tem acesso a este módulo."
          }
        })

        dialogRef.afterClosed().subscribe(() => { })

        return false;
      }
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout()
    // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}


