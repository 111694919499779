import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { Title } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { ContextoAutentificacaoVereador } from '../model/contexto-autenticacao-vereador.model';
import { Usuario } from "../model/usuario.model";
import { BrowserFavicons } from './favicons';
import { HttpUtilService } from './http-util-service';
import { SocketService } from './socket.service';
import { StorageService } from './storage.service';

@Injectable({
   providedIn: 'root'
})
export class AuthService {
   private url = environment.API_URL
   token = '';
   redirectUrl: string;

   public mostrarMenuEmitter = new EventEmitter<boolean>();

   constructor(
      private http: HttpClient,
      private util: HttpUtilService,
      private router: Router,
      private favicons: BrowserFavicons,
      private titleService: Title,
      private storage: StorageService,
      private socketService: SocketService,
   ) {
      this.token = this.storage.read('token') || '';
      this.redirectUrl = 'm';
   }

   /**
    * efetua login do usuario
    * @param usuario
    */
   public fazerLogin(usuario: Usuario): Observable<any> {
      let httpOptions = {
         headers: new HttpHeaders({
            cidade: this.storage.read("cidade")
         })
      }
      //colocar rota que faz login de servidor
      return this.http.post<Usuario>(`${environment.API_URL}/loginservidor/web/v2`, usuario, httpOptions);
   }

   public buscarTodasCidades(): Observable<any> {
      return this.http.get(this.url + "/master/adm/cidades")
   }

   public getUser(user): Promise<any> {
      return this.http.get(`${environment.API_URL}/usuario/${user}`, this.util.httpOptions())
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   // ...
   public isAuthenticated(): boolean {
      const token = this.storage.read('token');
      if (this.router.url === "/autenticidade-documentos") {
         this.mostrarMenuEmitter.emit(false);
         return false;
      }
      else if (token === null || token === undefined) {
         this.mostrarMenuEmitter.emit(false)
         this.router.navigate(['/login'])
         return false;
      } else {
         this.mostrarMenuEmitter.emit(true);
         return true;
      }
   }

   /**
    *
    */
   public logout(): void {
      this.storage.remove('token');
      this.storage.remove('isAdmin');
      this.storage.remove('cidade');
      this.storage.remove('permissoes');
      this.storage.remove('logo_cidade');
      this.storage.remove('facebook_id');
      this.storage.remove('favicon');
      this.storage.remove('nome_cidade');
      this.storage.remove('rotinas');
      this.storage.remove('cabecalhos');
      this.setTitleAndFavicon("Cidades", "assets/icone_app_cidades.png")
      localStorage.clear();
      this.mostrarMenuEmitter.emit(false);
      this.router.navigate(['/login']);
      this.token = '';
      this.socketService.disconnect()
   }

   public estaLogado(): boolean {
      return this.token.length > 0;
   }

   public forgotPassword(cpf, cidade): Promise<any> {
      return this.http.post(`${this.url}/recuperarsenha`, cpf, { headers: { cidade: cidade } })
         .toPromise()
         .then((data) => data)
         .catch((err) => Promise.reject(err))
   }

   setTitleAndFavicon(title: string, favicon: string) {
      try {
         if (favicon) {
            this.favicons.activate(favicon);
         }
         if (title) {
            this.titleService.setTitle("App " + title)
         }
      } catch (ex) {
      }
   }

   aceitarTermos() {
      return this.http.put(`${this.url}/cidadao/aceitar-termos-uso-e-privacidade`, null, this.util.httpOptions())
         .toPromise();
   }

   getTermos(cidade: string) {
      let headers = new HttpHeaders({
         // 'Authorization': "Bearer " + this.storage.read('token'),
         'cidade': this.storage.read('cidade'),
         'Content-Type': 'application/json'
      })
      return this.http.get(`${this.url}/termodeuso/novo/${cidade}`, { headers, responseType: 'text' })
         .toPromise();
   }

   getCabecalhosDinamicos(): Observable<any> {
      return this.http.get(this.url + "/cabecalhos/dinamicos", this.util.httpOptions())
   }

   public trocarContextoAutenticacaoUsuarioVereador(contexto: {
      idVereador?: number,
      cpfVereador?: string,
      idCidadao?: number
   }): Observable<ContextoAutentificacaoVereador> {
      let headers = new HttpHeaders({
         'Authorization': "Bearer " + this.storage.read('token'),
         'cidade': this.storage.read('cidade'),
         'Content-Type': 'application/json'
      });
      const adminToken = this.storage.read('adminToken');
      if (adminToken) {
         headers = headers.append('AuthorizationProfile', 'Bearer ' + adminToken);
      }
      return this.http.post<ContextoAutentificacaoVereador>(this.url + '/trocar-contexto-vereador', contexto, { headers });
   }
}
