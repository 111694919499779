import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuthService } from '../services/auth.service';
import { PermissaoService } from '../services/permissao.service';
import { ModalModule } from '../util/modal-module/modal.module';
import { AutenticidadeDocumentosComponent } from './autenticidade-documentos.component';
import { AutenticidadeDocumentosRoutingModule } from './autenticidade-documentos.routing.module';
import {MatDividerModule} from '@angular/material/divider';
import {MatCardModule} from '@angular/material/card';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        AutenticidadeDocumentosRoutingModule,
        MatDialogModule,
        MatInputModule,
        MatProgressSpinnerModule,
        NgxDocViewerModule,
        ModalModule,
        MatProgressBarModule,
        NgxMaskDirective,
        NgxMaskPipe,
        MatButtonModule,
        MatTooltipModule,
        MatDividerModule,
        MatCardModule,
        MatExpansionModule,       
        MatIconModule, 
    ],
    declarations: [AutenticidadeDocumentosComponent],
    providers: [AuthService, PermissaoService, provideNgxMask()]
})
export class AutenticidadeDocumentosModule { }
