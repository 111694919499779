<div mat-dialog-title>
  <span>{{mensagem}}</span>
</div>
<div mat-dialog-content *ngIf="content" style="max-width: 450px;">
    <p class="text-dark text-justify" [innerHTML]="content"></p>
</div>
<div mat-dialog-actions align="end" style="min-width: 300px;" class="mr-3">
    <button type="button" mat-raised-button class="mx-2" (click)="close()" data-dismiss="modal">
      {{textoBtnNao}}
    </button>
    <button type="button" mat-raised-button color="accent" class="text-white" (click)="save()">
      {{textoBtnSim}}
    </button>
</div>
