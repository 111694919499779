import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutenticidadeService } from './autenticidade.service';
import moment from 'moment';
import { StorageService } from '../services/storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ModalErrorComponent } from '../util/modal-module/modal-error/modal-error.component';
import { ModalErroV2Component } from '../util/modal-module/modal-erro-v2/modal-erro-v2.component';
import { first } from 'rxjs';

@Component({
  selector: 'app-autenticidade-documentos',
  templateUrl: './autenticidade-documentos.component.html',
  styleUrls: ['./autenticidade-documentos.component.scss'],
})
export class AutenticidadeDocumentosComponent implements OnInit {

  file: any;

  hashPdf: number;
  cidade: string;
  documento: any;
  assinaturas: [];
  loading: boolean = true;

  formHashCode = new UntypedFormControl(null)

  pesquisou = false

  windowWidth: number;

  cidadeObj;
  prefeitura: any;
  origem = "PREFEITURA"

  constructor(
    private autenticidadeService: AutenticidadeService,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageService,
    private dialog: MatDialog,
  ) {
    this.windowWidth = window.innerWidth;

    this.route.queryParams.subscribe(params => {
      this.hashPdf = params['hash'];
      this.cidade = params['cidade'];
      this.origem = params['origem'];
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit() {
    if (this.cidade) {
      this.storage.save('cidade', this.cidade);
      this.autenticidadeService.getPrefeituraCidadeByNomeReferencia()
        .subscribe({
          next: (pref) => {
            this.prefeitura = pref;
            if(this.origem == 'CAMARA'){
               this.autenticidadeService.getCamaraByNomeReferencia().pipe(first()).subscribe({
                  next:(cidade)=>{
                     this.cidadeObj = cidade
               }})
            } else {
               this.cidadeObj = this.prefeitura?.cidade;
            }
          },
          error: (err) => this.dialog.open(ModalErrorComponent, { data: err })
        })
    }

    this.router.navigate([], {
      queryParams: {
        'hash': null
      },
      queryParamsHandling: 'merge'
    });

    this.formHashCode.setValue(this.hashPdf);
    this.getDocument(this.hashPdf);
  }


  getNome(nome: string){
   if(this.origem === 'CAMARA') return nome.replace("Prefeitura", "Camara");
   return nome;
 }

  resetSearch() {
    this.loading = false;
    this.pesquisou = false;
    this.formHashCode.reset();
    this.assinaturas = [];
    this.documento = null;
  }

  getDocument(hash) {
    if (hash) {
      this.autenticidadeService.getDocumento(hash, this.cidade)
        .then(async (data) => {
          if (data) {
            this.documento = data;
            await this.getHistoricoAnexo(this.documento.url);

            if (this.assinaturas.length == 0) {
              await this.getAssinaturas(this.documento.id);
            }
          } else {
            this.documento = null
            this.loading = false
          }
        })
        .catch((err) => {
          this.loading = false
        });
    } else {
      this.loading = false;
      setTimeout(() => {
        this.pesquisou = false;
      }, 7500);
    }
  }

  async getHistoricoAnexo(url) {
    await new Promise((resolve, reject) => {
      this.autenticidadeService.getHistoricoAnexosByUrlDeUmAnexo(url, this.cidade)
        .then(async (data) => {
          this.assinaturas = data.filter(item => item.tipo == "ASSINATURA")

          if (data && data.length > 0) {
            this.documento.url = data[data.length - 1].urlAnexo
          }

          this.loading = false
          resolve(true)
        })
        .catch((err) => {
          this.loading = false
          reject(false)
        });
    })
  }

  async getAssinaturas(id) {
    await new Promise((resolve, reject) => {
      this.autenticidadeService.getAssinaturas(id, this.cidade)
        .then((data) => {
          this.assinaturas = data
          this.loading = false
          resolve(true)
        })
        .catch((err) => {
          this.loading = false
          reject(false)
        });
    })
  }

  search() {
    this.loading = true
    this.pesquisou = true
    this.getDocument(this.formHashCode.value)
  }

  onFileLoad() {
    document.getElementById("progress").style.display = "none";
  }

  getDate(data) {
    return moment.utc(data).local().format("DD/MM/YYYY HH:mm");
  }

  handleFileInput(event: any): void {
    const files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.file = files.item(0);
    }
  }

  searchFile() {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);

      this.autenticidadeService.getArquivoByHashContido(formData)
        .subscribe((arquivo) => {
          if (arquivo) {

            this.loading = true;
            this.pesquisou = true;
            this.documento = arquivo;
            this.formHashCode.setValue(this.documento.hashCode);

            this.getDocument(arquivo?.hashCode);

          } else {
            this.dialog.open(ModalErroV2Component, {
              data: {
                titulo: 'Erro na verificação de autênticidade',
                motivo: `Não foi possível validar este arquivo.`,
                motivoExtendido: `Escrever, desenhar ou anexar imagens à um documento oficial assinado,\n compromete sua integridade tornando-o assim inválido.\n\nEste documento não deve ser aceito como prova de autenticidade para nenhum fim.`
              }
            })
          }
        })
    }
  }
}
