<div mat-dialog-content  style="min-height: 100%;">
  <div class="form-group" *ngIf="!loading">
    <div [innerHtml]="content | safe"></div>
    <div class="text-center">
      <button type="button" tabindex="-1" (click)="aceitarTermos()" class="btn btn-sm btn-success mx-2" style="min-width: 80px;"
        [disabled]="loadingBtn">
        <mat-spinner class="d-inline-flex m-0 p-0 text-center align-middle" *ngIf="loadingBtn" diameter="20">
        </mat-spinner>
        <span *ngIf="!loadingBtn">
          <i class="fas fa-circle-check"></i>
        </span>
        Aceitar
      </button>
    </div>
  </div>
  <div class="form-group" *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</div>