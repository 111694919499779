<div class="whatsapp-chat">
    <div class="chat-header">
        <div class="info-block">
            <!-- <img class="foto-perfil" src="{{ cidadao.imagem }}" alt="Imagem cidadão"> -->
            <div class="info-cidadao">
                <span>{{ nome ? nome : '' }}</span>
                <span style="font-size: small">{{ telefone }}</span>
            </div>
        </div>
        <button tabindex="-1" type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()" style="padding: 10px;">
            <span aria-hidden="true" style="font-size: x-large;">&times;</span>
        </button>
    </div>

    <div class="chat-content">
        <div *ngFor="let msg of mensagens" class="message">
            {{ msg }}
        </div>
    </div>

    <div class="chat-input">
        <textarea [(ngModel)]="mensagem" placeholder="Digite sua mensagem..."></textarea>
        <button (click)="enviarMensagem()">Enviar</button>
    </div>
</div>