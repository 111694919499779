import { Observable } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpUtilService } from '../services/http-util-service';
import { tap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

@Injectable({
    providedIn: 'root'
})
export class ModalidadeEsporteService {

    // emite evento para componente pai
    @Output() public atualizaNavbarInformacoes = new EventEmitter();

    private url = environment.API_URL + '/modalidade/esporte';

    constructor(
        private http: HttpClient,
        private util: HttpUtilService,
		private storage: StorageService,
    ) { }



    getCategorias(): Observable<any> {
        return this.http.get(this.url, this.util.httpOptions());
    }

    getCategoriasPageable(options: any): Observable<any> {
        return this.http.post(this.url + '/pageable', options, this.util.httpOptions());
    }



    createCategoria(model): Promise<any> {
        return this.http.post(this.url , model, this.util.httpOptions()).toPromise()
        .then((data) => {
            this.atualizaNavbarInformacoes.emit(true);
            return data;
        });
    }

    updateCategoria(model): Promise<any> {
        return this.http.put(this.url , model, this.util.httpOptions()).toPromise()
        .then((data) => {
            this.atualizaNavbarInformacoes.emit(true);
            return data;
        });
    }

    deleteCategoria(id: number): Observable<any> {
        return this.http.delete(this.url + '/' + id, this.util.httpOptions())
        .pipe(
            tap(() => {this.atualizaNavbarInformacoes.emit(true); })
        );
    }

  getModalidadePdf(idModalidadeCategoria: any, tipoPdf: number): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this.storage.read('token'),
      'cidade': this.storage.read('cidade'),
      'Content-Type': 'application/json'
    });

    return this.http.get(`${this.url}/impressao/${idModalidadeCategoria}/${tipoPdf}`, { headers, responseType: 'arraybuffer' });

  }
}
