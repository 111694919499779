import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StorageService } from 'src/app/services/storage.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-modal-esqueceu-senha',
  templateUrl: './modal-esqueceu-senha.component.html'
})
export class ModalEsqueceuSenhaComponent implements OnInit {

  sucesso = false;
  erro = false;
  loadingBtn = false;

  public formulario: UntypedFormGroup = new UntypedFormGroup({
    'documento': new UntypedFormControl(null, [Validators.required, Validators.minLength(11), Validators.maxLength(14)]),
    'cidade': new UntypedFormControl(-1, [Validators.required]),
  })

  public cidades = [];

  constructor(
    private modalEsqueceuSenhaDialogRef: MatDialogRef<ModalEsqueceuSenhaComponent>,
    private dialog: MatDialog,
    private authService: AuthService, 
		private storage: StorageService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
      this.cidades = data;
   }
  ngOnInit() {
  }

  public setaCidade() {
    if(this.formulario.value.cidade != -1)
      this.storage.save("cidade", this.formulario.value.cidade)
  }

  esqueceuASenha(){
    if(this.formulario.status === 'INVALID' || this.formulario.value.cidade === -1){
      this.formulario.get('documento').markAsTouched()
      this.formulario.get('cidade').markAsTouched()
    }
    else{
      this.loadingBtn = true;
      let model = {
        documento: this.formulario.value.documento
      }
      this.authService.forgotPassword(model, this.formulario.value.cidade)
      .then((data) => {
        this.sucesso = true;
        this.loadingBtn = false
      })
      .catch((err) => {
        this.erro = true
        this.loadingBtn = false
      })
    }
  }

  close(){
    this.modalEsqueceuSenhaDialogRef.close()
  }



}
