import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs/operators';
import { HttpUtilService } from '../../services/http-util-service';
import { environment } from '../../../environments/environment';
import { AgendamentoServicoService } from './agendamento-servico.service';

@Injectable({
    providedIn: 'root',
})
export class CamaraAgendamentoServicoService extends AgendamentoServicoService {

    url = environment.API_URL + '/agendamento/servico/camara';

    constructor(
        public http: HttpClient,
        public util: HttpUtilService
    ) {
        super(http, util)
    }
}
