import { Component, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ControleProcessosService } from 'src/app/controle-processos/controle-processos.service';

@Component({
  selector: 'app-modal-upload-file-to-s3',
  templateUrl: './modal-upload-file-to-s3.component.html',
  styleUrls: ['./modal-upload-file-to-s3.component.scss']
})
export class ModalUploadFileToS3Component {
  // Reactive Form controls
  uploadForm = new FormGroup({
    fileName: new FormControl('', Validators.required), // Input do nome do arquivo
    file: new FormControl(null, Validators.required)     // Input do arquivo
  });

  loading = false;
  @Output() fileUploaded = new EventEmitter<string>();

  constructor(
    private controleProcessosService: ControleProcessosService,
    public dialogRef: MatDialogRef<ModalUploadFileToS3Component>
  ) {}

  onFileSelected(event: any): void {
    const file = event.target.files[0] || null;
    this.uploadForm.patchValue({ file });
  }

  removeFile(): void {
    this.uploadForm.patchValue({ file: null });
  }

  uploadFile(): void {
    if (this.uploadForm.valid) {
      const fileName = this.uploadForm.get('fileName')?.value;
      const file = this.uploadForm.get('file')?.value;
  
      // Criar um objeto FormData para enviar o arquivo e o nome
      const formData = new FormData();
      formData.append('file', file); // 'file' deve ser o nome esperado no backend
      formData.append('fileName.pdf', fileName); // 'fileName' conforme requerido na URL
  
      this.loading = true;
      this.controleProcessosService.uploadMultipart(formData, fileName)
        .subscribe({
          next: (response) => {
            this.loading = false;
            const fileUrl = response.url;
            this.fileUploaded.emit(fileUrl);
            this.dialogRef.close();
          },
          error: (err) => {
            this.loading = false;
            console.error('Upload failed:', err);
          }
        });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
