<div mat-dialog-title class="pb-0">
   <span> {{alerta?.name ? alerta?.name : 'Aviso'}} </span>
   <button tabindex="-1" type="button" style="padding-top: 1.3rem !important;" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
      <span aria-hidden="true" style="font-size: x-large">&times;</span>
   </button>
</div>
<div mat-dialog-content class="pb-0" [ngStyle]="{'max-width': maxWidth}">
    <div [innerHtml]="alerta?.mensagem"></div>
</div>
<div mat-dialog-actions class="d-block text-right mt-2">
    <button *ngIf="!disableClose" type="button" class="btn btn-primary mx-2" (click)="close()">
      Ok
    </button>
</div>
