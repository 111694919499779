import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { UtilidadePublicaCategoria } from '../model/utilidadePublicaCategoria.model';
import { HttpUtilService } from '../services/http-util-service';
import { StorageService } from '../services/storage.service';
import { UtilidadePublicaService } from './utilidade-publica.service';

@Injectable({ providedIn: 'any' })
export class CamaraUtilidadePublicaService extends UtilidadePublicaService {
	url = `${environment.API_URL}/utilidadepublica`;

	constructor(
		public http: HttpClient,
		public util: HttpUtilService,
		public storage: StorageService,
	) {
		super(http, util, storage);
	}

	/**
	 * Cria um objeto da categoria selecionada
	 * @param model objeto que deseja criar
	 * @param categoria tipo do objeto que deseja criar
	 */
	public async create(model: any): Promise<any> {
		return this.http
			.post(this.url + '/camara' + '/angular', model, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => console.error(err));
	}

	/**
	 * Atualiza objeto selecionado
	 * @param model objeto que deseja atualizar
	 * @param categoria tipio do objeto que deseja atualizar
	 */
	public async update(model: any): Promise<any> {
		return this.http
			.put(this.url + '/camara' + '/angular', model, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todas as servico de Utilidade Publica
	 */
	public async getCategorias(): Promise<any> {
		return this.http
			.get(this.url + '/categoria/camara', this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Cria uma categoria de Utilidade Publica
	 * @param categoria
	 */
	public async createCategoria(categoria): Promise<any> {
		return this.http
			.post(this.url + '/categoria/camara/angular', categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarUtilidadePublica.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Atualiza uma categoria de Utilidade Publica
	 * @param categoria
	 */
	public async updateCategoria(categoria: UtilidadePublicaCategoria): Promise<any> {
		return this.http
			.put(this.url + '/categoria/camara', categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => {
				this.atualizaNavbarUtilidadePublica.emit(true);
				return data;
			})
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todos os elementos de determinada categoria de Utilidade Publica
	 * @param categoria categoria do Utilidade Publica
	 */
	public async getAll(categoria: string): Promise<any> {
		return this.http
			.get(this.url + '/categoria/camara/all/' + categoria, this.util.httpOptions())
			.toPromise()
			.then((data) => data)
			.catch((err) => Promise.reject(err));
	}

	/**
	 * Busca todos os elementos de determinada categoria de Utilidade Publica
	 * @param categoria categoria do Utilidade Publica
	 */
	public getAllUp(): Observable<any> {
		return this.http.get(this.url + '/camara', this.util.httpOptions());
	}
}
