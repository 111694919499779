import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpUtilService } from 'src/app/services/http-util-service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class UsuarioService {

  url = environment.API_URL + "/membro";

  constructor(
    public http: HttpClient,
    public util: HttpUtilService
  ) {
  }

  public getAll(): Promise<any> {
    return this.http.get(this.url + '/cidadaomembros', this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => {
        Promise.reject(err);
      });
  }

  public getAllByCpf(documentos: string[]): Promise<any> {
    return this.http.get(this.url + '/cidadaomembros/' + documentos, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => {
        Promise.reject(err);
      });
  }

  public getStatusMotorista(id: number): Observable<any> {
    return this.http.get(`${environment.API_URL}/cidadao/motorista/${id}`, this.util.httpOptions());
  }

  public changeStatusMotorista(id: number): Observable<any> {
    return this.http.put(`${environment.API_URL}/cidadao/motorista/${id}`, null, this.util.httpOptions());
  }

  /**
   * Busca um cidadao pelo id
   * @param id
   */

  getById(id: number): Promise<any> {
    return this.http.get(`${environment.API_URL}/cidadao/${id}`, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => {
        Promise.reject(err);
      });
  }

  getPermissoes(id: number): Promise<any> {
    return this.http.get(`${this.url}/permissoes/${id}`, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => {
        Promise.reject(err);
      });
  }


  getPermissoesPorUsuarioLogado(): Promise<any> {
    return this.http.get(`${this.url}/permissoes/usuarioLogado`, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => {
        Promise.reject(err);
      });
  }

  /**
* registra um usuario
* @param user
*/
  public registrar(user): Promise<any> {
    return this.http.post(`${this.url}`, user, this.util.httpOptions())
      .toPromise();
  }

  /**
  * registra um usuario
  * @param model
  */
  public update(model, cpf): Promise<any> {
    return this.http.put(`${this.url}/permissoes/${cpf}`, model, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => Promise.reject(err));
  }

  /**
* deleta um usuario
* @param id
*/
  public delete(id: number): Promise<any> {
    return this.http.delete(`${this.url}/${id}`, this.util.httpOptions())
      .toPromise()
      .then(data => data)
      .catch((err) => Promise.reject(err));
  }

  public getCidadaoByCpf(cpf: String): Promise<any> {
    return this.http.get(`${this.url}/cidadao/${cpf}`, this.util.httpOptions())
      .toPromise();
  }

  public checkCidadaoByEmail(email: String): Promise<any> {
   return this.http.get(`${this.url}/cidadao/check?email=${email}`, this.util.httpOptions())
     .toPromise();
 }
}
