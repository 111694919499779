import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WhatsappService } from 'src/app/whatsapp/services/whatsapp.service';
import { ModalAlertasComponent } from '../modal-alertas/modal-alertas.component';

@Component({
  selector: 'app-modal-mensagem-whatsapp',
  templateUrl: './modal-mensagem-whatsapp.component.html',
  styleUrls: ['./modal-mensagem-whatsapp.component.scss']
})
export class ModalMensagemWhatsappComponent implements OnInit {

  public cidadao: any;
  public nome: any;
  public mensagem: string;

  public mensagens: string[] = [];
  configuracaoWhatsapp: any;
  telefone: any;

  constructor(
    private dialog: MatDialog,
    private whatsappService: WhatsappService,
    private modalWhatsappDialog: MatDialogRef<ModalMensagemWhatsappComponent>,

    @Inject(MAT_DIALOG_DATA) data

  ) {
    this.nome = data.nome;
    this.mensagem = data.mensagem;
    this.telefone = data.telefone;
  }

  ngOnInit() {
    this.verificaExibicaoWhatsapp();
  }

  close() {
    let enviouMensagem: boolean = false;
    if (this.mensagens.length > 0) enviouMensagem = true;
    this.modalWhatsappDialog.close(enviouMensagem);
  }

  verificaExibicaoWhatsapp() {
    let cidadeTemWhatsapp: boolean = false;
    this.whatsappService.cidadePossuiWhatsapp()
       .subscribe((config) => {
          this.configuracaoWhatsapp = config;
          if (this.configuracaoWhatsapp) cidadeTemWhatsapp = true;
       })
 }

  enviarMensagem() {
    function formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.replace(/\D/g, '');

      if (phoneNumber.length > 9) {
         phoneNumber = phoneNumber.slice(0, 2) + phoneNumber.slice(2);
      }

      return phoneNumber;
   }
   const formattedPhoneNumber = formatPhoneNumber(this.telefone);

    let dto = {
      phone: formattedPhoneNumber,
      message: this.mensagem,
    }
    this.whatsappService.sendMessage(dto, this.configuracaoWhatsapp?.telefone)
      .subscribe((_) => {
        const data = {
          name: "Envio de mensagem bem sucecido",
          mensagem: `Mensagem: ${dto.message}, enviada para o número: ${dto.phone}`,
        };
        this.dialog.open(ModalAlertasComponent, { data });
      }, (error) => {
        const data = {
          name: "Erro no envio da mensagem",
          mensagem: `Não foi possível enviar a mensagem: ${dto.message} para o número: ${dto.phone}`,
        };
        this.dialog.open(ModalAlertasComponent, { data });
      });
    if (this.mensagem.trim()) {
      this.mensagens.push(this.mensagem);
      this.mensagem = '';
    }
  }

}
