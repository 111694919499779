import { EventEmitter, Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class BaseDeConhecimentoBotaoAjudaService {

    rotinasEvent = new EventEmitter<any>();

    setAcvRoute(data: number[]) {
        setTimeout(() => {
            this.rotinasEvent.emit(data);
        }, 100);
    }
}