<div class="bg-dark d-flex align-items-center"
  style="height: 100%; background-image: url('../assets/home.jpg'); background-size: 100% 100%; background-repeat: no-repeat">
  <div class="bg-orange" style="height: 30px;"></div>
  <div class="container p-5">
    <div class="card card-login mx-auto mt-5">
      <div class="card-body">
        <div style="opacity: 1.0">
          <div class="d-block text-center mb-5">
            <div class="d-inline-block" style="width: 80px; height: 80px">
              <img class="img-fluid text-center" src="{{cidade?.logo || 'assets/icone_app_cidades.png'}}">
            </div>

          </div>

          <form [formGroup]="formulario" novalidate (ngSubmit)="fazerLogin()">
            <!--
        <mat-form-field class="example-full-width">
          <input matInput placeholder="Email" [formControl]="emailFormControl">

          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="emailFormControl.errors?.required">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
        -->
            <div class="form-group">
              <input class="form-control" type="text" (ngModelChange)="verificaCPF($event)" aria-describedby="cpfHelp"
                placeholder="CPF" autocomplete="off" ng-pastern="/^[0-9]*$/" mask="000.000.000-00"
                formControlName="documento">
              <small *ngIf="!formulario.get('documento').valid && formulario.get('documento').touched"
                class="form-text text-danger">CPF inválido</small>
            </div>
            <div class="form-group position-relative">
              <input class="form-control" type="{{ showPassword ? 'text' : 'password' }}" placeholder="Senha" autocomplete="off"
                formControlName="senha">
               <mat-icon (click)="showPassword = !showPassword" class="password-visibility-icon">{{showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
              <small *ngIf="!formulario.get('senha').valid && formulario.get('senha').touched"
                class="form-text text-danger">Senha inválida</small>
            </div>
            <div class="form-group" *ngIf="!carregandoCidades">
              <select class="form-control" id="cidade" (ngModelChange)="setaCidade()" formControlName="cidade">
                <option value="-1">Selecione sua cidade</option>
                <option *ngFor="let item of cidades" value="{{ item.id }}">{{item.nome}}</option>
              </select>
              <small *ngIf="formulario.get('cidade').value === -1 && formulario.get('cidade').touched"
                class="form-text text-danger">Selecione sua cidade</small>

            </div>

            <button type="submit" class="btn btn-block"
              [class.btn-danger]="loadingBtn === false && formulario.status === 'VALID'"
              [class.btn-light]="loadingBtn === true && formulario.status === 'INVALID'"
              [disabled]="loadingBtn || formulario.status === 'INVALID'">
              <mat-spinner class="d-inline-flex m-0 p-0 text-center align-middle" *ngIf="loadingBtn" diameter="20">
              </mat-spinner>
              Login
            </button>
          </form>

        </div>
        <div class="text-center">
          <a class="d-block small mt-3 btn btn-link text-primary" style="cursor: pointer;"
            (click)="esqueceSenha()">Esqueceu a senha?</a>
        </div>
      </div>
    </div>
  </div>
</div>
