import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-modal-error',
	templateUrl: './modal-error.component.html',
	styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent implements OnInit {

	@HostListener('window:keydown.enter', ['$event'])
  handleKeyDown() {
    this.close();
	}
	
	public erro: any;

	constructor(
		private modalErrorDialogRef: MatDialogRef<ModalErrorComponent>,
		@Inject(MAT_DIALOG_DATA) data,
	) {
		this.erro = data;
	}

	ngOnInit() {}

	close() {
		this.modalErrorDialogRef.close();
	}
}
