// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
   production: false,
   name: "dev",
   // url pra acesso ao baSckend

   // IP ARTHUR LOCAL
   API_URL: 'https://appcidadebackend.labcapital.com.br',

   // IP BASE DE TESTES
   // API_URL: 'https://backend.appcidades.findsolucoes.com.br',
   //API_URL: 'http://appcidadebackend.labcapital.com.br',

   // IP BASE DE PRODUÇÃO
   // API_URL: 'https://backend.appcidades.com.br',

   //WebImagem
   // API_NODE_URL: "http://192.168.0.231:8757",
   //API_NODE_URL: 'http://localhost:8757',
   API_NODE_URL: 'https://backend.appcidades.com.br',

   // Url base para acesso aos mapas do Google Maps
   API_URL_GOOGLE_MAPS: `https://www.google.com/maps/bembed/v1/place?key=`,
   // Chave para acesso à ai do Google Maps
   API_KEY_GOOGLE_MAPS: "AIzaSyCStQ5MqPfq8LgNEUb5b0W4TbwxfF51zAQ",

   // ID aplicativo pagina facebook
   APP_ID_FACEBOOK: "243810889832346",

   // PAGE_ID da pagina facebook
   APP_PAGE_ID_FACEBOOK: "524613118066742",
   tinymce: "aivlahnfz9016c4vbttyic32rui3f5uocljy44xz8uxdjswd",
   // tinymce: "7g13tx458lganpkcew77axh5zk33submq9tatardpug4dsos",
   LOCAL_STORAGE_SECRET: "W!*yJAucG%3m32OiLSjxZ4Q44&AsLg0*UJ@JLoRSq8Evy3o2&^",
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
