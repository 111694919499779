import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpUtilService } from '../services/http-util-service';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosPermissoesService {

   public url = environment.API_URL + '/relatorio/permissoes';

  constructor(
   public http: HttpClient,
   public util: HttpUtilService
  ) { }

  findAllCidComPermissao(page: number = 0, size: number = 10, nome: string): Observable<any> {
   let url = this.url;
   url += `/cids?page=${page}&size=${size}`;
   if (nome != null) {
       url += `&nome=${nome}`;
   }
   return this.http.get(`${url}`, this.util.httpOptions());
}

   findAllPermissaoCidadao(cidadaoId): Observable<any> {
      let url = this.url;
      url += `?&cidadaoId=${encodeURIComponent(cidadaoId)}`;
      return this.http.get(`${url}`, this.util.httpOptions());
   }

   findEstruturaCompleta() {
      let url = this.url + "/estrutura-completa"
      return this.http.get(`${url}`, this.util.httpOptions())
   }

   criaMultiplo(dto): Observable<any> {
      return this.http.post(`${this.url}/multiplo`, dto, this.util.httpOptions());
  }

  deletePermissaoById(permissaoId): Observable<any> {
   return this.http.delete(`${this.url}/${permissaoId}`, this.util.httpOptions());
}

  deleteAllByCidadaoId(cidadaoId: number): Observable<any> {
   return this.http.delete(`${this.url}/by-cidadao/${cidadaoId}`, this.util.httpOptions());
}
}
