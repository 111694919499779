import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpUtilService } from "src/app/services/http-util-service";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class RotinasService {

  url = environment.API_URL + "/rotina"

  constructor(
    public http: HttpClient,
    public util: HttpUtilService
  ) {
  }
  /**
   * Busca rotinas pelo usuario
   * @param id
   */
  getAllRotinasByUsuario(id: number): Observable<any> {
    return this.http.get(`${this.url}/membro/${id}/origem`, this.util.httpOptions())
  }

  /**
 * Busca rotinas pelo usuario logado
 * @param id
 */
  getAllRotinasByUsuarioLogado(): Observable<any> {
    return this.http.get(`${this.url}/membro`, this.util.httpOptions())
  }

  getAllRotinasPageable(model: any): Observable<any> {
    return this.http.post(`${this.url}/pageable`, model, this.util.httpOptions())
  }
}
