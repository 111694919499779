import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpUtilService {

  private url = environment.API_URL

  constructor(
    private http: HttpClient,
    private storage: StorageService,
  ) { }

  /**
   * Seta cabeçalho
   */
  httpOptionsMultipart() {
    return {
      headers: this.montaHeaders()
    }
  }

  getCabecalhos() {

    try {
      let retorno = {};

      if (!this.storage.read('cabecalhos')) {
        return retorno;
      }

      let cabecalhos = JSON.parse(this.storage.read('cabecalhos'))
      if (cabecalhos) {
        for (let key in cabecalhos) {
          if (cabecalhos[key]) {
            retorno[key] = cabecalhos[key];
          }
        }
      }

      return retorno

    } catch (e) {
      return {};
    }
  }

  montaHeaders() {
    let headers = {
      Authorization: "Bearer " + this.storage.read('token'),
      cidade: this.storage.read('cidade'),
      ...this.getCabecalhos(),
    };
    return new HttpHeaders(headers);
  }

  montaHeadersCidade(cidade) {
   let headers = {
     cidade: cidade,
     ...this.getCabecalhos(),
   };
   return new HttpHeaders(headers);
 }

  httpOptions() {
    return {
      headers: this.montaHeaders().set("Content-Type", "application/json")
    }
  }

  httpOptionsCidade(cidade) {
   return {
      headers: this.montaHeadersCidade(cidade).set("Content-Type", "application/json")
    }
  }

  httpOptionsTelemedicina() {
    let psf_id = this.storage.read('unidadeSelecionada');
    return {
      headers: this.montaHeaders().set("Content-Type", "application/json").set("psf_id", `${psf_id}`)
    }
  }

  httpOptionsWithoutApplicationJson() {
    return {
      headers: this.montaHeaders()
    }
  }

  getImagem(url): any {
    let headers = new HttpHeaders({ "Content-Type": "application/json" })
    return this.http.get(url, { headers: headers, responseType: 'blob', reportProgress: true, }).subscribe((_) => { })
  }

  /**
   * Busca latitude e longitude atraves de um enderço
   * @param endereco
   */
  getCoordenadas(endereco: string): Observable<any> {
    endereco = endereco.replace(" ", "+")
    let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${endereco}&key=${environment.API_KEY_GOOGLE_MAPS}`
    return this.http.get(url);
  }

  /**
   * Busca endereço atraves da latitude e longitude
   * @param lat
   * @param lng
   */
  getEndereco(lat: number, lng: number): Promise<any> {
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${environment.API_KEY_GOOGLE_MAPS}`
    return this.http.get(url)
      .toPromise()
      .then((data) => data)
  }

  uploadImage(fd: FormData): Promise<any> {
    return this.http.post(`${this.url}/storage/uploadfile`, fd, this.httpOptionsMultipart())
      .toPromise()
      .then((data) => data)
      .catch((err) => Promise.reject(err))
  }
}
