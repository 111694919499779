import { TransporteService } from './../transporte/transporte.service';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize, map, shareReplay } from 'rxjs/operators';
import { CategoriaService } from 'src/app/agendamentos/agendamento-servico-dinamico/services/categoria.service';
import { RotinasService } from '../acessos/rotinas/services/rotinas.service';
import { TelasAplicativoService } from '../acessos/telas-aplicativo/telas-aplicativo.service';
import { AgendamentoServicoService } from '../agendamentos/agendamento-servico/agendamento-servico.service';
import { CamaraAgendamentoServicoService } from '../agendamentos/agendamento-servico/categoria-agendamento-servico.service';
import { PermissoesCategoriasService } from '../agendamentos/agendamento-servico/permissoes-categoria/permissoes-categoria.service';
import { PermissoesCartaoDeVacinaService } from '../agendamentos/castracao/cartao-de-vacina/permissoes/permissoes-cartao-de-vacina.service';
import { CamaraService } from '../camara/camara.service';
import { VereadorService } from '../camara/vereador/vereador.service';
import { HistoricoNotificacaoPainelService } from '../historico-notificacao-painel/services/historico-notificacao-painel.service';
import { InformacoesService } from '../informacoes/informacoes.service';
import { InfraestruturaCategoriaService } from '../infraestrutura/infraestrutura-categoria/infraestrutura-categoria.service';
import { ListaEsperaPermissaoService } from '../lista-espera/permissao/lista-espera-permissao.service';
import { ModalidadeEsporteService } from '../modalidade_esporte/modalidade-esporte.service';
import { ProconProdutoCategoriaService } from '../procon/categorias-produto/procon-categorias-produto.service';
import { RelatoriosPermissoesService } from '../relatorios/relatorios-permissoes.service';
import { RelatoriosService } from '../relatorios/relatorios.service';
import { AuthService } from '../services/auth.service';
import { SocketService } from '../services/socket.service';
import { StorageService } from '../services/storage.service';
import { Categoria } from '../transporte/models/categoria';
import { TurismoService } from '../turismo/turismo.service';
import { Constants } from '../util/constants';
import { CamaraUtilidadePublicaService } from '../utilidade-publica/camara-utilidade-publica.service';
import { UtilidadePublicaService } from '../utilidade-publica/utilidade-publica.service';
import { WebviewService } from '../webview/webview.service';
import { AtualizarNavToolService } from './../acessos/atualizarNavTool.service';
import { NavItem } from './models/nav-item';
@Component({
	selector: 'app-navigation',
	templateUrl: './navigation.component.html',
	styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
	rotinas = [];
	categoriasProdutos: NavItem[] = [];
	categoriasAgendamentoServico: NavItem[] = [];
	categoriasAgendamentoServicoDinamico: NavItem[] = [];
	camaraCategoriasAgendamentoServico: NavItem[] = [];
	categoriasDocumento: NavItem[] = [];
	categoriasInformacao: NavItem[] = [];
	categoriasModalidadeEsportiva: NavItem[] = [];
	categoriasRelatorio: NavItem[] = [];
	categoriasTransporte: NavItem[] = [];
	categoriasTurismo: NavItem[] = [];
	categoriasUtilidadePublica: NavItem[] = [];
	camaraCategoriasUtilidadePublica: NavItem[] = [];
	categoriasWebview: NavItem[] = [];
	categoriasListaEspera: NavItem[] = [];
	categoriasInfraestrutura: NavItem[] = [];
	navItems: NavItem[] = [];
	vereador = undefined;
	vereadores = [];
	usuario: any;
	podeCadastrarVacina: any
	nomeCidade = '';
	loadingVereadores = false;
	loadingTrocaContextoParaVereador = false;
	@ViewChild('clickHoverMenuTrigger') clickHoverMenuTrigger: MatMenuTrigger;
	vereadorPerfilSelecionado: number | undefined;
	podeAcessarPerfilVereador$!: Observable<boolean>;

	permissoesCadastros = [
		'Agendamento Serviços',
		'Agendamento de Castração',
		'Bairros',
		'Campanhas',
		'Capa Ocorrência',
		'Capa Turismo',
		'Capa Utilidade',
		'Castração',
		'Comunicados',
		'Controle de Processo',
		'Controle de Processo Configuração',
		'Controle de Processo Dashboard',
		'Controle de Processo Etapa Configuração',
		'Controle de Processo Histórico Solicitações',
		'Controle de Processo Permissão',
		'Controle de Processo Sincronização',
		'Controle de Processo Novo Processo',
		'Cor Aplicativo',
		'Disponibilidade de Agendamento',
		'Empresa',
		'Espécie',
		'Eventos',
		'Formulário Dashboard',
		'Formulários',
		'Formulários Permissão',
		'Informações',
		'Instalação Esportiva - Agendamento',
		'Instalação Esportiva - Serviços',
		'Instruções de Castração',
		'Limpeza Urbana',
		'Lista de Documentos',
		'Meio Ambiente',
		'Modalidade Esportiva',
		'Médico Veterinário',
		'Notícias',
		'ONGs',
		'Ocorrências',
		'Onboarding',
		'Ordenação de Documentos',
		'Pesquisas',
		'Relatórios',
		'Relatórios Categorias',
		'Relatórios Permissões',
		'Rotinas',
		'Servidores',
		'Serviços',
		'Solicitação de Agendamento',
		'Permissão Solicitação de Agendamento',
		'Permissão QrCode Solicitação de Agendamento',
		'Sorteios',
		'SOS',
		'Tabelas',
		'Tela Login',
		'Telas do Aplicativo',
		'Tipo de Restrição',
		'Transporte',
		'Turismo',
		'Usuários',
		'Utilidade Pública',
		'Webview',
		'Whatsapp Configuração',
		'Categoria de Empresa',
		'Categoria de Produto',
		'Produto',
		'Variação de Preço',
		'Carteirinhas',
		'Carteirinhas Permissão',
		'Carteirinhas Categoria',
		'Carteirinhas Lista Presença',
		'Carteirinhas Lista Presença Permissão',
		'Carteirinhas Responsáveis',
		'Carteirinhas Solicitações Vínculos',
		'Demográficas e Serviços',
		'Demográficas e Serviços Consórcios',
		'Demográficas e Serviços Vínculos',
		'Demográficas e Serviços Consórcios Serviços',
		'Demográficas e Serviços Consórcios Permissões',
		'Rotativo Digital',
		'Rotativo Veículo',
		'Rotativo Veículo Tipo',
		'Rotativo Veículo Modelo',
		'Rotativo Veículo Marca',
		'Rotativo Veículo Cor',
		'Novo Controle Processo Criar',
		'Novo Controle Processo Solicitação',
		'Novo Controle Processo Solicitações',
	];

	permissoesCamara = [
		'Agendamento Serviços',
		'Capa Ocorrência',
		'Chamados Não Tramitados Em Aberto',
		'Chamados Não Tramitados Em Análise',
		'Chamados Não Tramitados Finalizados',
		'Chamados Tramitados Em Aberto',
		'Chamados Tramitados Em Análise',
		'Chamados Tramitados Finalizados',
		'Cor Aplicativo',
		'Dados da Câmara',
		'Enquetes',
		'Formulário Dashboard',
		'Formulários',
		'Lista de Documentos',
		'Lista de Vereadores',
		'Notícias',
		'Ocorrências',
		'Onboarding',
		'Partidos',
		'Processo Seletivo',
		'Projetos de Lei',
		'Rotinas',
		'Tela Login',
		'Telas do Aplicativo',
		'Usuários',
		'Whatsapp Configuração',
		'Carteirinhas',
		'Carteirinhas Permissão',
		'Carteirinhas Categoria',
		'Carteirinhas Lista Presença',
		'Carteirinhas Lista Presença Permissão',
		'Carteirinhas Responsáveis',
		'Carteirinhas Solicitações Vínculos',
		'Utilidade Pública',
		'Dashboard',
		'Mural'
	];
	cpfsAutorizadosTrocarContextoVereador = ['10917495667'];

	telaDiretorioCampanhas: boolean = false;

	isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
		map((result) => result.matches),
		shareReplay(),
	);

	qtdeNotificacoes: number = 0;

	nomeUtilidadePublica = 'Utilidade Pública';
	nomeTurismo = 'Turismo';

	constructor(
		private permissoesCartaoDeVacinaService: PermissoesCartaoDeVacinaService,
		private agendamentoServicoService: AgendamentoServicoService,
		private camaraAgendamentoServicoService: CamaraAgendamentoServicoService,
		private informacoesService: InformacoesService,
		private agendamentoServicoDinamicoCategoriaService: CategoriaService,
		private modalidadeEsporteService: ModalidadeEsporteService,
		private relatoriosService: RelatoriosService,
		private relatoriosPermissoesService: RelatoriosPermissoesService,
		private transporteService: TransporteService,
		private turismoService: TurismoService,
		private utilidadePublicaService: UtilidadePublicaService,
		private camaraUtilidadePublicaService: CamaraUtilidadePublicaService,
		private webviewService: WebviewService,
		private authService: AuthService,
		private rotinaService: RotinasService,
		private breakpointObserver: BreakpointObserver,
		private eventAtualizar: AtualizarNavToolService,
		private storage: StorageService,
		private proconProdutoCategoriaService: ProconProdutoCategoriaService,
		private listaEsperaPermissaoService: ListaEsperaPermissaoService,
		private infraestruturaCategoriaService: InfraestruturaCategoriaService,
		private historicoNotificacaoPainelService: HistoricoNotificacaoPainelService,
		private router: Router,
		private socketService: SocketService,
		private permService: PermissoesCategoriasService,
		private vereadorService: VereadorService,
		private camaraService: CamaraService,
		private telaAplicativoService: TelasAplicativoService,
	) {
		this.usuario = JSON.parse(this.storage.read('usuario')) || {};
		this.nomeCidade = this.storage.read('nome_cidade') || '';
		this.getRotinasUsuario();


		let dadosPerfilVereador = this.storage.read('vereadorPerfil');
		if (dadosPerfilVereador) {
			this.vereadorPerfilSelecionado = JSON.parse(dadosPerfilVereador)?.id;
		} else {
			this.vereadorPerfilSelecionado = undefined;
		}
		this.socketService.eventoRecebido.subscribe(() => {
			this.countNotificacoesVisualizadaIsFalse();
		});
		this.podeAcessarPerfilVereador$ = this.camaraService.findCamaraAtiva().pipe(map((camara) => {
			if (camara) {
				this.storage.save(Constants.CHAVE_CAMARA_LEGISLATIVA, JSON.stringify(camara));
			} else {
				this.storage.remove(Constants.CHAVE_CAMARA_LEGISLATIVA);
			}
			return camara != undefined && (this.storage.read("isAdmin") === 'true' || this.cpfsAutorizadosTrocarContextoVereador.includes(this.usuario.documento))
		}));
	}

	ngOnInit() {
		this.initEvents();
	}

	ngAfterViewInit() {
		this.resizeText({
			element: document.querySelector('.text'),
			parent: document.querySelector('.text-container'),
		});
	}

	/**
	 * Busca as rotinas do usuario
	 */
	getRotinasUsuario() {
		this.rotinaService
			.getAllRotinasByUsuarioLogado()
			.pipe(finalize(() => { }))
			.subscribe({
				next: (data: any) => {
					this.storage.remove('rotinas');
					// const rotinas = [];
					for (let item of data.rotinas) {
						if (item.rotina && item.rotina.nome) {
							this.rotinas.push({
								id: item.rotina.id,
								nome: item.rotina.nome,
								privilegio: item.privilegio,
								origem: item.origem
							})
						}
					}
					this.storage.save('rotinas', JSON.stringify(this.rotinas));

					this.buscaMenuAsync();
				},
			});
	}

	getNomesTelaAplicativo() {
		return new Promise((resolve, reject) => {
			this.telaAplicativoService.getAll()
				.then((data) => {
					let telaUtilidadePublica = data?.telas?.find(item => item?.link == '/start/public-utility/')
					let telaTurismo = data?.telas?.find(item => item?.link == '/start/tourism/')

					if (telaUtilidadePublica) {
						this.nomeUtilidadePublica = telaUtilidadePublica?.nome || 'Utilidade Pública'
					}

					if (telaTurismo) {
						this.nomeTurismo = telaTurismo?.nome || 'Turismo'
					}
				}).catch((_) => {
					resolve(true)
				}).finally(() => {
					resolve(true)
				})
		});
	}

	buscaMenuAsync() {
		let promises = [];
		promises.push(this.getAgendamentoServico());
		promises.push(this.getAgendamentoServicoDinamico());
		promises.push(this.getCamaraAgendamentoServico());
		promises.push(this.getCategoriasProdutos());
		promises.push(this.getCategoriaInformacoes());
		promises.push(this.getModalidadeEsportiva());
		promises.push(this.getCategoriasTransporte());
		promises.push(this.getCategoriasRelatorios());
		promises.push(this.getCategoriasTurismo());
		promises.push(this.getCategoriasUtilidadePublica());
		promises.push(this.getCamaraCategoriasUtilidadePublica());
		promises.push(this.getCategoriasWebview());
		promises.push(this.getListaEspera());
		promises.push(this.getCategoriasInfraestrutura());
		promises.push(this.countNotificacoesVisualizadaIsFalse());
		promises.push(this.getNomesTelaAplicativo());

		Promise.all(promises).then(() => {
			this.navItems = this.dfs_filter(this.setNavItems());
		});
	}

	setNavItems() {
		const menu: NavItem[] | any[] = [
			{
				displayName: 'Dashboard',
				iconName: 'fas fa-tachometer-alt',
				show: this.verificaRotina('Dashboard'),
				route: 'm/dashboard',
			},
			{
				displayName: 'Controle de Processos',
				iconName: 'fas fa-paste',
				show: true,
				children: [
					{
						displayName: 'Criar Processo',
						iconName: 'fas fa-plus',
						show: true,
						route: 'm/controle-processos-app-cidades/criar-processo',
					},
					{
						displayName: 'Permissões',
						iconName: 'fas fa-link',
						show: true,
						route: 'm/controle-processos-app-cidades/permissoes',
					},
					{
						displayName: 'Lista de Processos',
						iconName: 'fas fa-table-list',
						show: true,
						route: 'm/controle-processos-app-cidades/lista-processos',
					},
					{
						displayName: 'Formulários',
						iconName: 'fas fa-file-invoice',
						show: true,
						children: [
							// {
							// 	displayName: 'Dashboard',
							// 	iconName: '',
							// 	show: this.verificaRotina('Formulário Dashboard'),
							// 	route: 'm/formularios/dashboard',
							// },
							{
								displayName: 'Lista de formulários',
								iconName: '',
								show: true,
								route: 'm/controle-processos-app-cidades/formularios',
							},
							 {
							 	displayName: 'Permissões',
							 	iconName: '',
							 	show: true,
							 	route: 'm/controle-processos-app-cidades/formularios/permissoes',
							 },
						]
					},
					{
						displayName: 'Atividades',
						iconName: 'fa-solid fa-receipt',
						show: true,
						route: 'm/controle-processos-app-cidades/atividades',
					},
					{
						displayName: 'Histórico',
						iconName: 'fa-solid fa-file-waveform ',
						show: this.verificaRotina('Controle de Processo Histórico Solicitações'),
						route: 'm/controle-processos-app-cidades/historico-solicitacoes',
					},
					{
						displayName: 'Minhas Solicitações',
						iconName: 'fas fa-paste',
						show: true,
						route: 'm/controle-processos-app-cidades/minhas-solicitacoes',
					},
					{
						displayName: 'Solicitações',
						iconName: 'fas fa-paste',
						show: true,
						route: 'm/controle-processos-app-cidades/solicitacoes',
					},
				]
			},
			{
				// Cadastros
				displayName: 'Cadastros',
				iconName: 'fas fa-edit',
				show: this.verificaCadastros(),
				children: [
					{
						displayName: 'Bairros',
						iconName: 'fas fa-home',
						show: this.verificaRotina('Bairros'),
						route: 'm/bairros',
					},
					{
						displayName: 'Solicitação de Agendamento',
						iconName: 'far fa-calendar-alt',
						show: this.verificaListaRotinas(['Solicitação de Agendamento', 'Permissão Solicitação de Agendamento', 'Permissão QrCode Solicitação de Agendamento']),
						children: this.categoriasAgendamentoServicoDinamico,
					},
					{
						displayName: 'Agendamento de Serviços',
						iconName: 'far fa-calendar-alt',
						show: this.verificaRotina('Agendamento Serviços'),
						children: this.categoriasAgendamentoServico,
					},
					{
						displayName: 'Acessos',
						iconName: 'fas fa-user-tag',
						show: this.verificaListaRotinas([
							'Telas do Aplicativo',
							'Tela Login',
							'Cor Aplicativo',
							'Capa Turismo',
							'Capa Utilidade',
							'Capa Ocorrência',
							'Onboarding',
							'Serviços',
							'Matricula Servidor',
							'Rotinas',
							'Usuários',
						]),
						children: [
							{
								displayName: 'Parâmetros',
								iconName: 'fas fa-wrench',
								show: this.verificaListaRotinas([
									'Telas do Aplicativo',
									'Tela Login',
									'Cor Aplicativo',
									'Capa Turismo',
									'Capa Utilidade',
									'Capa Ocorrência',
									'Onboarding',
									'Serviços',
								]),
								children: [
									{
										displayName: 'Capas Ocorrência',
										iconName: '',
										show: this.verificaRotina('Capa Ocorrência'),
										route: 'm/parametros/capa-ocorrencia',
									},
									{
										displayName: 'Capas ' + this.nomeTurismo,
										iconName: '',
										show: this.verificaRotina('Capa Turismo'),
										route: 'm/parametros/capa-turismo',
									},
									{
										displayName: 'Capas ' + this.nomeUtilidadePublica,
										iconName: '',
										show: this.verificaRotina('Capa Utilidade'),
										route: 'm/parametros/capa-utilidade-publica',
									},
									{
										displayName: 'Cores Aplicativo',
										iconName: '',
										show: this.verificaRotina('Cor Aplicativo'),
										route: 'm/parametros/cor-aplicativo',
									},
									{
										displayName: 'Onboarding',
										iconName: '',
										show: this.verificaRotina('Onboarding'),
										route: 'm/parametros/onboarding',
									},
									{
										displayName: 'Tela de Login',
										iconName: '',
										show: this.verificaRotina('Tela Login'),
										route: 'm/parametros/tela-login',
									},
									{
										displayName: 'Telas do Aplicativo',
										iconName: '',
										show: this.verificaRotina('Telas do Aplicativo'),
										route: 'm/telas-aplicativo',
									},
								],
							},
							{
								displayName: 'Rotinas',
								iconName: '',
								show: this.verificaRotina('Rotinas'),
								route: 'm/rotinas',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Usuários'),
								route: 'm/usuarios',
							},
							{
								displayName: 'Lista de Usuários',
								iconName: '',
								show: this.verificaRotina('Usuários'),
								route: 'm/lista-usuarios',
							},
						],
					},
					{
						displayName: 'Campanhas',
						iconName: 'fas fa-hands-helping',
						show: this.verificaRotina('Campanhas'),
						route: 'm/campanhas',
					},
               {
						displayName: 'Carteira Digital',
						iconName: 'fas fa-credit-card',
						show: this.verificaListaRotinas(['Carteira Digital Servidores', 'Carteira Digital Comerciantes', 'Carteira Digital Configuração']),
						children: [
                     {
								displayName: 'Configuração',
								iconName: 'fas fa-gear',
								show: this.verificaRotina('Carteira Digital Configuração'),
								route: 'm/carteira-digital/configuracao',
							},
							{
								displayName: 'Servidores',
								iconName: 'fas fa-users',
								show: this.verificaRotina('Carteira Digital Servidores'),
								route: 'm/carteira-digital/servidores',
							},
							{
								displayName: 'Comerciantes',
								iconName: 'fas fa-shop',
								show: this.verificaRotina('Carteira Digital Comerciantes'),
								route: 'm/carteira-digital/comerciantes',
							},
						],
					},
					{
						displayName: 'Carteirinhas',
						iconName: 'fa-solid fa-image',
						show: this.verificaListaRotinas([
							'Carteirinhas',
							'Carteirinhas Permissão',
							'Carteirinhas Categoria',
							'Carteirinhas Lista Presença',
							'Carteirinhas Lista Presença Permissão',
							'Carteirinhas Responsáveis',
							'Carteirinhas Solicitações Vínculos',
						]),
						children: [
							{
								displayName: 'Templates',
								iconName: '',
								show: this.verificaRotina('Carteirinhas Categoria'),
								route: 'm/carteirinhas/templates',
							},
							{
								displayName: 'Categorias',
								iconName: '',
								show: this.verificaRotina('Carteirinhas Categoria'),
								route: 'm/carteirinhas/categorias',
							},
							{
								displayName: 'Lista de carteirinhas',
								iconName: '',
								show: this.verificaRotina('Carteirinhas'),
								route: 'm/carteirinhas',
							},
							{
								displayName: 'Lista Presença',
								iconName: '',
								show: this.verificaRotina('Carteirinhas Lista Presença'),
								route: 'm/carteirinhas/lista-presenca',
							},
							{
								displayName: 'Lista Presença Permissão',
								iconName: '',
								show: this.verificaRotina('Carteirinhas Lista Presença Permissão'),
								route: 'm/carteirinhas/lista-presenca-permissao',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Carteirinhas Permissão'),
								route: 'm/carteirinhas/permissoes',
							},
							{
								displayName: 'Vínculo',
								iconName: '',
								show: this.verificaListaRotinas(['Carteirinhas Responsáveis', 'Carteirinhas Solicitações Vínculos']),
								children: [
									{
										displayName: 'Responsáveis',
										iconName: '',
										show: this.verificaRotina('Carteirinhas Responsáveis'),
										route: 'm/carteirinhas/responsaveis',
									},
									{
										displayName: 'Solicitação de Vínculo',
										iconName: '',
										show: this.verificaRotina('Carteirinhas Solicitações Vínculos'),
										route: 'm/carteirinhas/solicitacoes-vinculo',
									},
								],
							},
						],
					},
					{
						displayName: 'Castração de Animais',
						show: this.verificaListaRotinas([
							'Agendamento de Castração',
							'Castração',
							'Disponibilidade de Agendamento',
							'Espécie',
							'Médico Veterinário',
							'ONGs',
							'Tipo de Restrição',
						]),
						iconName: 'fas fa-paw',
						children: [
							{
								displayName: 'Configuração',
								iconName: '',
								route: 'm/castracao/configuracao',
							},
							{
								displayName: 'Agendamento',
								iconName: '',
								show: this.verificaRotina('Agendamento de Castração'),
								route: 'm/castracao/agendamento',
							},
							{
								displayName: 'Castração',
								iconName: '',
								show: this.verificaRotina('Castração'),
								route: 'm/castracao',
							},
							{
								displayName: 'Disponibilidade de Agendamento',
								iconName: '',
								show: this.verificaRotina('Disponibilidade de Agendamento'),
								route: 'm/castracao/disponibilidade',
							},
							{
								displayName: 'Espécie',
								iconName: '',
								show: this.verificaRotina('Espécie'),
								route: 'm/castracao/especie-animal',
							},
							{
								displayName: 'Instruções de Castração',
								iconName: '',
								show: this.verificaRotina('Instruções de Castração'),
								route: 'm/castracao/instrucoes-castracao',
							},
							{
								displayName: 'Médico Veterinário',
								iconName: '',
								show: this.verificaRotina('Médico Veterinário'),
								route: 'm/castracao/medico-veterinario',
							},
							{
								displayName: 'ONGs',
								iconName: '',
								show: this.verificaRotina('ONGs'),
								route: 'm/pessoa-juridica',
							},
							{
								displayName: 'Protetores Individuais',
								iconName: '',
								route: 'm/castracao/protetores-individuais',
							},
							{
								displayName: 'Tipo de Restrição',
								iconName: '',
								show: this.verificaRotina('Tipo de Restrição'),
								route: 'm/tipo-restricao',
							},
							{
								displayName: 'Cartão de Vacina',
								iconName: '',
								show: this.verificaRotina('Cartão de Vacina'),
								children: [
									{
										displayName: 'Permissões',
										iconName: '',
										show: this.verificaRotina('Cartão de Vacina Permissões'),
										route: 'm/castracao/cartao-de-vacina/permissoes',
									},
									{
										displayName: 'Vacínas',
										iconName: '',
										show: this.podeCadastrarVacina,
										route: 'm/castracao/cartao-de-vacina/vacinas',
									},
									{
										displayName: 'Listagem de Cartões',
										iconName: '',
										route: 'm/castracao/cartao-de-vacina',
									},
								]
							}
						],
					},
					{
						displayName: 'Instalações Esportiva',
						iconName: 'fas fa-football-ball',
						show: this.verificaListaRotinas(['Instalação Esportiva - Agendamento', 'Instalação Esportiva - Serviços']),
						children: [
							{
								displayName: 'Agendamento',
								iconName: '',
								show: this.verificaRotina('Instalação Esportiva - Agendamento'),
								route: 'm/instalacao-esportiva/agendamento',
							},
							{
								displayName: 'Serviços',
								iconName: '',
								show: this.verificaRotina('Instalação Esportiva - Serviços'),
								route: 'm/instalacao-esportiva',
							},
						],
					},
					{
						displayName: 'Comunicados',
						iconName: 'fas fa-envelope',
						show: this.verificaRotina('Comunicados'),
						children: [
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Comunicados Permissão'),
								route: 'm/mensagens/permissoes',
							},
							{
								displayName: 'Internos',
								iconName: '',
								children: [
									{
										displayName: 'Mensagens Recebidas',
										iconName: '',
										show: true,
										route: 'm/mensagens/detalhes/recebidas',
									},
									{
										displayName: 'Mensagens Enviadas',
										iconName: '',
										show: true,
										route: 'm/mensagens/detalhes/enviadas',
									},
									{
										displayName: 'Grupos',
										iconName: '',
										show: true,
										route: 'm/mensagens/grupo-usuarios',
									},
								],
							},
							{
								displayName: 'Externos',
								iconName: '',
								children: [
									{
										displayName: 'Mensagens Recebidas',
										iconName: '',
										show: true,
										route: 'm/mensagens-externas/detalhes/recebidas',
									},
									{
										displayName: 'Mensagens Enviadas',
										iconName: '',
										show: true,
										route: 'm/mensagens-externas/detalhes/enviadas',
									},
									{
										displayName: 'Grupos',
										iconName: '',
										show: true,
										route: 'm/mensagens-externas/grupo-usuarios-externos',
									},
								],
							},
						],
					},
					{
						displayName: 'Configurações',
						iconName: 'fas fa-link',
						show: this.verificaListaRotinas(['Configurações aplicativo']),
						route: 'm/config-app',
					},
					{
						displayName: 'Controle de Processos',
						iconName: 'fas fa-paste',
						show: this.verificaListaRotinas([
							'Controle de Processo',
							'Controle de Processo Permissão',
							'Controle de Processo Etapa Configuração',
							'Controle de Processo Configuração',
							'Controle de Processo Histórico Solicitações',
							'Controle de Processo Dashboard',
							'Controle de Processo Sincronização',
							
							'Controle de Processo Minhas Solicitações'
						]),
						children: [
							{
								displayName: 'Atividades',
								iconName: '',
								show: this.verificaRotina('Controle de Processo'),
								route: 'm/controle-processos/minhas-atividades',
							},
							{
								displayName: 'Configurações etapas',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Etapa Configuração'),
								route: 'm/controle-processos/processos/etapas/configuracoes',
							},
							{
								displayName: 'Configurações processos',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Configuração'),
								route: 'm/controle-processos/processos/configuracoes',
							},
							{
								displayName: 'Dashboard',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Dashboard'),
								route: 'm/controle-processos/dashboard',
							},
							{
								displayName: 'Histórico',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Histórico Solicitações'),
								route: 'm/controle-processos/solicitacoes/historico',
							},
							{
								displayName: 'Minhas solicitações',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Minhas Solicitações'),
								route: 'm/controle-processos/minhas-solicitacoes',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Permissão'),
								route: 'm/controle-processos/processos/permissoes',
							},
							{
								displayName: 'Sincronização',
								iconName: '',
								show: this.verificaRotina('Controle de Processo Sincronização'),
								route: 'm/controle-processos/sincronizacao',
							},
							
							{
								displayName: 'Solicitações',
								iconName: '',
								show: this.verificaRotina('Controle de Processo'),
								route: 'm/controle-processos/solicitacoes',
							},
						],
					},
					{
						displayName: 'Currículos',
						iconName: 'far fa-file-alt',
						show: this.verificaListaRotinas(['Curriculo']),
						children: [
							{
								displayName: 'Lista de currículos',
								iconName: '',
								show: this.verificaRotina('Curriculo'),
								route: 'm/curriculos/curriculos-lista',
							},
							{
								displayName: 'Configuração de currículos',
								iconName: '',
								show: this.verificaRotina('Curriculo'),
								route: 'm/curriculos/curriculos-config',
							},
						],
					},
					{
						displayName: 'Dashboard',
						iconName: 'fas fa-tachometer-alt',
						show: this.verificaRotinaAndOrigem('Dashboard Permissões', 'PREFEITURA'),
						children: [
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Dashboard Permissões', 'PREFEITURA'),
								route: 'm/dashboard/permissoes',
							},
						],
					},
					{
						displayName: 'Documentos',
						iconName: 'far fa-file-alt',
						show: this.verificaListaRotinas(['Lista de Documentos', 'Ordenação de Documentos']),
						children: [
							{
								displayName: 'Lista de Documentos',
								iconName: '',
								show: this.verificaRotina('Lista de Documentos'),
								route: 'm/documentos',
							},
							{
								displayName: 'Ordenação de Documentos',
								iconName: '',
								show: this.verificaRotina('Ordenação de Documentos'),
								route: 'm/documentos/ordenacao',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Permissões Documentos'),
								route: 'm/documentos/permissoes',
							},
						],
					},
					{
						displayName: 'Demográficas e Serviços',
						iconName: 'far fa-map',
						show: this.verificaListaRotinas([
							'Demográficas e Serviços',
							'Demográficas e Serviços Consórcios',
							'Demográficas e Serviços Vínculos',
							'Demográficas e Serviços Consórcios Serviços',
							'Demográficas e Serviços Consórcios Permissões',
						]),
						children: [
							{
								displayName: 'Consórcios',
								iconName: '',
								show: this.verificaRotina('Demográficas e Serviços Consórcios'),
								route: 'm/demograficas-servicos/consorcios',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Demográficas e Serviços Consórcios Permissões'),
								route: 'm/demograficas-servicos/consorcios-permissoes',
							},
							{
								displayName: 'Serviços',
								iconName: '',
								show: this.verificaRotina('Demográficas e Serviços Consórcios Serviços'),
								route: 'm/demograficas-servicos/consorcios-servicos',
							},
							{
								displayName: 'Vínculos',
								iconName: '',
								show: this.verificaRotina('Demográficas e Serviços Vínculos'),
								route: 'm/demograficas-servicos/vinculos-municipio-servico',
							},
						],
					},
               {
                  displayName: 'Entrevistas',
                  iconName: 'fas fa-handshake',
						show: this.verificaListaRotinas(['Configuração Entrevistas','Dashboard Entrevistas', 'Empresas Entrevistas', 'Candidatos Entrevistas', 'Minhas Entrevistas', 'Relatório Entrevistas']),
                  children: [
                     {
								displayName: 'Configuracao',
								iconName: 'fas fa-gear',
								show: this.verificaRotina('Configuração Entrevistas'),
								route: 'm/entrevistas/configuracao',
							},
                     {
								displayName: 'Dashboard',
								iconName: 'fas fa-tachometer-alt',
								show: this.verificaRotina('Dashboard Entrevistas'),
								route: 'm/entrevistas/dashboard',
							},
                     {
								displayName: 'Empresas',
								iconName: 'fas fa-building',
								show: this.verificaRotina('Empresas Entrevistas'),
								route: 'm/entrevistas/empresas',
							},
                     {
								displayName: 'Candidatos',
								iconName: 'fas fa-people-group',
								show: this.verificaRotina('Candidatos Entrevistas'),
								route: 'm/entrevistas/candidatos',
							},
                     {
								displayName: 'Minhas Entrevistas',
								iconName: 'fas fa-clipboard-question',
								show: this.verificaRotina('Minhas Entrevistas'),
								route: 'm/entrevistas/minhas-entrevistas',
							},
                     {
								displayName: 'Relatórios',
								iconName: 'fas fa-file-lines',
								show: this.verificaRotina('Relatório Entrevistas'),
								route: 'm/entrevistas/relatorios',
							},
                     {
								displayName: 'Vagas de emprego',
								iconName: 'fas fa-briefcase',
                        show: this.verificaListaRotinas(['Anunciar Vaga Entrevistas', 'Solicitações de Vagas Entrevistas']),
								children: [
                           {
                              displayName: 'Minhas Vagas',
                              iconName: 'fas fa-bullhorn',
                              show: this.verificaRotina('Anunciar Vaga Entrevistas'),
                              route: 'm/entrevistas/minhas-vagas',
                           },
                           {
                              displayName: 'Solicitações de Vagas',
                              iconName: 'fas fa-clipboard-check',
                              show: this.verificaRotina('Solicitacoes de Vagas Entrevistas'),
                              route: 'm/entrevistas/solicitacoes-vagas',
                           },
                        ]
							},
                  ]
               },
					{
						displayName: 'Eventos',
						iconName: 'fas fa-calendar-week',
						show: this.verificaRotina('Eventos'),
						children: [
							{
								displayName: 'Categorias',
								iconName: '',
								show: true,
								route: 'm/eventos/categorias',
							},
							{
								displayName: 'Eventos',
								iconName: '',
								show: true,
								route: 'm/eventos',
							},
						],
					},
					{
						displayName: 'Empresas',
						iconName: 'fas fa-building',
						show: this.verificaRotina('Empresa'),
						route: 'm/empresa',
					},
					{
						displayName: 'Formulários',
						iconName: 'fas fa-file-invoice',
						show: this.verificaListaRotinas(['Formulários', 'Formulários Permissão']),
						children: [
							{
								displayName: 'Dashboard',
								iconName: '',
								show: this.verificaRotina('Formulário Dashboard'),
								route: 'm/formularios/dashboard',
							},
							{
								displayName: 'Lista de formulários',
								iconName: '',
								show: this.verificaRotina('Formulários'),
								route: 'm/formularios',
							},
							{
								displayName: 'Vínculo de Formulários',
								iconName: '',
								show: this.verificaRotina('Formulários Vínculos'),
								route: 'm/formularios/vinculos',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Formulários Permissão'),
								route: 'm/formularios/permissoes',
							},
						],
					},
					{
						displayName: 'Informações',
						iconName: 'fas fa-info-circle',
						show: this.verificaRotina('Informações'),
						children: this.categoriasInformacao,
					},
					{
						displayName: 'Meio Ambiente',
						iconName: 'fas fa-tree',
						show: this.verificaRotina('Meio Ambiente'),
						children: [
							{
								displayName: 'Tipo de Resíduo',
								iconName: '',
								show: true,
								route: 'm/meio-ambiente/tipo-de-residuo',
							},
							{
								displayName: 'Pontos de Coleta',
								iconName: '',
								show: true,
								route: 'm/meio-ambiente/ponto-de-coleta',
							},
						],
					},
					{
						displayName: 'Tabelas',
						iconName: 'fas fa-table',
						show: this.verificaRotina('Tabelas'),
						children: this.categoriasListaEspera,
					},
					{
						displayName: 'Telemedicina',
						iconName: 'fas fa-notes-medical',
						show: this.verificaRotina('Painel Telemedicina'),
						children: [
							{
								displayName: 'Profissionais das Unidades',
								iconName: '',
								show: true,
								route: 'm/telemedicina/profissional',
							},
							{
								displayName: 'UBS',
								iconName: '',
								show: true,
								route: 'm/telemedicina/ubs',
							},
						],
					},
					{
						displayName: 'Modalidade Esportiva',
						iconName: 'fas fa-volleyball-ball',
						show: this.verificaRotina('Modalidade Esportiva'),
						children: this.categoriasModalidadeEsportiva,
					},
					{
						displayName: 'Notícias',
						iconName: 'fas fa-newspaper',
						show: this.verificaRotina('Notícias'),
						route: 'm/noticias',
					},
					{
						displayName: 'Ocorrências',
						iconName: 'fas fa-exclamation-circle',
						show: this.verificaRotina('Ocorrências'),
						children: [
							{
								displayName: 'Assuntos',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/secretarias-assuntos',
							},
							{
								displayName: 'Configurações',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/configuracoes',
							},
							{
								displayName: 'Documentos Finalização',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/documento-finalizacao',
							},
							{
								displayName: 'Respostas Pré-Cadastradas',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/respostas-pre-cadastradas',
							},
							{
								displayName: 'Prioridades',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/prioridades',
							},
							{
								displayName: 'Secretarias',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/secretarias',
							},
							{
								displayName: 'Tipo de Comentário',
								iconName: '',
								show: true,
								route: 'm/ocorrencias/tipo-comentario',
							},
						],
					},
					{
						displayName: 'Pesquisas',
						iconName: 'fas fa-search',
						show: this.verificaRotina('Pesquisas'),
						route: 'm/pesquisas',
					},
					{
						displayName: 'Procon',
						iconName: 'fas fa-bullhorn',
						show: this.verificaListaRotinas(['Categoria de Empresa', 'Categoria de Produto', 'Produto', 'Variação de Preço']),
						children: this.categoriasProdutos,
					},
					{
						displayName: 'Relatórios',
						iconName: 'fas fa-chart-area',
						show: this.verificaRotina('Relatórios'),
						children: [
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotina('Relatórios Permissões'),
								route: '/m/relatorios/permissoes',
							},
							{
								displayName: 'Categorias',
								iconName: '',
								show: this.verificaRotina('Relatórios Categorias'),
								route: '/m/relatorios/categorias',
							},
							...this.categoriasRelatorio
						],
					},
					{
						displayName: 'Servidores',
						iconName: 'fas fa-user',
						show: this.verificaRotina('Servidores'),
						children: [
							{
								displayName: 'Importação de matrículas',
								iconName: '',
								show: this.verificaRotina('Servidores'),
								route: 'm/servidores/importacao-matriculas',
							},
							{
								displayName: 'Matrículas',
								iconName: '',
								show: this.verificaRotina('Servidores'),
								route: 'm/servidores/matriculas',
							},
							{
								displayName: 'Secretarias',
								iconName: '',
								show: this.verificaRotina('Servidores'),
								route: 'm/servidores/secretarias',
							},
							{
								displayName: 'Vínculos secretaria',
								iconName: '',
								show: this.verificaRotina('Servidores'),
								route: 'm/servidores/vinculos-cidadaos',
							},
						],
					},
					{
						displayName: 'Sorteios',
						iconName: 'fas fa-dice',
						show: this.verificaRotina('Sorteios'),
						children: [
							{
								displayName: 'Sorteio',
								iconName: '',
								show: this.verificaRotina('Sorteios'),
								route: 'm/sorteios',
							},
							{
								displayName: 'Sorteio formulário',
								iconName: '',
								show: this.verificaRotina('Sorteios'),
								route: 'm/sorteiosv2',
							},
						],
					},
					{
						displayName: 'SOS',
						iconName: 'fa-solid fa-land-mine-on',
						show: this.verificaRotina('SOS'),
						children: [
							{
								displayName: 'SOS Permissões',
								iconName: '',
								show: this.verificaRotina('SOS Permissões')
									&& this.verificaRotinaAndOrigem('SOS Permissões', 'SOS'),
								route: 'm/chamado-sos/usuarios',
							},
							{
								displayName: 'SOS Empresas',
								iconName: 'fa-solid fa-land-mine-on',
								show: this.verificaRotinaAndOrigem('SOS Empresas Configurações', 'SOS')
									|| this.verificaRotinaAndOrigem('SOS Empresas Chamados SOS', 'SOS'),
								children: [
									{
										displayName: 'Categoria Empresa',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Empresas Configurações', 'SOS'),
										route: 'm/procon/empresa-categoria',
									},
									{
										displayName: 'Empresas',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Empresas Configurações', 'SOS'),
										route: 'm/empresa',
									},
									{
										displayName: 'Matrículas',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Empresas Configurações', 'SOS'),
										route: 'm/servidores/matriculas',
									},
									{
										displayName: 'Chamados SOS',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Empresas Chamados SOS', 'SOS'),
										route: 'm/chamado-sos/tipo-chamado/empresa',
									},
								],
							},
							{
								displayName: 'SOS Cidadão',
								iconName: 'fa-solid fa-building-shield',
								show: this.verificaRotinaAndOrigem('SOS Cidadão Configurações', 'SOS')
									|| this.verificaRotinaAndOrigem('SOS Cidadão Chamados SOS', 'SOS'),
								children: [
									{
										displayName: 'Categoria Cidadão',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Cidadão Configurações', 'SOS'),
										route: 'm/chamado-sos/cidadao-categoria',
									},
									{
										displayName: 'Cidadãos',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Cidadão Configurações', 'SOS'),
										route: 'm/chamado-sos/cidadaos',
									},
									{
										displayName: 'Chamados SOS',
										iconName: '',
										show: this.verificaRotinaAndOrigem('SOS Cidadão Chamados SOS', 'SOS'),
										route: 'm/chamado-sos/tipo-chamado/cidadao',
									}
								]
							},
						]
					},
					{
						displayName: 'Limpeza Urbana',
						iconName: 'fas fa-dumpster',
						show: this.verificaRotina('Limpeza Urbana'),
						route: 'm/limpeza-urbana',
					},
					{
						displayName: 'Livraria',
						iconName: 'fa-solid fa-book',
						show: this.verificaListaRotinas(['Livraria Configuração', 'Livraria Listagem', 'Livraria Pedidos']),
						children: [
							{
								displayName: 'Configuração',
								iconName: 'fa-solid fa-gear',
								show: this.verificaRotinaAndOrigem('Livraria Configuração', 'PREFEITURA'),
								route: 'm/livraria/configuracao',
							},
							{
								displayName: 'Locação',
								iconName: 'fa-solid fa-calendar-check',
								show: this.verificaRotinaAndOrigem('Livraria Locação', 'PREFEITURA'),
								route: 'm/livraria/locacao',
							},
							{
								displayName: 'Listagem',
								iconName: 'fa-solid fa-list',
								show: this.verificaRotinaAndOrigem('Livraria Listagem', 'PREFEITURA'),
								route: 'm/livraria/listagem',
							}
						]
					},
					{
						displayName: 'Transporte',
						iconName: 'fas fa-bus-alt',
						show: this.verificaListaRotinas(['Transporte', 'Transporte Comunicado']),
						children: this.categoriasTransporte,
					},
					{
						displayName: this.nomeTurismo,
						iconName: 'fas fa-plane',
						show: this.verificaRotina('Turismo'),
						children: this.categoriasTurismo,
					},
					{
						displayName: this.nomeUtilidadePublica,
						iconName: 'fas fa-wrench',
						show: this.verificaRotinaAndOrigem('Utilidade Pública', 'PREFEITURA'),
						children: this.categoriasUtilidadePublica,
					},
					{
						displayName: 'Webview',
						iconName: 'fas fa-globe',
						show: this.verificaRotina('Webview'),
						children: this.categoriasWebview,
					},
					{
						displayName: 'Whatsapp',
						iconName: 'fas fa-phone',
						show: this.verificaRotina('Whatsapp Configuração'),
						route: 'm/whatsapp',
					},
					{
						displayName: 'Zoneamento Escolar',
						iconName: 'fa-solid fa-map',
						show: this.verificaListaRotinasAndOrigem(['Zoneamento Escolar Listagem', 'Zoneamento Escolar Escolas'], 'PREFEITURA'),
						children: [
							{
								displayName: 'Escolas',
								iconName: 'fa-solid fa-school',
								show: this.verificaRotinaAndOrigem('Zoneamento Escolar Escolas', 'PREFEITURA'),
								route: 'm/zoneamento-escolar/escolas',
							}, {
								displayName: 'Listagem',
								iconName: 'fa-solid fa-list',
								show: this.verificaRotinaAndOrigem('Zoneamento Escolar Listagem', 'PREFEITURA'),
								route: 'm/zoneamento-escolar/listagem',
							},
						]
					},
					{
						displayName: 'Certificados',
						iconName: 'fa fa-certificate',
						show: this.verificaListaRotinas(['Certificados']),
						children: [
							{
								displayName: 'Templates',
								iconName: '',
								show: this.verificaRotina('Certificados'),
								route: 'm/certificados/templates',
							},
							{
								displayName: 'Lista de Certificados',
								iconName: '',
								show: this.verificaRotina('Certificados'),
								route: 'm/certificados',
							},
						],
					},
					{
						displayName: 'Ingressos',
						iconName: 'fa-solid fa-ticket',
						show: this.verificaListaRotinas(['Ingressos']),
						children: [
							{
								displayName: 'Lista de Eventos',
								show: this.verificaRotina('Ingressos'),
								route: 'm/ingressos',
							},
							{
								displayName: 'Permissão',
								show: this.verificaRotina('Ingressos') && this.verificaRotina('Ingressos Permissões'),
								route: 'm/ingressos/permissao',
							},
							{
								displayName: 'Lista Presença',
								show: this.verificaRotina('Ingressos') && this.verificaRotina('Ingressos Lista Presença'),
								route: 'm/ingressos/lista-presenca',
							},
							{
								displayName: 'Lista Presença Permissão',
								show: this.verificaRotina('Ingressos') && this.verificaRotina('Ingressos Lista Presença Permissão'),
								route: 'm/ingressos/lista-presenca-permissao',
							},
						],
					}

				].sort((a, b) => a.displayName.localeCompare(b.displayName)),
			},
			{
				// Diario de Bordo
				displayName: 'Diário de Bordo',
				iconName: 'fas fa-book-reader',
				show: this.verificaRotina('Diário de bordo'),
				children: [
					{
						displayName: '1. Setor',
						iconName: 'fas fa-building',
						show: true,
						route: 'm/diario-setor',
					},
					{
						displayName: '2. Carros',
						iconName: 'fas fa-car',
						children: [
							{
								displayName: '1. Tipos',
								iconName: 'fas fa-truck-pickup',
								show: true,
								route: 'm/carro/tipos',
							},
							{
								displayName: '2. Carros',
								iconName: 'fas fa-car-side',
								show: true,
								route: 'm/carro',
							},
						],
					},
					{
						displayName: '3. Motoristas',
						iconName: 'far fa-id-card',
						show: true,
						route: 'm/motoristas',
					},
					{
						displayName: '4. Relatório',
						iconName: 'fas fa-file',
						show: true,
						route: 'm/diario-de-bordo',
					},
					{
						displayName: 'Rastreamento RANOR',
						iconName: 'fas fa-map',
						show: this.verificaRotina('Rastreamento RANOR'),
						children: [
							{
								displayName: 'Rastreamento',
								iconName: 'fas fa-file',
								show: this.verificaRotina('Rastreamento RANOR'),
								route: 'm/diario-rastreamento-ranor',
							},
							{
								displayName: 'Carros Rastreáveis',
								iconName: 'fas fa-car-side',
								show: this.verificaRotina('Rastreamento RANOR'),
								route: 'm/diario-carros-rastreaveis',
							},
						],
					},
				],
			},
			{
				// Lista de Ocorrencias
				displayName: 'Lista de Ocorrências',
				iconName: 'fas fa-exclamation-circle',
				show: this.verificaListaRotinas([
					'Chamados Não Tramitados Em Aberto',
					'Chamados Não Tramitados Em Análise',
					'Chamados Não Tramitados Finalizados',
					'Chamados Tramitados Em Aberto',
					'Chamados Tramitados Em Análise',
					'Chamados Tramitados Finalizados',
				]),
				route: 'm/ocorrencias',
			},
			{
				// Patos Premia
				displayName: 'Patos Premia',
				iconName: 'fas fa-gift',
				show: this.verificaRotina('Patos Premia'),
				children: [
					{
						displayName: 'Dashboard',
						iconName: '',
						show: true,
						route: 'm/patos-premia/dashboard',
					},
					{
						displayName: 'Relatórios',
						iconName: '',
						show: true,
						children: [
							{
								displayName: 'Bilhetes',
								iconName: '',
								show: true,
								route: 'm/patos-premia/bilhetes',
							},
							{
								displayName: 'Notas de Serviço',
								iconName: '',
								show: true,
								route: 'm/patos-premia/notas',
							},
							// {
							// 	displayName: 'Imóveis optados por carnê',
							// 	iconName: '',
							// 	show: true,
							// 	route: 'm/patos-premia/imoveis-carne',
							// },
						],
					},
					{
						displayName: 'Validações',
						iconName: '',
						show: true,
						children: [
							{
								displayName: 'Validação MEI',
								iconName: '',
								show: true,
								route: 'm/patos-premia/mei',
							},
							{
								displayName: 'Validação Sorteio',
								iconName: '',
								show: true,
								route: 'm/patos-premia/validacao/sorteio',
							},
						],
					},
					{
						displayName: 'Operações',
						iconName: '',
						show: true,
						children: [
							{
								displayName: 'Restrição',
								iconName: '',
								show: true,
								route: 'm/patos-premia/restricao',
							},
							{
								displayName: 'Sorteios',
								iconName: '',
								show: true,
								route: 'm/patos-premia/sorteios',
							},
							{
								displayName: 'Cancelamentos',
								iconName: '',
								show: true,
								route: 'm/patos-premia/cancelamentos',
							},
						],
					},
					{
						displayName: 'Parâmetros',
						iconName: '',
						children: [
							// {
							// 	displayName: 'Carnê Virtual',
							// 	iconName: '',
							// 	show: true,
							// 	route: 'm/patos-premia/parametros/carne-virtual',
							// },
							// {
							// 	displayName: 'Desconto IPTU',
							// 	iconName: '',
							// 	show: true,
							// 	route: 'm/patos-premia/parametros/desconto-iptu',
							// },
							{
								displayName: 'Validade Bilhete',
								iconName: '',
								show: true,
								route: 'm/patos-premia/parametros/validade-bilhete',
							},
							{
								displayName: 'Validação Notas',
								iconName: '',
								show: true,
								route: 'm/patos-premia/parametros/validacao-notas',
							},
							{
								displayName: 'Data Corte IPTU',
								iconName: '',
								show: true,
								route: 'm/patos-premia/parametros/data-corte-iptu',
							},
							{
								displayName: 'Geração de Bilhetes',
								iconName: '',
								show: true,
								route: 'm/patos-premia/parametros/pontuacao',
							},
							// {
							// 	displayName: 'Conversão',
							// 	iconName: '',
							// 	show: true,
							// 	route: 'm/patos-premia/parametros/conversao',
							// },
							{
								displayName: 'Restrição',
								iconName: '',
								show: true,
								route: 'm/patos-premia/parametros/restricao',
							},
							{
								displayName: 'Termo de compromisso',
								iconName: '',
								show: true,
								route: 'm/patos-premia/termo',
							},
						],
					},
				],
			},
			{
				// Gestão Escolar
				displayName: 'Gestão Escolar',
				iconName: 'fa fa-graduation-cap',
				show: this.verificaRotina('Gestão Escolar'),
				children: [
					{
						displayName: 'Boletim Escolar',
						iconName: 'fas fa-newspaper',
						show: true,
						children: [
							{
								displayName: 'Secretaria',
								iconName: 'fa-solid fa-laptop-file',
								show: this.verificaRotina('Gestão Escolar') && this.verificaRotina('Gestão Escolar Secretaria'),
								children: [
									{
										displayName: 'Conceito',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/conceito',
									},
									{
										displayName: 'Legenda',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/legenda',
									},
									{
										displayName: 'Período',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/periodo',
									},
									{
										displayName: 'Categoria de Disciplina',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/categoria-disciplina',
									},
									{
										displayName: 'Disciplina',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/disciplina',
									},
									{
										displayName: 'Processo de Formação',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/processo-formacao',
									},
									{
										displayName: 'Boletim',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/historico/escola/boletim',
									},
								]
							},
							{
								displayName: 'Geral',
								iconName: 'fa-solid fa-school',
								show: this.verificaRotina('Gestão Escolar'),
								children: [
									{
										displayName: 'Professor',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/professor',
									},
									{
										displayName: 'Estudante',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/estudante',
									},
									{
										displayName: 'Escola Permissões',
										iconName: '',
										show: this.verificaRotina('Gestão Escolar Permissões'),
										route: 'm/boletim-escolar/escola/permissao',
									},
									{
										displayName: 'Escola',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/escola',
									},
									{
										displayName: 'Turma Permissões',
										iconName: '',
										show: this.verificaRotina('Gestão Escolar Permissões'),
										route: 'm/boletim-escolar/turma/permissao',
									},
									{
										displayName: 'Turma',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/turma',
									},
									{
										displayName: 'Histórico',
										iconName: '',
										show: true,
										route: 'm/boletim-escolar/historico/escola',
									},
								]
							},
						],
					},
					{
						displayName: 'Vínculo',
						iconName: 'fas fa-link',
						show: true,
						children: [
							{
								displayName: 'Solicitação de Vínculo',
								iconName: '',
								show: true,
								route: 'm/vinculo-escolar/todas-solicitacoes',
							},
						],
					},
				],
			},
			{
				// Saude
				displayName: 'Saúde',
				iconName: 'fas fa-heartbeat',
				show: this.verificaListaRotinas([
					'Acompanhamento Doença',
					'Cartão Cidadão',
					'Exames',
					'Indicadores eSus',
					'Painel do ACS',
					'Painel da UBS',
					'Medicamento',
					'Rastreamento Doença',
					'Solicitação de Vacinação',
					'Central de Vacinas',
					'Postos de Atendimento',
					'Vacinação/Classificação',
					'Painel de Estratificação',
					'Ficha de Dengue e Chikungunya'
				]),
				children: [
					{
						// Acompanhamento Doença
						displayName: 'Acompanhamento Doença',
						iconName: '',
						show: this.verificaRotina('Acompanhamento Doença'),
						children: [
							{
								displayName: 'Doença',
								iconName: '',
								show: true,
								route: 'm/saude/doenca',
							},
							{
								displayName: 'Informação',
								iconName: '',
								show: true,
								route: 'm/saude/doenca/informacao',
							},
							{
								displayName: '1. Listagem',
								iconName: '',
								route: 'm/saude/acompanhamento-doenca/listagem',
								show: true,
							},
							{
								displayName: '2. Método',
								iconName: '',
								show: true,
								route: 'm/saude/metodo',
							},
							{
								displayName: '3. Horário',
								iconName: '',
								show: true,
								route: 'm/saude/horario',
							},
							{
								displayName: '4. Indicador',
								iconName: '',
								show: true,
								route: 'm/saude/indicador',
							},
							{
								displayName: '5. Relatório',
								iconName: '',
								show: true,
								route: 'm/saude/relatorio',
							},
						],
					},
					{
						// Cartão Cidadão
						displayName: 'Cartão Cidadão',
						iconName: '',
						show: this.verificaRotina('Cartão Cidadão'),
						route: 'm/saude/cartao-cidadao',
					},
					{
						// Exames
						displayName: 'Exames',
						iconName: '',
						show: this.verificaRotina('Exames'),
						route: 'm/saude/exames',
					},
					{
						// Indicadores eSus
						displayName: 'Indicadores eSus',
						iconName: '',
						show: this.verificaRotina('Indicadores eSus'),
						children: [
							{
								displayName: 'Dashboard',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus',
							},
							{
								displayName: 'Sala de Situações',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/tabela',
							},
							{
								displayName: 'Gestantes',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/gestantes',
							},
							{
								displayName: 'Mulheres',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/mulheres',
							},
							{
								displayName: 'Crianças',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/criancas',
							},
							{
								displayName: 'Hipertensos',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/hipertensos',
							},
							{
								displayName: 'Diabéticos',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/diabeticos',
							},
							{
								displayName: 'Validador e-Gestor',
								iconName: '',
								show: true,
								route: 'm/saude/indicador-eSus/egestor',
							},
						],
					},
					{ 	// Cofinanciamento Federal
						displayName: 'Cofinanciamento Federal',
						iconName: '',
						show: this.verificaRotina('Indicadores eSus'),
						route: 'm/saude/indicador-eSus/CVAT',
					},
					{
						// Painel ACS
						displayName: 'ACS',
						iconName: '',
						show: this.verificaRotina('Painel do ACS'),
						children: [
							{
								displayName: 'Painel',
								iconName: '',
								show: true,
								route: 'm/saude/painel-acs',
							},
							{
								displayName: 'Relatórios',
								iconName: '',
								show: true,
								route: 'm/saude/painel-acs/relatorios',
							},
						],
					},
					{
						// Painel UBS
						displayName: 'UBS',
						iconName: '',
						show: this.verificaRotina('Painel da UBS'),
						route: 'm/saude/painel-ubs',
					},
					{
						// Painel da criança
						displayName: 'Painel da criança',
						iconName: '',
						show: this.verificaRotina('Painel da criança'),
						route: 'm/saude/painel-crianca',
					},

					{
						// Vacinação/Classificação
						displayName: 'Vacinação/Classificação',
						iconName: '',
						show: this.verificaRotina('Vacinação/Classificação'),
						route: 'm/saude/vacinacao-classificacao',
					},
					{
						displayName: 'Painel de Estratificação',
						iconName: '',
						show: this.verificaRotina('Painel de Estratificação'),
						children: [
							{
								displayName: 'Dashboard',
								iconName: '',
								show: true,
								route: 'm/saude/painel-estratificacao',
							},
							{
								displayName: 'Hipertensos',
								iconName: '',
								show: true,
								route: 'm/saude/painel-estratificacao/hipertensos',
							},
							{
								displayName: 'Diabeticos',
								iconName: '',
								show: true,
								route: 'm/saude/painel-estratificacao/diabeticos',
							},
							{
								displayName: 'Gestantes',
								iconName: '',
								show: true,
								route: 'm/saude/painel-estratificacao/gestantes',
							},
						],
					},
					{
						// Sala de Situação da Vigilância Epidemiológica
						displayName: 'Vigilância Epidemiológica',
						iconName: '',
						show: this.verificaRotina('Sala de Situação da Vigilância Epidemiológica'),
						children: [
							{
								displayName: 'Sala de Situações',
								iconName: '',
								show: true,
								route: 'm/saude/sala-situacao-vigilancia-epidemologica',
							},
							{
								displayName: 'Lista de Doenças',
								iconName: '',
								show: true,
								route: 'm/saude/sala-situacao-vigilancia-epidemologica/lista-doencas',
							},
						],
					},

					{
						// Medicamento
						displayName: 'Medicamento',
						iconName: '',
						show: this.verificaRotina('Medicamento'),
						children: [
							{
								displayName: 'Configuração',
								iconName: '',
								show: this.verificaRotina('Medicamento Configuração'),
								route: 'm/saude/configuracao-medicamento',
							},
							{
								displayName: 'Categorias',
								iconName: '',
								show: true,
								route: 'm/saude/categoria-medicamento',
							},
							{
								displayName: 'Medicamentos',
								iconName: '',
								show: true,
								route: 'm/saude/eSus-medicamento',
							},
						],
					},
					{
						// Rastreamento Doença
						displayName: 'Rastreamento Doença',
						iconName: '',
						show: this.verificaRotina('Rastreamento Doença'),
						children: [
							{
								displayName: 'Listagem',
								iconName: '',
								route: 'm/saude/acompanhamento-doenca/listagem',
								show: true,
							},

							{
								displayName: 'Histórico de locais',
								iconName: '',
								route: 'm/saude/acompanhamento-doenca/historico-de-locais',
								show: true,
							},
							{
								displayName: 'Histórico de locais',
								iconName: '',
								route: 'm/saude/acompanhamento-doenca/historico-de-locais',
								show: true,
							},

							{
								displayName: 'Localização Doença',
								iconName: '',
								route: 'm/saude/acompanhamento-doenca/localizacao-doenca',
								show: true,
							},
						],
					},
					{
						// Solicitação de Vacinação
						displayName: 'Solicitação de Vacinação',
						iconName: '',
						show: this.verificaRotina('Solicitação de Vacinação'),
						children: [
							{
								displayName: 'Comorbidade',
								iconName: '',
								show: true,
								route: 'm/saude/comorbidade',
							},
							{
								displayName: 'Solicitações',
								iconName: '',
								show: true,
								route: 'm/saude/vacinacao',
							},
						],
					},
					{
						// Vacinação
						displayName: 'Vacinação',
						iconName: '',
						show: this.verificaListaRotinas(['Central de Vacinas', 'Postos de Atendimento']),
						children: [
							{
								displayName: '1. Vacina',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/vacina',
							},
							{
								displayName: '2. Lote de Vacinas',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/lote-vacina',
							},
							{
								displayName: '3. Tipo de Vaga',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/tipo-vaga',
							},
							{
								displayName: '4. Vagas Automáticas',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/vagas-automaticas',
							},
							{
								displayName: '5. Vagas Disponíveis',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/vagas-disponiveis',
							},
							{
								displayName: '6. Agendamentos',
								iconName: '',
								show: this.verificaRotina('Postos de Atendimento'),
								route: 'm/saude/vacinacao-covid/agendamentos',
							},
							{
								displayName: '7. Informação',
								iconName: '',
								show: this.verificaRotina('Central de Vacinas'),
								route: 'm/saude/vacinacao-covid/informacao',
							},
						],
					},
				],
			},
			{
				// Processo Seletivo
				displayName: 'Processos',
				iconName: 'fa fa-briefcase',
				show: this.verificaListaRotinas(['Processo Seletivo', 'Processo Seletivo Permissão']),
				children: [
					{
						displayName: 'Lista de Processos',
						iconName: '',
						route: 'm/processo-seletivo/processo',
						show: true,
					},
					{
						displayName: 'Categorias',
						iconName: '',
						route: 'm/processo-seletivo/categorias',
						show: true,
					},
					{
						displayName: 'Permissões',
						iconName: '',
						show: this.verificaRotina('Processo Seletivo Permissão'),
						route: 'm/processo-seletivo/permissoes',
					},
					{
						displayName: 'Vagas',
						iconName: '',
						route: 'm/processo-seletivo/vaga',
						show: true,
					},
					{
						displayName: 'Inscritos',
						iconName: '',
						route: 'm/processo-seletivo/inscrito',
						show: true,
					},
				],
			},
			{
				// Câmara Legislativa
				displayName: 'Câmara Legislativa',
				iconName: 'fas fa-balance-scale',
				show: this.verificaListaRotinasAndOrigem(this.permissoesCamara, 'CAMARA'),
				children: [

					{
						displayName: 'Dashboard',
						iconName: 'fas fa-tachometer-alt',
						show: this.verificaRotinaAndOrigem('Dashboard', 'CAMARA'),
						route: 'm/camara/dashboard',
					},
					{
						displayName: 'Dashboard Mural',
						iconName: 'fas fa-chalkboard',
						route: 'm/camara/dashboard/mural',
					},
					{
						displayName: 'Acessos',
						iconName: 'fas fa-user-tag',
						show: this.verificaListaRotinasAndOrigem(['Rotinas', 'Usuários'], 'CAMARA'),
						children: [
							{
								displayName: 'Rotinas',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Rotinas', 'CAMARA'),
								route: 'm/camara/rotinas',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Usuários', 'CAMARA'),
								route: 'm/camara/usuarios',
							},
						],
					},
					{
						displayName: 'Agendamento de Serviços',
						iconName: 'far fa-calendar-alt',
						show: this.verificaRotinaAndOrigem('Agendamento Serviços', 'CAMARA'),
						children: this.camaraCategoriasAgendamentoServico,
					},
					{
						displayName: 'Controle de Processos',
						iconName: 'fas fa-paste',
						show: this.verificaListaRotinasAndOrigem([
							'Controle de Processo',
							'Controle de Processo Permissão',
							'Controle de Processo Etapa Configuração',
							'Controle de Processo Configuração',
							'Controle de Processo Histórico Solicitações',
							'Controle de Processo Dashboard',
							'Controle de Processo Sincronização',
							'Controle de Processo Minhas Solicitações',
						], 'CAMARA'),
						children: [
							{
								displayName: 'Atividades',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo', 'CAMARA'),
								route: 'm/camara/controle-processos/minhas-atividades',
							},
							{
								displayName: 'Configurações etapas',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Etapa Configuração', 'CAMARA'),
								route: 'm/camara/controle-processos/processos/etapas/configuracoes',
							},
							{
								displayName: 'Dashboard',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Dashboard', 'CAMARA'),
								route: 'm/camara/controle-processos/dashboard',
							},
							{
								displayName: 'Histórico',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Histórico Solicitações', 'CAMARA'),
								route: 'm/camara/controle-processos/solicitacoes/historico',
							},
							{
								displayName: 'Minhas solicitações',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Minhas Solicitações', 'CAMARA'),
								route: 'm/camara/controle-processos/minhas-solicitacoes',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Permissão', 'CAMARA'),
								route: 'm/camara/controle-processos/processos/permissoes',
							},
							{
								displayName: 'Sincronização',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo Sincronização', 'CAMARA'),
								route: 'm/camara/controle-processos/sincronizacao',
							},
							{
								displayName: 'Solicitações',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Controle de Processo', 'CAMARA'),
								route: 'm/camara/controle-processos/solicitacoes',
							},
						],
					},
					{
						displayName: 'Dados da Câmara',
						iconName: 'fas fa-university',
						show: this.verificaRotinaAndOrigem('Dados da Câmara', 'CAMARA'),
						route: 'm/camara/utilidade',
					},
					{
						displayName: 'Documentos',
						iconName: 'far fa-file-alt',
						show: this.verificaRotinaAndOrigem('Lista de Documentos', 'CAMARA'),
						route: 'm/camara/documentos',
					},
					{
						displayName: 'Dashboard Permissões',
						iconName: 'fas fa-tachometer-alt',
						show: this.verificaRotinaAndOrigem('Dashboard Permissões', 'CAMARA'),
						route: 'm/camara/permissoes/dashboard',
					},
					{
						displayName: 'Enquetes',
						iconName: 'fas fa-bullhorn',
						show: this.verificaRotinaAndOrigem('Enquetes', 'CAMARA'),
						route: 'm/camara/enquetes',
					},
					{
						displayName: 'Eventos',
						iconName: 'fas fa-calendar-week',
						show: this.verificaRotinaAndOrigem('Eventos', 'CAMARA'),
						children: [
							{
								displayName: 'Categorias',
								iconName: '',
								show: true,
								route: 'm/camara/eventos/categorias',
							},
							{
								displayName: 'Eventos',
								iconName: '',
								show: true,
								route: 'm/camara/eventos',
							},
						],
					},
					{
						displayName: 'Formulários',
						iconName: 'fas fa-file-invoice',
						show: this.verificaListaRotinasAndOrigem(['Formulários', 'Formulários Permissão', 'Formulário Dashboard'], 'CAMARA'),
						children: [
							{
								displayName: 'Dashboard',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Formulário Dashboard', 'CAMARA'),
								route: 'm/camara/formularios/dashboard',
							},
							{
								displayName: 'Lista de formulários',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Formulários', 'CAMARA'),
								route: 'm/camara/formularios',
							},
							{
								displayName: 'Vínculo de Formulários',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Formulários Vínculos', 'CAMARA'),
								route: 'm/camara/formularios/vinculos',
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Formulários Permissão', 'CAMARA'),
								route: 'm/camara/formularios/permissoes',
							},
						],
					},
					{
						displayName: 'Mural',
						iconName: 'fas fa-chalkboard',
						show: this.verificaRotinaAndOrigem('Mural', 'CAMARA'),
						route: 'm/camara/mural',
					},
					{
						displayName: 'Lista de Ocorrências',
						iconName: 'fas fa-exclamation-circle',
						show: this.verificaListaRotinasAndOrigem(
							[
								'Chamados Não Tramitados Em Aberto',
								'Chamados Não Tramitados Em Análise',
								'Chamados Não Tramitados Finalizados',
								'Chamados Tramitados Em Aberto',
								'Chamados Tramitados Em Análise',
								'Chamados Tramitados Finalizados',
							],
							'CAMARA',
						),
						route: 'm/camara/ocorrencias',
					},
					{
						displayName: 'Notícias',
						iconName: 'fas fa-newspaper',
						show: this.verificaRotinaAndOrigem('Notícias', 'CAMARA'),
						route: 'm/camara/noticias',
					},
					{
						displayName: 'Ocorrências',
						iconName: 'fas fa-exclamation-circle',
						show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
						children: [
							{
								displayName: 'Assuntos',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/secretarias-assuntos',
							},
							{
								displayName: 'Documentos Finalização',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/documentos-finalizacao',
							},
							{
								displayName: 'Prioridades',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/prioridades',
							},
							{
								displayName: 'Secretarias',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/secretarias',
							},
							{
								displayName: 'Tipo de Comentário',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/tipo-comentario',
							},
							{
								displayName: 'Respostas Pré-Cadastradas',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Ocorrências', 'CAMARA'),
								route: 'm/camara/ocorrencias/respostas-pre-cadastradas',
							},
							{
								displayName: 'Vereador',
								iconName: '',
								show: this.verificaSeVereador(),
								route: 'm/camara/ocorrencias/vereador',
							},
						],
					},
					{
						displayName: 'Parâmetros',
						iconName: 'fas fa-wrench',
						show: this.verificaListaRotinasAndOrigem(
							['Capa Ocorrência', 'Cor Aplicativo', 'Onboarding', 'Tela Login', 'Telas do Aplicativo',],
							'CAMARA',
						),
						children: [
							{
								displayName: 'Capas Ocorrência',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Capa Ocorrência', 'CAMARA'),
								route: 'm/camara/parametros/capa-ocorrencia',
							},
							{
								displayName: 'Configurações Relatórios',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Configurações Relatórios', 'CAMARA'),
								route: 'm/camara/parametros/configuracoes-relatorios',
							},
							{
								displayName: 'Cores Aplicativo',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Cor Aplicativo', 'CAMARA'),
								route: 'm/camara/parametros/cores-aplicativo',
							},
							{
								displayName: 'Onboarding',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Onboarding', 'CAMARA'),
								route: 'm/camara/parametros/onboarding',
							},
							{
								displayName: 'Tela de Login',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Tela Login', 'CAMARA'),
								route: 'm/camara/parametros/tela-login',
							},
							{
								displayName: 'Telas do Aplicativo',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Telas do Aplicativo', 'CAMARA'),
								route: 'm/camara/parametros/telas-aplicativo',
							},
						],
					},
					{
						displayName: 'Partidos',
						iconName: 'fas fa-flag',
						show: this.verificaRotinaAndOrigem('Partidos', 'CAMARA'),
						route: 'm/camara/vereador/partido',
					},
					{
						// Processo Seletivo
						displayName: 'Processo Seletivo',
						iconName: 'fa fa-briefcase',
						show: this.verificaListaRotinasAndOrigem(['Processo Seletivo', 'Processo Seletivo Permissão'], 'CAMARA'),
						children: [
							{
								displayName: 'Processo Seletivo',
								iconName: '',
								route: 'm/camara/processo-seletivo/processo',
								show: this.verificaRotinaAndOrigem('Processo Seletivo', 'CAMARA'),
							},
							{
								displayName: 'Permissões',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Processo Seletivo Permissão', 'CAMARA'),
								route: 'm/camara/processo-seletivo/permissoes',
							},
							{
								displayName: 'Vagas',
								iconName: '',
								route: 'm/camara/processo-seletivo/vaga',
								show: this.verificaRotinaAndOrigem('Processo Seletivo', 'CAMARA'),
							},
							{
								displayName: 'Inscritos',
								iconName: '',
								route: 'm/camara/processo-seletivo/inscrito',
								show: this.verificaRotinaAndOrigem('Processo Seletivo', 'CAMARA'),
							},
						],
					},
					{
						displayName: 'Projetos de Lei',
						iconName: 'fas fa-gavel',
						show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
						children: [
							{
								displayName: 'Situações',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
								route: 'm/camara/pautas/situacoes',
							},
							{
								displayName: 'Tipos de Matéria Legislativa',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
								route: 'm/camara/pautas/materias',
							},
							{
								displayName: 'Projetos',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
								route: 'm/camara/pautas/projetos',
							},
							{
								displayName: 'Tipos de Sessão',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
								route: 'm/camara/pautas/sessoes',
							},
							{
								displayName: 'Pautas',
								iconName: '',
								show: this.verificaRotinaAndOrigem('Projetos de Lei', 'CAMARA'),
								route: 'm/camara/pautas',
							},
						],
					},
					{
						displayName: 'Turismo',
						iconName: 'fas fa-plane',
						show: this.verificaRotinaAndOrigem('Turismo', 'CAMARA'),
						children: this.categoriasTurismo,
					},
					{
						displayName: 'Utilidade Pública',
						iconName: 'fas fa-wrench',
						show: this.verificaRotinaAndOrigem('Utilidade Pública', 'CAMARA'),
						children: this.camaraCategoriasUtilidadePublica,
					},
					{
						displayName: 'Vereadores',
						iconName: 'fas fa-user-tag',
						show: this.verificaRotinaAndOrigem('Lista de Vereadores', 'CAMARA'),
						route: 'm/camara/vereador',
					},
				],
			},
			{
				displayName: 'Rotativo Digital',
				iconName: 'fas fa-car',
				show: this.verificaListaRotinas([
					'Rotativo Digital',
					'Rotativo Veículo',
					'Rotativo Veículo Tipo',
					'Rotativo Duvida Frequente',
					'Rotativo Configuração',
					'Rotativo Vaga',
					'Rotativo Região',
					'Rotativo Estabelecimento',
					'Rotativo Meus Estabelecimentos',
					'Rotativo Dashboard',
					'Rotativo Dashboard Região',
					'Rotativo Dashboard Financeiro',
					'Rotativo Fiscal Permissão',
				]),
				children: [
					{
						displayName: 'Configuração',
						iconName: '',
						show: this.verificaRotina('Rotativo Configuração'),
						route: 'm/rotativo-digital/configuracoes',
					},
					{
						displayName: 'Dashboards',
						iconName: '',
						show: this.verificaListaRotinas(['Rotativo Dashboard Região', 'Rotativo Dashboard Financeiro']),
						children: [
							{
								displayName: 'Região',
								iconName: '',
								show: this.verificaRotina('Rotativo Dashboard Região'),
								route: 'm/rotativo-digital/dashboards/regioes',
							},
							{
								displayName: 'Financeiro',
								iconName: '',
								show: this.verificaRotina('Rotativo Dashboard Financeiro'),
								route: 'm/rotativo-digital/dashboards/financeiros',
							},
						],
					},
					{
						displayName: 'Dúvidas Frequentes',
						iconName: '',
						show: this.verificaRotina('Rotativo Duvida Frequente'),
						route: 'm/rotativo-digital/duvidas-frequentes',
					},
					{
						displayName: 'Estabelecimentos',
						iconName: '',
						show: this.verificaRotina('Rotativo Estabelecimento'),
						route: 'm/rotativo-digital/estabelecimentos',
					},
					{
						displayName: 'Fiscais permissões',
						iconName: '',
						show: this.verificaRotina('Rotativo Fiscal Permissão'),
						route: 'm/rotativo-digital/fiscais/permissoes',
					},
					{
						displayName: 'Meus Estabelecimentos',
						iconName: '',
						show: this.verificaRotina('Rotativo Meus Estabelecimentos'),
						route: 'm/rotativo-digital/meus-estabelecimentos',
					},
					{
						displayName: 'Regiões',
						iconName: '',
						show: this.verificaListaRotinas(['Rotativo Vaga', 'Rotativo Região']),
						children: [
							{
								displayName: 'Listagem',
								iconName: '',
								show: this.verificaRotina('Rotativo Região'),
								route: 'm/rotativo-digital/regioes',
							},
							{
								displayName: 'Vagas',
								iconName: '',
								show: this.verificaRotina('Rotativo Vaga'),
								route: 'm/rotativo-digital/regioes/vagas',
							},
						],
					},
					{
						displayName: 'Veículos',
						iconName: '',
						show: this.verificaListaRotinas(['Rotativo Veículo', 'Rotativo Veículo Tipo']),
						children: [
							{
								displayName: 'Listagem',
								iconName: '',
								show: this.verificaRotina('Rotativo Veículo'),
								route: 'm/rotativo-digital/veiculos',
							},
							{
								displayName: 'Tipos',
								iconName: '',
								show: this.verificaRotina('Rotativo Veículo Tipo'),
								route: 'm/rotativo-digital/veiculos/tipos',
							},
						],
					},
				],
			},
			{
				displayName: 'Rodar Script',
				iconName: '',
				show: this.verificaRotina('Rodar Script Controle de Processo'),
				ploomesScript: true
			},
			{
				displayName: 'Base de Conhecimento',
				iconName: 'fas fa-book',
				show: this.verificaRotina('Base de Conhecimento'),
				route: 'm/base-de-conhecimento',
			},
			{
				displayName: 'Whatsapp',
				iconName: 'fa-brands fa-whatsapp',
				show: this.verificaRotinaAndOrigem('Whatsapp Configuração', 'PREFEITURA') || this.verificaRotinaAndOrigem('Whatsapp Configuração', 'CAMARA'),
				children: [
					{
						displayName: 'Dashboard',
						iconName: 'fa-solid fa-gauge',
						show: this.verificaRotinaAndOrigem('Whatsapp Configuração', 'PREFEITURA'),
						route: 'm/whatsapp/dashboard',
					},
					{
						displayName: 'Bate Papo Whatsapp',
						iconName: 'fa-brands fa-rocketchat',
						show: this.verificaRotina('Bate Papo Whatsapp'),
						route: 'm/bate-papo-whatsapp',
					},
					{
						displayName: 'ADMIN - Configurações do Whatsapp',
						iconName: 'fas fa-link',
						show: this.verificaRotina('Configurações Whatsapp') && this.storage.read('isAdmin') == 'true',
						route: 'm/config-whatsapp',
					},
					{
						displayName: 'Qr Code - Whatsapp Camara',
						iconName: 'fa-solid fa-qrcode',
						show: this.verificaRotinaAndOrigem('Whatsapp Configuração', 'CAMARA'),
						route: 'm/camara/whatsapp',
					},
					{
						displayName: 'Qr Code - Whatsapp Prefeitura',
						iconName: 'fa-solid fa-qrcode',
						show: this.verificaRotinaAndOrigem('Whatsapp Configuração', 'PREFEITURA'),
						route: 'm/whatsapp',
					},
				]
			},
			{
				displayName: 'Central de Infraestrutura',
				iconName: 'fas fa-arrow-right-to-city',
				show: this.verificaRotina('Central de Iluminação Pública'),
				children: this.categoriasInfraestrutura,
			},
			{
				// Restaurante Popular
				displayName: 'Restaurante Popular',
				iconName: 'fas fa-utensils',
				show: this.verificaListaRotinas(['Restaurante Popular']),
				children: [
					{
						displayName: 'Dashboard',
						iconName: '',
						route: 'm/restaurante-popular/dashboard',
						show: true,
					},
					{
						displayName: 'Configuração',
						iconName: '',
						route: 'm/restaurante-popular/configuracao',
						show: true,
					},
				],
			},
		];

		return menu;
	}

	dfs_filter(nodes: NavItem[]): NavItem[] {
		return nodes.filter((root) => {
			if (root.show && root.children && root.children.length > 0) {
				root.children = this.dfs_filter(root.children);
			}
			if (root.show == null || root.show == undefined) return true;
			return root.show;
		});
	}

	verificaRotina(item): boolean {
		return this.verificaListaRotinasAndOrigem([item], 'PREFEITURA');
	}

	verificaSeVereador(): boolean {
		return this.vereador != undefined && this.vereador != null;
	}

	verificaRotinaAndOrigem(item, origem): boolean {
		if (!item) {
			return false;
		}

		return this.verificaListaRotinasAndOrigem([item], origem);
	}

	verificaListaRotinas(rotinas): boolean {
		return this.verificaListaRotinasAndOrigem(rotinas, 'PREFEITURA');
	}

	verificaListaRotinasAndOrigem(rotinas, origem): boolean {
		return rotinas.some((item) => this.rotinas.findIndex((r) => r.nome == item && r.origem == origem) != -1);
	}

	verificaCadastros(): boolean {
		return this.rotinas.some((r) => this.permissoesCadastros.findIndex((item) => r.nome == item && r.origem == 'PREFEITURA') != -1);
	}

   verificaPermissaoCartaoDeVacina() {
      this.permissoesCartaoDeVacinaService.findByUserId(this.usuario.id).subscribe({
         next: (res) => {
            this.podeCadastrarVacina = res.podeCadastrarVacina
         },
         error: (err) => {
            this.podeCadastrarVacina = false
         }
      })
   }

	initEvents() {
		this.transporteService.atualizaNavbarTransporte.subscribe(() => {
			this.getCategoriasTransporte();
		});
		this.turismoService.atualizaNavbarTurismo.subscribe(() => {
			this.getCategoriasTurismo();
		});
		this.informacoesService.atualizaNavbarInformacoes.subscribe(() => {
			this.getCategoriaInformacoes();
		});
		this.webviewService.atualizaNavbarWebview.subscribe(() => {
			this.getCategoriasWebview();
		});
		this.relatoriosService.atualizaNavbarRelatorios.subscribe(() => {
			this.getCategoriasRelatorios();
		});
		this.eventAtualizar.emitirEvento.subscribe(() => {
			this.buscaMenuAsync();
		});
		this.historicoNotificacaoPainelService.atualizaContadorQuantidade.subscribe(() => {
			this.countNotificacoesVisualizadaIsFalse();
		});
		this.vereadorService.getVereadorByCidadaoId(this.usuario.id).subscribe({
			next: (data) => {
				this.vereador = data;
			},
			error: (_) => {
				this.vereador = null;
			},
		});
		this.verificaPermissaoCartaoDeVacina()
	}

	getCategoriasProdutos() {
		return new Promise((resolve, reject) => {
			this.categoriasProdutos.length = 0;

			this.categoriasProdutos.push({
				displayName: 'Categoria Empresa',
				iconName: '',
				show: this.verificaRotina('Categoria de Empresa'),
				route: 'm/procon/empresa-categoria',
			});

			this.categoriasProdutos.push({
				displayName: 'Bandeiras Empresa',
				iconName: '',
				show: this.verificaRotina('Empresa'),
				route: 'm/procon/bandeira-empresa',
			});

			this.categoriasProdutos.push({
				displayName: 'Empresas',
				iconName: '',
				show: this.verificaRotina('Empresa'),
				route: 'm/empresa',
			});

			this.categoriasProdutos.push({
				displayName: 'Profissões',
				iconName: '',
				show: this.verificaRotina('Profissões'),
				route: 'm/profissoes',
			});

			this.categoriasProdutos.push({
				displayName: 'Categoria Produto',
				iconName: '',
				show: this.verificaRotina('Categoria de Produto'),
				route: 'm/procon/produto-categoria',
			});

			this.proconProdutoCategoriaService
				.getCategorias()
				.then((data) => {
					data.categoria.forEach((element) => {
						this.categoriasProdutos.push({
							displayName: element.nomeCategoria,
							iconName: '',
							show: this.verificaListaRotinas(['Produto', 'Variação de Preço']),
							children: [
								{
									displayName: 'Produto',
									iconName: '',
									show: this.verificaRotina('Produto'),
									route: 'm/procon/produto' + '/' + element.id,
								},
								{
									displayName: 'Variação De Preço',
									iconName: '',
									show: this.verificaRotina('Variação de Preço'),
									route: 'm/procon/variacao-preco' + '/' + element.id,
								},
							],
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}
	getAgendamentoServicoDinamico() {
		return new Promise((resolve, reject) => {
			this.categoriasAgendamentoServicoDinamico.length = 0;
			this.categoriasAgendamentoServicoDinamico.push({
				displayName: 'Serviços',
				iconName: '',
				show: true,
				route: 'm/servicos-dinamicos/categorias',
			});
			this.categoriasAgendamentoServicoDinamico.push({
				displayName: 'Pedidos',
				iconName: '',
				show: true,
				route: 'm/servicos-dinamicos/pedidos',
			});
			this.categoriasAgendamentoServicoDinamico.push({
				displayName: 'Respostas',
				iconName: '',
				show: true,
				route: 'm/servicos-dinamicos/respostas',
			});
			this.categoriasAgendamentoServicoDinamico.push({
				displayName: 'Permissões',
				iconName: '',
				show: this.verificaRotina('Permissão Solicitação de Agendamento'),
				route: 'm/servicos-dinamicos/permissoes',
			});
			this.categoriasAgendamentoServicoDinamico.push({
				displayName: 'Leitores De Qr-Code',
				iconName: '',
				show: this.verificaRotina('Permissão QrCode Solicitação de Agendamento'),
				route: 'm/servicos-dinamicos/leitores',
			});
			this.agendamentoServicoDinamicoCategoriaService
				.getAllCategorias()
				.then((data) => {
					data.forEach((element) => {
						if (element.configuracaoCategoria.disponibilizarPorSubCategoria) {
							this.categoriasAgendamentoServicoDinamico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Categorias',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/categoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Subcategorias',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/subcategoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Restrição',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '5. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '6. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						} else {
							this.categoriasAgendamentoServicoDinamico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Restrição',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/servicos-dinamicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						}
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getAgendamentoServico() {
		return new Promise((resolve, reject) => {
			this.categoriasAgendamentoServico.length = 0;
			this.categoriasAgendamentoServico.push({
				displayName: 'Permissões',
				iconName: '',
				show: this.verificaRotina('Permissão Agendamento de Serviço'),
				route: 'm/servicos/permissoes',
			});
			this.categoriasAgendamentoServico.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/servicos/categorias',
			});
			this.categoriasAgendamentoServico.push({
				displayName: 'Informação',
				iconName: '',
				show: true,
				route: 'm/servicos/informacao',
			});
			this.agendamentoServicoService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						if (element.statusDisponibilidade == 1) {
							this.categoriasAgendamentoServico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Categorias de Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos/categoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Subcategorias de Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos/subcategoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Restrição',
										iconName: '',
										show: true,
										route: 'm/servicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/servicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '5. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '6. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/servicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						} else {
							this.categoriasAgendamentoServico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Restrição',
										iconName: '',
										show: true,
										route: 'm/servicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/servicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/servicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/servicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						}
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCamaraAgendamentoServico() {
		return new Promise((resolve, reject) => {
			this.camaraCategoriasAgendamentoServico.length = 0;
			this.camaraCategoriasAgendamentoServico.push({
				displayName: 'Permissões',
				iconName: '',
				show: this.verificaRotinaAndOrigem('Permissão Agendamento de Serviço', 'CAMARA'),
				route: 'm/camara/servicos/permissoes',
			});
			this.camaraCategoriasAgendamentoServico.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/camara/servicos/categorias',
			});
			this.camaraCategoriasAgendamentoServico.push({
				displayName: 'Informação',
				iconName: '',
				show: true,
				route: 'm/camara/servicos/informacao',
			});
			this.camaraAgendamentoServicoService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						if (element.statusDisponibilidade == 1) {
							this.camaraCategoriasAgendamentoServico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Categorias de Agendamento',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/categoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Subcategorias de Agendamento',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/subcategoria/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Restrição',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '5. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '6. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						} else {
							this.camaraCategoriasAgendamentoServico.push({
								displayName: element.nome,
								iconName: '',
								children: [
									{
										displayName: '1. Restrição',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/restricao-agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '2. Disponibilidades',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/disponibilidade/' + element.id + '/' + element.nome,
									},
									{
										displayName: '3. Realizar Agendamento',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/realizar/agendamento/' + element.id + '/' + element.nome,
									},
									{
										displayName: '4. Agendamentos Realizados',
										iconName: '',
										show: true,
										route: 'm/camara/servicos/agendamentos/' + element.id + '/' + element.nome,
									},
								],
							});
						}
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCategoriaInformacoes() {
		return new Promise((resolve, reject) => {
			this.categoriasInformacao.length = 0;
			this.categoriasInformacao.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/informacoes/categorias',
			});
			this.informacoesService.getCategorias().subscribe({
				next: (data) => {
					data.categorias.forEach((element) => {
						this.categoriasInformacao.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							route: 'm/informacoes/categorias/' + element.id + '/' + element.nome,
						});
					});
				},
				complete: () => {
					resolve(true);
				},
			});
		});
	}

	getModalidadeEsportiva() {
		return new Promise((resolve, reject) => {
			this.categoriasModalidadeEsportiva.length = 0;
			this.categoriasModalidadeEsportiva.push({
				displayName: 'Modalidades',
				iconName: '',
				show: true,
				route: 'm/modalidade/esportiva',
			});
			this.modalidadeEsporteService.getCategorias().subscribe({
				next: (data) => {
					data.forEach((element) => {
						this.categoriasModalidadeEsportiva.push({
							displayName: element.nome,
							iconName: '',
							children: [
								{
									displayName: 'Categorias Esporte',
									iconName: '',
									show: true,
									route: 'm/modalidade/esportiva/categoria/' + element.id + '/' + element.nome,
								},
								{
									displayName: 'Participantes',
									iconName: '',
									show: true,
									route: 'm/modalidade/esportiva/participantes/' + element.id + '/' + element.nome,
								},
								{
									displayName: 'Links',
									iconName: '',
									show: true,
									route: 'm/modalidade/esportiva/categoria/webview/' + element.id + '/' + element.nome,
								},
								{
									displayName: 'Relatórios',
									iconName: '',
									show: true,
									route: 'm/modalidade/esportiva/relatorio/' + element.id + '/' + element.nome,
								},
							],
						});
					});
				},
				complete: () => {
					resolve(true);
				},
			});
		});
	}

	getCategoriasRelatorios() {
		return new Promise((resolve, reject) => {
			this.categoriasRelatorio.length = 0;
			this.relatoriosPermissoesService.findAllPermissaoCidadao(this.usuario.id).subscribe({
				next: (res) => {
					const permissoesRelatorio = res;

					this.relatoriosService.getAllCategorias().then((data) => {
						data.categorias.forEach((element) => {

							const hasPermission = permissoesRelatorio.some(
								(permissao) => permissao.categoriaRelatorio.displayName === element.displayName
							);

							const alreadyExists = this.categoriasRelatorio.some(
								(cat) => cat.displayName === element.displayName
							);

							if (hasPermission && !alreadyExists) {
								this.categoriasRelatorio.push({
									displayName: element.displayName,
									iconName: '',
									show: true,
									route: 'm/relatorios/categoria/' + element.id + '/' + element.nome,
								});
							}
						});
					}).finally(() => {
						resolve(true);
					});
				},
				error: (err) => {
					console.log(err);
					resolve(true);
				}
			});
		});
	}

	getCategoriasTransporte() {
		return new Promise((resolve, reject) => {
			this.categoriasTransporte.length = 0;
			this.categoriasTransporte.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/transporte/categorias',
			});

			if (this.verificaRotina('Transporte Permissão')) {
				this.categoriasTransporte.push({
					displayName: 'Permissões',
					iconName: '',
					show: true,
					route: 'm/transporte/categorias/permissoes',
				});
			}

			if (this.verificaRotina('Transporte Comunicado')) {
				this.categoriasTransporte.push({
					displayName: 'Comunicados',
					iconName: '',
					show: true,
					route: 'm/transporte/comunicados',
				});
			}

			this.transporteService
				.getCategoriasPermitidas()
				.then((data) => {
					data.forEach((element: Categoria) => {
						this.categoriasTransporte.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							children: [
								{
									displayName: '1. Linhas',
									iconName: '',
									show: true,
									route: 'm/transporte/categorias/' + element.id + '/linhas',
								},
								{
									displayName: '2. Pontos',
									iconName: '',
									show: element.ativaPontos,
									route: 'm/transporte/categorias/' + element.id + '/pontos-parada',
								},
								{
									displayName: '3. Períodos',
									iconName: '',
									show: true,
									route: 'm/transporte/categorias/' + element.id + '/periodos',
								},
								{
									displayName: '4. Vias',
									iconName: '',
									show: true,
									route: 'm/transporte/categorias/' + element.id + '/vias',
								},
								{
									displayName: '5. Horários',
									iconName: '',
									show: true,
									route: 'm/transporte/categorias/' + element.id + '/horarios',
								},
								{
									displayName: '6. Veiculos',
									iconName: '',
									show: true,
									route: 'm/transporte/categorias/' + element.id + '/veiculos',
								},
							],
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCategoriasTurismo() {
		return new Promise((resolve, reject) => {
			this.categoriasTurismo.length = 0;
			this.categoriasTurismo.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/turismo/categorias',
			});
			this.categoriasTurismo.push({
				displayName: 'Solicitações de Cadastro',
				iconName: '',
				show: true,
				route: 'm/turismo/solicitacoes-cadastro',
			});
			this.turismoService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						this.categoriasTurismo.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							children: [
								{
									displayName: '1. SubCategorias',
									iconName: '',
									show: true,
									route: 'm/turismo/categorias/' + element.id + '/subcategorias',
								},
								{
									displayName: '2. Listagem',
									iconName: '',
									show: true,
									route: 'm/turismo/categorias/' + element.id,
								},
								{
									displayName: '3. Ordenacao',
									iconName: '',
									show: true,
									route: 'm/turismo/categorias/' + element.id + '/subcategorias/ordenacao',
								},
							],
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCategoriasUtilidadePublica() {
		return new Promise((resolve, reject) => {
			this.categoriasUtilidadePublica.length = 0;
			this.categoriasUtilidadePublica.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/utilidadepublica/categorias',
			});
			this.categoriasUtilidadePublica.push({
				displayName: 'Solicitações de Cadastro',
				iconName: '',
				show: true,
				route: 'm/utilidadepublica/solicitacoes-cadastro',
			});
			this.utilidadePublicaService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						this.categoriasUtilidadePublica.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							children: [
								{
									displayName: '1. SubCategorias',
									iconName: '',
									show: true,
									route: 'm/utilidadepublica/categorias/' + element.id + '/subcategorias',
								},
								{
									displayName: '2. Listagem',
									iconName: '',
									show: true,
									route: 'm/utilidadepublica/categorias/' + element.id,
								},
								{
									displayName: '3. Ordenacao',
									iconName: '',
									show: true,
									route: 'm/utilidadepublica/categorias/' + element.id + '/subcategorias/ordenacao',
								},
							],
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCamaraCategoriasUtilidadePublica() {
		return new Promise((resolve, reject) => {
			this.camaraCategoriasUtilidadePublica.length = 0;
			this.camaraCategoriasUtilidadePublica.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/camara/utilidadepublica/categorias',
			});
			this.camaraUtilidadePublicaService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						this.camaraCategoriasUtilidadePublica.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							children: [
								{
									displayName: '1. SubCategorias',
									iconName: '',
									show: true,
									route: 'm/camara/utilidadepublica/categorias/' + element.id + '/subcategorias',
								},
								{
									displayName: '2. Listagem',
									iconName: '',
									show: true,
									route: 'm/camara/utilidadepublica/categorias/' + element.id,
								},
								{
									displayName: '3. Ordenacao',
									iconName: '',
									show: true,
									route: 'm/camara/utilidadepublica/categorias/' + element.id + '/subcategorias/ordenacao',
								},
							],
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCategoriasWebview() {
		return new Promise((resolve, reject) => {
			this.categoriasWebview.length = 0;
			this.categoriasWebview.push({
				displayName: 'Categorias',
				iconName: '',
				show: true,
				route: 'm/webview/categorias',
			});
			this.webviewService
				.getCategorias()
				.then((data) => {
					data.categorias.forEach((element) => {
						this.categoriasWebview.push({
							displayName: element.nome,
							iconName: '',
							show: true,
							route: 'm/webview/categorias/' + element.id + '/' + element.nome,
						});
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	logout(): void {
		this.authService.logout();
	}

	resizeText({ element, parent }) {
		let i = 12; // let's start with 12px
		let overflow = false;
		const maxSize = 100; // very huge text size

		while (!overflow && i < maxSize) {
			element.style.fontSize = `${i}px`;
			overflow = this.isOverflown(element.clientHeight, parent.clientHeight);
			if (!overflow) i++;
		}

		// revert to last state where no overflow happened:
		element.style.fontSize = `${i - 1}px`;
	}

	isOverflown(clientHeight, scrollHeight) {
		return scrollHeight < clientHeight;
	}

	getListaEspera() {
		return new Promise((resolve, reject) => {
			this.categoriasListaEspera.length = 0;
			this.categoriasListaEspera.push({
				displayName: 'Serviços',
				iconName: '',
				show: this.verificaRotina('Tabelas'),
				route: 'm/lista-espera/tabelas',
			});
			this.categoriasListaEspera.push({
				displayName: 'Permissões',
				iconName: '',
				show: this.verificaRotina('Tabelas Permissão'),
				route: 'm/lista-espera/permissoes',
			});
			this.listaEsperaPermissaoService
				.getMinhasPermissoes()
				.then((permissoes) => {
					permissoes.forEach((permissao) => {
						if (permissao.listaEspera.ativo) {
							this.categoriasListaEspera.push({
								displayName: permissao.listaEspera.nome,
								iconName: '',
								show: true,
								route: 'm/lista-espera/' + permissao.listaEspera.tela.toLowerCase(),
							});
						}
					});
				})
				.finally(() => {
					resolve(true);
				});
		});
	}

	getCategoriasInfraestrutura() {
		return new Promise((resolve, reject) => {
			this.categoriasInfraestrutura.length = 0;
			this.categoriasInfraestrutura.push({
				displayName: 'Dashboard',
				iconName: 'fas fa-table-cells-large',
				show: this.verificaRotina('Central de Iluminação Pública'),
				route: 'm/infraestrutura/dashboard',
			});
			this.categoriasInfraestrutura.push({
				displayName: 'Categorias',
				iconName: 'fas fa-table-cells-large',
				show: this.verificaRotina('Central de Iluminação Pública'),
				route: 'm/infraestrutura/categoria',
			});
			this.infraestruturaCategoriaService
				.getAll()
				.subscribe({
					next: (permissoes) => {
						permissoes.forEach((categoria) => {

							let children = [
								{
									displayName: 'Pontos em Tempo Real',
									iconName: 'fa-solid fa-gauge',
									show: true,
									route: 'm/infraestrutura/' + categoria.id + '/rastreamento-em-tempo-real',
								},
								{
									displayName: 'Dashboard',
									iconName: 'fa-solid fa-gauge',
									show: true,
									route: 'm/infraestrutura/' + categoria.id + '/dashboard',
								},
							];
							if (categoria.possuiPontosGeorrefericados) {
								children.push({
									displayName: 'Pontos',
									iconName: 'fa-solid fa-map-pin',
									show: true,
									route: 'm/infraestrutura/' + categoria.id + '/pontos',
								});
							}

							this.categoriasInfraestrutura.push({
								displayName: categoria.nome,
								iconName: 'fa-solid fa-angle-right',
								show: true,
								children,
							});
						});
					},
					complete: () => resolve(true)
				});
		});
	}

	countNotificacoesVisualizadaIsFalse() {
		return new Promise((resolve, reject) => {
			this.historicoNotificacaoPainelService.countAllByVisualizadaIsFalse().subscribe(
				(data) => {
					this.qtdeNotificacoes = data;
					resolve(true);
				},
				(error) => {
					reject();
				},
			);
		});
	}

	listagemNotificacoes() {
		this.router.navigate(['m/historico-notificacao-painel']);
	}

	public acessarPerfilVereador(): void {
		this.loadingVereadores = true;
		this.vereadorService.getAllAtivos().pipe(finalize(() => {
			this.loadingVereadores = false;
			this.clickHoverMenuTrigger.openMenu();
		})).subscribe({
			next: (vereadores) => {
				this.vereadores = vereadores;
			}
		});
	}

	public trocarContextoParaVereador(vereador: { value: number | undefined }): void {
		this.loadingTrocaContextoParaVereador = true;
		if (!vereador.value) {
			this.authService.trocarContextoAutenticacaoUsuarioVereador({
				idVereador: null,
				idCidadao: null,
				cpfVereador: null,
			}).pipe(finalize(() => this.loadingTrocaContextoParaVereador = false))
				.subscribe({
					next: (_) => {
						this.storage.remove('vereadorPerfil');
						this.storage.save('token', this.storage.read('adminToken'));
						this.storage.save('permissoes', this.storage.read('adminPermissoes'));
						this.storage.save('rotinas', this.storage.read('adminRotinas'));
						this.storage.save('isAdmin', JSON.parse(this.storage.read('adminUsuario')).admin);
						this.storage.save('usuario', this.storage.read('adminUsuario'));
						this.storage.remove('adminToken');
						this.storage.remove('adminPermissoes');
						this.storage.remove('adminRotinas');
						this.storage.remove('adminUsuario');
						this.vereadorPerfilSelecionado = undefined;
						this.router.navigate(['/m/dashboard']).then(() => window.location.reload());
					}
				});
			return;
		}
		const vereadorSelecionado = this.vereadores.filter(v => v.id === vereador.value)[0];
		this.authService.trocarContextoAutenticacaoUsuarioVereador({
			idVereador: vereadorSelecionado.id,
			idCidadao: vereadorSelecionado.cidadao.id,
			cpfVereador: vereadorSelecionado.cidadao.documento
		}).pipe(finalize(() => this.loadingTrocaContextoParaVereador = false))
			.subscribe({
				next: (response) => {
					this.vereadorPerfilSelecionado = response.vereador.id;
					if (!this.storage.read('adminToken')) {
						this.storage.save('adminToken', this.storage.read('token'));
						this.storage.save('adminPermissoes', this.storage.read('permissoes'));
						this.storage.save('adminRotinas', this.storage.read('rotinas'));
						this.storage.save('adminUsuario', this.storage.read('usuario'));
						this.storage.save('isAdmin', JSON.parse(this.storage.read('usuario')).admin);
					}
					this.storage.save('vereadorPerfil', JSON.stringify(response.vereador));
					this.storage.save('usuario', JSON.stringify(response.vereador.cidadao));
					this.storage.save('token', response.token);
					this.storage.save('permissoes', JSON.stringify(response.permissaoCidadao));
					const rotinas = [];
					for (let item of response.cidadaoRotinas) {
						if (item.rotina && item.rotina.nome) {
							rotinas.push({
								id: item.rotina.id,
								nome: item.rotina.nome,
								privilegio: item.privilegio,
								origem: item.origem
							});
						}
					}
					this.storage.save('rotinas', JSON.stringify(rotinas));
					this.router.navigate(['/m/dashboard']).then(() => window.location.reload());
				}
			});
	}
}
