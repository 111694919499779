import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MarkdownModule } from 'ngx-markdown';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing.module';
import { AutenticidadeDocumentosModule } from './autenticidade-documentos/autenticidade-documentos.module';
import { AuthGuard } from './guards/auth.guard';
import { PermissionGuard } from './guards/permissoes.guard';
import { LoginModule } from './login/login.module';
import { NavigationModule } from './navigation/navigation.module';
import { AuthService } from './services/auth.service';
import { ErrorInterceptor } from './services/error-interceptor.service';
import { HttpUtilService } from './services/http-util-service';
import { SocketService } from './services/socket.service';
import { StorageService } from './services/storage.service';
import { TurismoService } from './turismo/turismo.service';
import { ModalModule } from './util/modal-module/modal.module';
import { getDutchPaginatorIntl } from './util/PaginatorIntl';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        NavigationModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatDialogModule,
        ModalModule,
        LoginModule,
        AutenticidadeDocumentosModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        MatSnackBarModule,
        MarkdownModule.forRoot(),
    ],
    providers: [
        DatePipe,
        AuthService,
        StorageService,
        SocketService,
        SocketService,
        AuthGuard,
        PermissionGuard,
        TurismoService,
        HttpUtilService,
        // {
        //     provide: Favicons,
        //     useClass: BrowserFavicons
        // },
        // {
        //     provide: BROWSER_FAVICONS_CONFIG,
        //     useValue: {
        //         icons: null,
        //         cacheBusting: true
        //     }
        // },
        { provide: MatPaginatorIntl, useValue: getDutchPaginatorIntl() },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
