import { HttpUtilService } from './../services/http-util-service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from './../../environments/environment';
import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { BaseDao } from '../services/base.service';
import { StorageService } from '../services/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RelatoriosService implements BaseDao<any> {

  private url = environment.API_URL;
  private urlNode = environment.API_NODE_URL;

  ///emite evento para componente pai
  @Output() public atualizaNavbarRelatorios = new EventEmitter()

  constructor(
    private http: HttpClient,
    private util: HttpUtilService,
		private storage: StorageService,
  ) { }

  /**
   * Busca todas as categoriras
   */
  getAllCategorias(): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio/categoria", this.util.httpOptions())
      .toPromise()
  }

  getPrefeituraCidadeByNomeReferencia(): Observable <any> {
   return this.http.get(this.url + '/master/adm/prefeitura', this.util.httpOptions());
 }

  /**
   * Busca servicos que geram relatorios
   */
  getAllServicosDisponiveis(): Observable<any> {
   return this.http.get<any>(this.url + "/relatorio/servicos-disponiveis", this.util.httpOptions())
  }

  /**
  *  Busca categoria por id
  * @param id Id da categoria
  */
  getCategoriasById(id: number): Promise<any> {
    return this.http.get<any>(this.url + "/" + id, this.util.httpOptions())
      .toPromise()
  }

  createCategoria(categoria): Promise<any> {
    return this.http.post<any>(this.url + "/relatorio/categoria", categoria, this.util.httpOptions())
      .toPromise()
      .then((data) => {
        this.atualizaNavbarRelatorios.emit(true)
      })
  }

  updateCategoria(categoria): Promise<any> {
    return this.http.put<any>(this.url + "/relatorio/categoria", categoria, this.util.httpOptions())
      .toPromise()
      .then((data) => {
        this.atualizaNavbarRelatorios.emit(true)
      })
  }

  /**
   * Deleta uma categoria
   * @param id id da categoria
   */
  deleteCategoria(id: number): Promise<any> {
    return this.http.delete<any>(this.url + "/relatorio/categoria/" + id, this.util.httpOptions())
      .toPromise()
      .then((data) => {
        this.atualizaNavbarRelatorios.emit(true)
      })
  }


  getById(id: number): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio/categoria/" + id + "/relatorios", this.util.httpOptions())
      .toPromise()
  }
  delete(id: number): Promise<any> {
    return this.http.delete<any>(this.url + "/relatorio/" + id, this.util.httpOptions())
      .toPromise()
  }
  getAll(): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio", this.util.httpOptions())
      .toPromise()
  }
  create(model: any): Promise<any> {
    return this.http.post<any>(this.url + "/relatorio", model, this.util.httpOptions())
      .toPromise()
  }
  update(model: any): Promise<any> {
    return this.http.put<any>(this.url + "/relatorio/dto", model, this.util.httpOptions())
      .toPromise()
  }
  getRelatorioById(id: number): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio/" + id, this.util.httpOptions())
      .toPromise()
  }
  createScript(model: any): Promise<any> {
    return this.http.post<any>(this.url + "/relatorio/script", model, this.util.httpOptions())
      .toPromise()
  }
  updateScript(model: any): Promise<any> {
    return this.http.put<any>(this.url + "/relatorio", model, this.util.httpOptions())
      .toPromise()
  }


  /**
  * Busca todos os campos da tabela
  */
  getCamposDaTabelas(tabela: string): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio/tabela/" + tabela + "/campos", this.util.httpOptions())
      .toPromise()
  }

  /**
  * Busca todos os campos da tabela
  */
  getTabelasRelacionadas(tabela: string): Promise<any> {
    return this.http.get<any>(this.url + "/relatorio/tabela/" + tabela + "/relacionadas", this.util.httpOptions())
      .toPromise()
  }

  getRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/" + id, this.util.httpOptions()).toPromise()
  }

  getCamposRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/campos/" + id, this.util.httpOptions()).toPromise()
  }

  getComparacoesRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/comparacao/" + id, this.util.httpOptions()).toPromise()
  }

  getComparacoesAgregacaoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/comparacao/agregacao/" + id, this.util.httpOptions()).toPromise()
  }

  getTabelasRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/tabela/" + id, this.util.httpOptions()).toPromise()
  }

  getRelacionamentoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/relacionamento/" + id, this.util.httpOptions()).toPromise()
  }

  getAgregacaoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/agregacao/" + id, this.util.httpOptions()).toPromise()
  }

  getAgregacoes() {
    return this.http.get<any>(this.url + "/relatorio/agregacao", this.util.httpOptions()).toPromise()
  }

  getAgrupamentoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/agrupamento/" + id, this.util.httpOptions()).toPromise()
  }

  getOrdenacaoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/ordenacao/" + id, this.util.httpOptions()).toPromise()
  }

  getOrdenacaoAgregacaoRelatorio(id: number) {
    return this.http.get<any>(this.url + "/relatorio/ordenacao/agregacao/" + id, this.util.httpOptions()).toPromise()
  }


  getQueryRelatorio(id: number) {
    return this.http.get<any>(this.urlNode + "/findQuery/" + id,
      {
        headers: new HttpHeaders({
          token: this.storage.read('token'),
          cidade: this.storage.read("cidade")
        }).set("Content-Type", "application/json")
      }).toPromise()
  }

  checkQueryRelatorio(query: string) {
    return this.http.post<any>(this.urlNode + "/checkQuery", { query: query.trim() },
      {
        headers: new HttpHeaders({
          token: this.storage.read('token'),
          cidade: this.storage.read("cidade")
        }).set("Content-Type", "application/json") }).toPromise()
  }

  getQueryPageable(query: string, page: any, size: any) {
   return this.http.post<any>(this.urlNode + "/getQuery/pageable", { query: query.trim(), page: page, size: size },
     {
       headers: new HttpHeaders({
         token: this.storage.read('token'),
         cidade: this.storage.read("cidade")
       }).set("Content-Type", "application/json") }).toPromise()
 }

  createQueryRelatorio(model: any) {
    return this.http.post<any>(this.url + "/relatorio/getQuery", model, this.util.httpOptions()).toPromise()
  }

  // checkQueryServidorRelatorio(query: string){
  //   return this.http.post<any>(this.urlNode+"/checkQuery", {query: query.trim()}, {headers: new HttpHeaders({token: this.storage.read('token')}).set("Content-Type", "application/json")}).toPromise()
  // }


  /**
  * Configuração dos relatorios
  */

  findConfiguracaoRelatorio(relatorioId: number): Observable<any> {
   return this.http.get<any>(this.url + "/configuracao-relatorio/" + relatorioId, this.util.httpOptions())
  }

  findCamposBPA(): Observable<any> {
   return this.http.get<any>(this.url + "/configuracao-relatorio/campos/BPA", this.util.httpOptions())
  }

  createOrUpdateConfiguracaoRelatorio(relatorioId: number, model: any): Observable<any> {
   return this.http.post<any>(this.url + "/configuracao-relatorio/" + relatorioId, model, this.util.httpOptions())
  }

  findAllPageableRelatorio(relatorioId, tipo, model): Observable<any>{
   return this.http.post<any>(this.url + `/relatorio/${relatorioId}/tipo/${tipo}/pageable`, model, this.util.httpOptions())
  }

  gerarBap(id: number, tipo, filter: any): Observable<any> {
   return this.http.post<any>(`${this.url}/relatorio/${id}/tipo/${tipo}/gerar`, filter, this.util.httpOptions());
 }

 getImagem(urlImagem: string): Observable<ArrayBuffer> {
  return this.http.get(urlImagem, { responseType: 'arraybuffer' })
 }


}
