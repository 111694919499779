<div mat-dialog-title class="d-flex align-items-center justify-content-between">
   <span>Histórico das ocorrências</span>
   <button type="button" class="close" data-dismiss="modal" aria-label="Fechar" (click)="close()">
      <i class="mt-2 fa-solid fa-x"></i>
   </button>
</div>
<mat-dialog-content class="mb-3">
   <table mat-table [dataSource]="ocorrencias" class="table table-bordered table-sm">
      <ng-container matColumnDef="id">
         <mat-header-cell *matHeaderCellDef class="px-2"> Nº </mat-header-cell>
         <mat-cell *matCellDef="let element" class="px-2">
            <span class="border-light text-truncate">
               {{element.id}}
            </span>
         </mat-cell>
      </ng-container>
      <!-- Name Column -->
      <ng-container matColumnDef="horaExataChamado">
         <mat-header-cell *matHeaderCellDef class="px-2"> Data </mat-header-cell>
         <mat-cell *matCellDef="let element" class="px-2">
            <span class="border-light text-truncate">
               {{ this.getHoraFormatada(element.horaExataChamado) | date: "dd/MM/yyyy HH:mm"}}
            </span>
         </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
   </table>
   <!--PAGINAÇÃO-->
   <mat-paginator [length]="totalElements" [pageSize]="size" [pageSizeOptions]="[5, 10, 25, 100]"
      (page)="filter($event)" [pageIndex]="number" aria-label="Selecione a página">
   </mat-paginator>
   <!--PAGINAÇÃO-->
</mat-dialog-content>
