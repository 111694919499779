import { NgModule } from "@angular/core";
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from "../guards/auth.guard";
import { PermissionGuard } from "../guards/permissoes.guard";
import { LoginComponent } from "./login.component";

const autenticidadeRoutes: Routes = [
    {
        path: '',
        component: LoginComponent
    },
    
]

@NgModule({

    imports: [RouterModule.forChild(autenticidadeRoutes)],
    exports: [RouterModule]

})
export class LoginRoutingModule {}