import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { ModalAlertasComponent } from './modal-alertas/modal-alertas.component';
import { ModalAtiva } from './modal-ativar/modal-ativa.component';
import { ModalCertezaComponent } from './modal-certeza/modal-certeza.component';
import { ModalConfirmDeleteComponent } from './modal-confirm-delete/modal-confirm-delete.component';
import { ModalErroV2Component } from './modal-erro-v2/modal-erro-v2.component';
import { ModalErrorMarkdownComponent } from './modal-error-markdown/modal-error-markdown.component';
import { ModalErrorComponent } from './modal-error/modal-error.component';
import { ModalMensagemWhatsappComponent } from './modal-mensagem-whatsapp/modal-mensagem-whatsapp.component';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        MarkdownModule.forRoot(),
    ],
    declarations: [
        ModalConfirmDeleteComponent,
        ModalErrorComponent,
        ModalAlertasComponent,
        ModalAtiva,
        ModalCertezaComponent,
        ModalMensagemWhatsappComponent,
        ModalErroV2Component,
        ModalErrorMarkdownComponent,
    ],
    exports: [
        ModalConfirmDeleteComponent,
        ModalErrorComponent,
        ModalAlertasComponent,
        ModalAtiva,
        ModalCertezaComponent,
        ModalMensagemWhatsappComponent,
        ModalErroV2Component,
        ModalErrorMarkdownComponent,
    ]
})
export class ModalModule{
}
