<h2 mat-dialog-title *ngIf="erro.status === 500">{{ erro.name }}</h2>
<h2 mat-dialog-title *ngIf="erro.status === 0">Falha de conexão</h2>
<div mat-dialog-content  class="mb-1">
  <p class="font-weight-bold" *ngIf="erro.status === 500">
    Consulte o administrador do sistema para mais detalhes.
  </p>
  <div *ngIf="erro.status === 400 || erro.status === 409">
    <p>Mensagem:<br /></p>
    <p style="white-space: pre-line;">
      {{ erro?.error?.message || erro?.message }}
    </p>
  </div>
  <p *ngIf="erro.status == 300">Mensagem: {{ erro.error?.mensagem }}</p>
  <p *ngIf="erro.status == 404">Mensagem: Não encontrado</p>
  <p *ngIf="erro.status == 0">Mensagem: Aplicação não consegue se conectar com o servidor</p>
  <p *ngIf="erro.status == 403">Mensagem: {{ erro.error?.message || erro.error?.error || erro?.message }}</p>
  <p *ngIf="erro.status == 104">Mensagem: {{ erro.error?.message || erro.error?.error || erro?.message }}</p>
  <p *ngIf="!erro.status && erro.status !== 0">Mensagem: Faça login com uma conta válida.</p>
</div>
<div mat-dialog-actions class="d-block text-right">
  <button type="button" class="btn btn-primary mx-2" (click)="close()">Ok</button>
</div>
